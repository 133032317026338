

import { useL10n } from "@ews/react-localization-context";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { AlarmTransmissionProps } from "./types";

const AlarmTransmission: React.FC<AlarmTransmissionProps> = ({ system }) =>
{

    const { translate: t } = useL10n();
    /*  const license = useSystemLicense(system.id!);
     const modifySystemLicense = useModifySystemLicense();
 
     function saveSystemLicense(key: keyof SystemLicense, value: any)
     {
         if (!system.id || !(key in license)) return;
 
 
         license[key] = value;
 
         modifySystemLicense(system.id!, { [key]: value });
     }
     const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
 
 
     useEffect(() =>
     {
         if (!systemLicenseState.systemId && license.systemId) {
             setSystemLicenseState(license);
         }
     }, [system, license]); */

    return <>
        <SettingsCol >
            <SettingsSelect
                text={"Automatic"}
                // eslint-disable-next-line react/jsx-no-undef
                tooltip={<TooltipIcon text={t("")} />}
                value={"EN54-21"}
                onBlur={({ value }) =>
                {
                    /* setSystemDetailState({ ...systemDetailsState, country: value });
                    saveSystemDetails("country", value); */
                }}>
                <SettingsSelectOption text={"Klasse 1 gemäß EN54-21"} value={"EN54-21"} />
            </SettingsSelect>
            <SettingsSelect
                text={"Manual"}
                tooltip={<TooltipIcon text={t("")} />}
                value={"austria"}
                onBlur={({ value }) =>
                {
                    /* setSystemDetailState({ ...systemDetailsState, country: value });
                    saveSystemDetails("country", value); */
                }}>
                <SettingsSelectOption text={"durch eine Person"} value={"one"} />
            </SettingsSelect>
            <SettingsTextInput
                text={"Additional text field manual"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={''}
                onBlur={({ value }) =>
                {
                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default AlarmTransmission;