import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";
import List from "../../../List/List";
import { useIsMobile } from "../../../Responsive";
import { getList } from "../../../TypeAhead/helper";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { QueriesProps } from "./types";
import { pageRange } from "../../../List/Pagination/pagerange";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";

const Queries: React.FC<QueriesProps> = ({ system }) =>
{
    const queriesList: any[] = [];

    const mobileView = useIsMobile();

    type EventMemoryFilter = {
        /*   id?: { from: number, to: number } */
        time?: StingFilterType;
        systemkey?: StingFilterType;
        userId?: StingFilterType;
        command?: StingFilterType;
        zone?: StingFilterType;
        firstElement?: StingFilterType;
        lastElement?: StingFilterType;
    };
    const [list, setList] = useState<Record<string, any>[] | []>(queriesList);

    const [currentState, setState] = useState<EventMemoryFilter>({});

    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);

    return <>
        <FilterList
            isDetailsHidden={false}
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);

            }}

            details={<>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, systemkey: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'systemkey');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"Systemkey"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, time: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'time');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"Time"}
                    />
                </IonCol>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, userId: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'userId');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"User ID"}
                    />
                    {/*       <SettingsSelect text={"Event"} value={currentState!.event || ''} onSelect={(value: string) =>
            {
                setState({ ...currentState, event: value, })

            }}>
                {eventsChooiceList.map((option, index) => <SettingsSelectOption key={index} text={option} value={option} />)}
            </SettingsSelect> */}
                </IonCol>
                <IonCol>

                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, time: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'text1');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"Text1"}
                    />

                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, time: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'text2');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"Text2"}
                    />
                </IonCol>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, firstElement: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(list, 'firstElement');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"First element"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, lastElement: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(queriesList, 'lastElement');
                            return typeheadList;
                        }}
                        disabled={!queriesList.length}
                        text={"Last element"}
                    />
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>} >
        </FilterList >

        <List
            rows={queriesList}
            headers={[
                { title: 'Time', key: 'time', both: true },
                { title: 'Systemkey', key: 'systemkey', both: true },
                { title: 'User-ID', key: 'userId', breakpoint: 1200, up: true },
                { title: 'Executed command', key: 'command', breakpoint: 1200, up: true },
                { title: 'First zone', key: 'firstZone', breakpoint: 1200, up: true },
                { title: 'Last zone', key: 'lastZone', breakpoint: 1200, up: true },
                { title: 'First element', key: 'firstElement', breakpoint: 1200, up: true },
                { title: 'Last element', key: 'lastElement', breakpoint: 1200, up: true },
            ]}

        />
    </>;
};

export default Queries;