import { FilterCategory } from "@ews/zlt-events";
import { FilterQuery, QueryValue } from "./types";

export function createQuery(url: string, value: QueryValue, type: keyof typeof FilterQuery)
{
    const query = FilterQuery[type];
    return url.includes("?") ? `&${query}=${value}` : `?${query}=${value}`;
}

export function decimalArrayToHexString(array: number[])
{
    const hex = array.reduce((v, c) => c! | v!, 0b00).toString(16).toUpperCase();
    return hex;
}
export function decimalToHexString(num: number)
{
    const defaultHex = 0b00;
    const hex = (num | defaultHex).toString(16).toUpperCase();
    return hex;
}

export function decimalToHex(d: number)
{
    var hex = Number(d).toString(16);
    hex = "0x000".substr(0, 6 - hex.length) + hex;
    return hex;
}

export const filterCategoryEventKindList = [...Object.values(FilterCategory)
    .filter(item => Number.isInteger(item) && Number(item) !== 0)]
    .map(eventKindTypeString => Number(eventKindTypeString));

export const allEventsKindType = [...filterCategoryEventKindList].reduce(
    (total, current) => total | current
);

export const filterEventList = [allEventsKindType, ...filterCategoryEventKindList];