import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { numberFilter } from "../../../FilterList/FilterList";
import { NumberFilterType, StingFilterType } from "../../../FilterList/types";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import SettingsRangeInput from "../../../Settings/SettingsComponents/SettingsRangeInput/SettingsRangeInput";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { InputsSystemDocumentationProps } from "./types";

const InputsSystemDocumentation: React.FC<InputsSystemDocumentationProps> = ({ system }) =>
{


    const { generate } = useNamedRoutes();
    const mobileView = useIsMobile();


    const data = [
        {
            link: generate('systems'),
            inputNo: 0,
            moduleType: "",
            zone: 0,
            element: 0,
            zoneText: "",
            elementText: "",
            actuatedDevice: "",
        },

    ];


    const [list, setList] = useState<any[] | null>(data);

    type Filter = {
        inputNo?: NumberFilterType,
        moduleType?: StingFilterType,
        zone?: NumberFilterType,
        element?: NumberFilterType,
        zoneText?: StingFilterType,
        elementText?: StingFilterType,
        actuatedDevice?: StingFilterType,
    };

    const [currentState, setState] = useState<Filter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);


    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}
            //onDetailOpen={setFilterDetailsOpen}
            isDetailsHidden={false}
            details={<>
                <IonCol>
                    <SettingsRangeInput
                        text={"ID"}
                        reset={clearFilterInputs}
                        onChange={(value) => 
                        {

                            setState({
                                ...currentState, inputNo: (a: number, b: { from: number; to: number; }): boolean => numberFilter(a, value as { from: number; to: number; })
                            });

                        }} />

                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >
        </FilterList >



        <List
            rows={list}
            maxHeight={mobileView ? '86vh' : "48vh"}
            headers={[
                {
                    title: 'Input no.', key: 'inputNo', both: true
                },
                {
                    title: 'Function module type', key: 'moduleType', both: true
                },
                {
                    title: 'Zone number', key: 'zone', both: true
                },
                {
                    title: 'Element number', key: 'element', breakpoint: 1200, up: true
                },
                {
                    title: 'Zone text', key: 'zoneText', breakpoint: 1200, up: true
                },
                {
                    title: 'Element text', key: 'elementText', breakpoint: 1200, up: true
                },
                {
                    title: 'Actuated by device', key: 'actuatedDevice', breakpoint: 1200, up: true
                },
            ]}

        />
    </>;
};

export default InputsSystemDocumentation;

