
import spinnerStyle from "./Default.module.scss";
import { SpinnerProps } from "./types";


const Default: React.FC<SpinnerProps> = ({ }) => (
    <span className={spinnerStyle.loader} />
);

export default Default;




