import { useIonRouter, UseIonRouterResult } from "@ionic/react";
import React, { createContext, ReactNode, useContext } from "react";
import { generatePath, match, Redirect as RedirectWrapped, Route, useRouteMatch } from "react-router";

type Routes = Record<string, string>;

export type RouteParams = Record<string, string | undefined>;

type Router = {
    routes: Routes,
    current?: string,
    defaults: RouteParams,
    params: RouteParams;
};

const router: Router = {
    routes: {},
    defaults: {},
    params: {}
};

export const registerRoutes = (routes: Routes) =>
{
    router.routes = { ...router.routes, ...routes };
};

export const registerDefaults = (defaults: RouteParams) =>
{
    router.defaults = { ...router.defaults, ...defaults };
};

export type NamedRouteProps = {
    name: string,
    exact?: boolean,
    children: ReactNode;
};

const registerCurrentRoute = (route: match) =>
{
    for (const name in router.routes) {
        if (router.routes[name] === route.path) {
            router.current = name;
            router.params = route.params;
            break;

        }
    }

};

const generate = (route: string | null | undefined = null, routeParams: RouteParams = {}): string => 
{

    route = route || router.current || null;
    let path = route ? router.routes[route] || "" : "";

    const defaults = router.defaults;
    const params = { ...router.params, ...routeParams };

    for (let key in defaults) {

        if (typeof params[key] === 'undefined') {
            params[key] = defaults[key];
        }
    }

    try {
        return generatePath(path, params);
    } catch (e: any) {
        console.log(e);
    }

    return path;
};

const params = () => router.params;


type NamedRouteContextType = {
    generate: (route?: string | null, routeParams?: RouteParams) => string;
    params: () => RouteParams;
    router?: UseIonRouterResult;
};

const NamedRouteContextProvider = {
    generate, params
};

const NameRouteContext = createContext<NamedRouteContextType>(NamedRouteContextProvider);

const RouteWrapper: React.FC<{ children: ReactNode; }> = ({ children }) =>
{
    const route = useRouteMatch();
    const router = useIonRouter();

    registerCurrentRoute(route);

    return (
        <NameRouteContext.Provider value={{ ...NamedRouteContextProvider, router }} >
            {children}
        </NameRouteContext.Provider >
    );

};

export const NamedRoute: React.FC<NamedRouteProps> = ({ name, children, exact = true }) =>
{
    const path = router.routes[name] || "";
    return (
        <Route path={path} exact={exact}>
            <RouteWrapper>
                {children}
            </RouteWrapper>
        </Route>
    );
};

export const useNamedRoutes = () =>
{
    return useContext(NameRouteContext);
};

export type RedirectProps = ({
    route: string,
    to?: never,
    params?: RouteParams;
} | {
    route?: never;
    params?: never;
    to: string;
});

export const Redirect: React.FC<RedirectProps> = ({ route, params, to }) =>
{
    const { generate } = useNamedRoutes();
    const path = route ? generate(route, params || {}) : to;

    return <RedirectWrapped to={path || ""} />;
};