import
{
    FC
} from "react";

import
{
    SystemStatus
} from "../../System/SystemStatus";

import Main from "./Main";

const System: FC = () =>
{
    return (<Main>
        <SystemStatus />
    </Main>);
};

export default System;