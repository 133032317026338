import
{
    FC,
    useEffect,
    useState
} from "react";

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) =>
{
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

const getPath = (cx: number, cy: number, radius: number, angleFrom: number, angleTo: number) =>
{
    const start = polarToCartesian(cx, cy, radius, angleTo);
    const end = polarToCartesian(cx, cy, radius, angleFrom);

    const largeArcFlag = angleTo - angleFrom <= 180 ? 0 : 1;

    return [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
};

export type TimerProps = React.SVGAttributes<SVGElement> & {
    time: number;
    color?: string,
    highlight?: string;

};

const formatTime = (time: number) =>
{
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    if (minutes) {
        return `${minutes}' ${seconds}"`;
    }

    return `${seconds}"`;

};

export const Timer: FC<TimerProps> = ({
    time,
    color = "#ddd",
    highlight = "#ff0000",
    strokeWidth = 5,
    ...svgAttributes
}) =>
{
    const [remaining, setRemaining] = useState(time);

    const angle = (remaining / time) * 360;
    const radius = 50 - (+strokeWidth);
    const sector = getPath(55, 55, radius, 0, angle);

    if (angle === 360) {
        color = highlight;
    }

    useEffect(() =>
    {
        const tick = () => setRemaining((current) =>
        {
            return current ? current - 1 : current;
        });

        const timeout = setTimeout(tick, 1000);
        return () => clearTimeout(timeout);
    }, [remaining]);

    return (
        <>
            <svg {...svgAttributes} strokeWidth={strokeWidth} viewBox="0 0 110 110">

                <circle stroke={color} fill="none" cx={55} cy={55} r={radius} />
                <path stroke={highlight} fill="none" fillRule="evenodd" d={sector}></path>

                <text y={55} x={55} textAnchor="middle">{formatTime(remaining)}</text>
            </svg >

        </>
    );
};
