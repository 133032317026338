import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

const deepLinkUrl = "react://";

const AppUrlListener: React.FC<any> = () =>
{
    const router = useIonRouter();

    function handleDeepLink(event: URLOpenListenerEvent)
    {
        try {
            const url = event.url;
            if (url.includes(deepLinkUrl)) {
                const route = url.split(deepLinkUrl).pop();
                console.log(route);
                if (route) router?.push(route);
            }
        }
        catch (error) { }
    }

    useEffect(() =>
    {
        App.addListener('appUrlOpen', handleDeepLink);

        return () =>
        {
            App.removeAllListeners();
        };
    }, []);

    return null;
};

export default AppUrlListener;