import Icon, {
    IconName,
    IconProps
} from '@ews/react-icons';

import
{
    EventType
} from "../Event/types";

const eventCategoryIcons: { [key in EventType]?: IconName } = {
    Alarm: 'EN54_red',
    TestCondition: "Fire_zone_test_cond_L_yellow",
    ActiveOutput: 'Act_elem_activ_L_red',
    Fault: 'Fault_alarm_L_gelb',
    Disablement: 'Abschaltung_yellow',
    TechnicalMessage: 'TechMess_zone_test_cond_L_yellow'
};

const eventCategoryIconsGrey: { [key in EventType]?: IconName } = {
    Alarm: 'Alarm_fire_pre_alarm_L_grey',
    TestCondition: "Fire_zone_test_cond_L_grey",
    ActiveOutput: 'Act_elem_activ_L_grey',
    Fault: 'Fault_alarm_L_grey',
    Disablement: 'Abschaltung_grey',
    TechnicalMessage: 'TechMess_zone_test_cond_L_grey'
};

type EventCategoryIconProps = Omit<IconProps, 'icon'> & {
    category: EventType;
    greyScale?: boolean;
};

export const EventCategoryIcon: React.FC<EventCategoryIconProps> = ({
    category,
    height = 20,
    width = 20,
    greyScale = false,
    ...props
}) =>
{
    const icon = (greyScale ? eventCategoryIconsGrey[category] : eventCategoryIcons[category]) || "none";
    return <Icon icon={icon} height={height} width={width} {...props} />;
};


