import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";
import { DisplayDeviceProps } from "./types";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { SortItem } from "../../../List/types";

const DisplayDevice: React.FC<DisplayDeviceProps> = ({ system }) =>
{


    const { generate } = useNamedRoutes();
    const mobileView = useIsMobile();


    const data = [
        {
            link: generate('systems'),
            deviceType: system.systemType,
            panelType: system.id,
            panelNumber: system.name,
            connectedTo: "",
            location: "",
            interfaceType: "",
            connections: [],
            address: "Wiegelstr.",
        },

    ];


    const [list, setList] = useState<any[] | null>(data);

    type Filter = {
        deviceType?: StingFilterType,
        panelType?: StingFilterType,
        panelNumber?: StingFilterType,
        connectedTo?: StingFilterType,
        location?: StingFilterType,
        interfaceType?: StingFilterType,
        connections?: StingFilterType,
        address?: StingFilterType,
    };

    const [currentState, setState] = useState<Filter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);


    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                //setClearFilterInputs(!clearFilterInputs);
            }}
            //onDetailOpen={setFilterDetailsOpen}
            isDetailsHidden={false}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="deviceType"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, deviceType: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'deviceType');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"Display device type"}
                    />

                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >
        </FilterList >

        <List
            rows={list}
            maxHeight={mobileView ? "86vh" : "58vh"}
            headers={[
                {
                    title: 'Display device type', key: 'deviceType', both: true
                },
                {
                    title: 'Control panel type', key: 'panelType', both: true
                },
                {
                    title: 'Control panel number', key: 'panelNumber', both: true
                },
                {
                    title: 'connected to:', key: 'connectedTo', breakpoint: 1200, up: true
                },
                {
                    title: 'Location', key: 'location', breakpoint: 1200, up: true
                },
                {
                    title: 'interface type', key: 'interfaceType', breakpoint: 1200, up: true
                },
                {
                    title: 'Connections', key: 'connections', breakpoint: 1200, up: true
                },
                {
                    title: 'Address', key: 'address', breakpoint: 1200, up: true
                },

            ]}

        />
    </>;
};

export default DisplayDevice;

