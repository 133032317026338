import { formatLogicalAddress } from "@ews/zlt-events";
import { IonItem, IonText } from "@ionic/react";
import React, { useRef } from "react";
import { SystemElement } from "../../../ReactData/system";
import style from "../../Plan.module.scss";
import { ElementsProps } from "./types";


const Elements: React.FC<ElementsProps> = ({ disabled = false, current, list, pointer, className, draggable, onDrag, onClick, color, hidden = false }) =>
{

    function drag(element: SystemElement)
    {
        onDrag!(element);

    }
    const currentElement = useRef(-1);

    return <>
        {list.map((element, index) =>
        {
            //return element.planX || element.planY ?
            // <React.Fragment key={`${index}-${element.text}-${element.groupId}-${element.id}`} /> :
            //if)console.log()

            const id = formatLogicalAddress(element.groupId!, element.id!, 1);
            //const { symbol } = eventDetails(element.eventKindType!);

            return <IonItem
                draggable={draggable}
                key={`${index}-${element.text}-${element.groupId}-${element.id}`}
                className={`${className} ${pointer ? style.pointer : ''}`}
                hidden={hidden}
                lines="full"
                color={current?.groupId && formatLogicalAddress(current.groupId, current.id!, 1) === formatLogicalAddress(element.groupId!, element.id!, 1) ? "primary" : color}
                onClick={() =>
                {

                    if (!onClick) return;
                    currentElement.current = index;
                    onClick(element);
                }}
                onDrag={
                    () =>
                    {
                        if (onDrag) drag(element);
                    }
                }
            >
                <IonText
                    slot="start"
                    color={disabled ? "danger" : "dark"}
                    className={style.groupText}>
                    {`${id}`}
                </IonText>
                <IonText
                    color={disabled ? "danger" : undefined}
                    className={style.deviceText}>
                    {`${element.text}`}
                </IonText>
                <span slot="end">
                    {/* <Icon icon={symbol} /> */}
                </span>
                {/*     <span >
                    <Melder
                        draggable={false}
                        element={element}
                        icon={`assets/device-icons/${"BMZ"}_0.png`}
                        pointer={false}
                        onDrop={() =>
                        {
                            // if (onDrop) drop(element);
                        }} />
                </span> */}
            </IonItem>;
        })}
    </>;
};


export default Elements;