

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { ActuatedFireProtectionSystemsProps } from "./types";

const ActuatedFireProtectionSystems: React.FC<ActuatedFireProtectionSystemsProps> = ({ system }) =>
{

    /*   const license = useSystemLicense(system.id!);
      const modifySystemLicense = useModifySystemLicense();
  
      function saveSystemLicense(key: keyof SystemLicense, value: any)
      {
          if (!system.id || !(key in license)) return;
  
  
          license[key] = value;
  
          modifySystemLicense(system.id!, { [key]: value });
      }
      const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
  
  
      useEffect(() =>
      {
          if (!systemLicenseState.systemId && license.systemId) {
              setSystemLicenseState(license);
          }
      }, [system, license]); */

    return <>
        <SettingsCol >
            <SettingsCheckbox
                text={"Gas extinguishing system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Smoke and heat exhaust system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Forced ventilation system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


            <SettingsCheckbox
                text={"Electro acoustic emergency warning system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


            <SettingsCheckbox
                text={"Pre-piloted sprinker system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default ActuatedFireProtectionSystems;