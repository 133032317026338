import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { NumberFilterType, StingFilterType } from "../../../FilterList/types";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";
import { PowerUnitsDocumentationProps } from "./types";

const PowerUnitsDocumentation: React.FC<PowerUnitsDocumentationProps> = ({ system }) =>
{


    const { generate } = useNamedRoutes();
    //    const mobileView = useIsMobile();

    const data = [
        {
            link: generate('systems'),
            powerUnitType: "",
            location: "",
            nominalCurrent: "",
            consumptionMainsFault: "",
            maxConsumption: "",
            bridgingTime: "",
            standbyVoltage: 0,
            standbyCapacity: "",
            standbyNumberBatteries: 0,
            voltageSecondarySide: "",
        },

    ];


    const [list, setList] = useState<any[] | null>(data);

    type Filter = {
        powerUnitType?: StingFilterType,
        location?: StingFilterType,
        nominalCurrent?: StingFilterType,
        consumptionMainsFault?: StingFilterType,
        maxConsumption?: StingFilterType,
        bridgingTime?: StingFilterType,
        standbyVoltage?: NumberFilterType,
        standbyCapacity?: NumberFilterType,
        standbyNumberBatteries?: NumberFilterType,
        voltageSecondarySide?: StingFilterType,
    };

    const [currentState, setState] = useState<Filter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);


    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}
            isDetailsHidden={false}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="powerUnitType"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, powerUnitType: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'powerUnitType');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"Power unit type"}
                    />

                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >
        </FilterList >



        <List
            rows={list}
            //maxHeight={mobileView ? undefined : filterDetailsOpen ? '45vh' : "61vh"}
            headers={[
                {
                    title: 'Power unit type', key: 'powerUnitType', both: true
                },
                {
                    title: 'Location of installation', key: 'location', both: true
                },
                {
                    title: 'Nominal current [A]', key: 'nominalCurrent', both: true
                },
                {
                    title: 'Current consumption at mains fault [mA]', key: 'consumptionMainsFault', breakpoint: 1200, up: true
                },
                {
                    title: 'Maximum current consumptions [mA]', key: 'maxConsumption', breakpoint: 1200, up: true
                },
                {
                    title: 'Bridging time [h]', key: 'bridgingTime', breakpoint: 1200, up: true
                },
                {
                    title: 'Nominal voltage of stand-by battery [V]', key: 'standbyVoltage', breakpoint: 1200, up: true
                },
                {
                    title: 'Capacity of stand-by battery [Ah]', key: 'standbyCapacity', breakpoint: 1200, up: true
                },
                {
                    title: 'Number fo stand-by batteries [pcs.]', key: 'standbyNumberBatteries', breakpoint: 1200, up: true
                },
                {
                    title: 'Nominal voltage secondary side', key: 'voltageSecondarySide', breakpoint: 1200, up: true
                },

            ]}

        />
    </>;
};

export default PowerUnitsDocumentation;

