import { useL10n } from "@ews/react-localization-context";


import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { SystemDocumentationProps } from "./types";

const SystemDocumentation: React.FC<SystemDocumentationProps> = ({ system, notifications }) =>
{
    const { translate: t } = useL10n();
    /*    const mobileView = useIsMobile();
   
       const modifySystemNotifications = useModifySystemNotifications();
   
       const notification = useSystemNotifications(system.id!);
   
       const [sendEmailFireAlarmPDF, setSendEmailFireAlarmPDF] = useState<boolean>(true);
       const receiver1Ref = useRef<HTMLIonInputElement>(null);
       const receiver2Ref = useRef<HTMLIonInputElement>(null);
       const receiver3Ref = useRef<HTMLIonInputElement>(null);
   
       function saveSystemNotifications(key: keyof SystemNotifications, value: any)
       {
           if (!system.id) return;
           modifySystemNotifications(system.id!, { [key]: value });
       }
   
       function CheckedDevice(key: keyof SystemNotifications, device: keyof typeof NotificationChannel, checked: boolean)
       {
   
           if (!(key in notification)) return;
   
           const { EMAIL, PUSH } = NotificationChannel;
   
           if (NotificationChannel[device] === EMAIL) {
               (notification as Record<string, number>)[key] = checked ? (notification as Record<string, number>)[key] |= EMAIL : (notification as Record<string, number>)[key] &= PUSH;
           }
           if (NotificationChannel[device] === PUSH) {
               (notification as Record<string, number>)[key] = checked ? (notification as Record<string, number>)[key] |= PUSH : (notification as Record<string, number>)[key] &= EMAIL;
           }
           saveSystemNotifications(key, notification[key]);
       } */

    return <>
        <SettingsCol >
            <SettingsCheckbox
                text={"Prescribed by authority"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsTextInput
                text={"Authority"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Number"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Date"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                inputType={"date"}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />

            <SettingsTextInput
                text={"Fire protection strategy created by"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"Fire brigade in charge"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsCheckbox
                text={"Works fire brigade"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


            <SettingsTextInput
                text={"E-mail"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


            {/*FRAGEN: sollte das eigendlich Radio buttons sein wo nur einer ausgewählt werden kann? */}
            <SettingsCheckbox
                text={"New installation"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Expansion"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Change"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


        </SettingsCol>

        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default SystemDocumentation;