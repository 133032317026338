
import { useL10n } from "@ews/react-localization-context";
import { IonItem, IonText } from "@ionic/react";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsInfo.module.scss';
import { SettingsInfoProps } from "./types";
import React from "react";

const SettingsInfo: React.FC<SettingsInfoProps> = ({
    hidden = false,
    text = '',
    lines = undefined,
    children
}) =>
{
    const { translate: t } = useL10n();


    return <IonItem hidden={hidden} lines={lines} className={`${style.overflow} ${globalStyle.setting}`}>
        <div className={style.content}>
            <IonText className={style.text}>{t(text)}</IonText>

            {children}
        </div>
    </IonItem>;
};

export default SettingsInfo;