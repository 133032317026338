import { Map as LeafletMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import style from './Map.module.scss';
import { MapProps } from './types';

type Layouts = "default" /* | "osmb" */ | "satellite" | "esri";

export const MapLayouts: Record<Layouts, string> = {
    default: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    //osmb: "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.jpg",
    satellite: "https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg",
    esri: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
};

const Map: React.FC<MapProps> = ({
    children,
    className,
    center,
    zoom,
    // onReady
    //bounds
}) =>
{

    const [renderMap, setRenderMap] = useState<boolean>(false);

    useEffect(() =>
    {
        const timeout = setTimeout(() =>
        {
            setRenderMap(true);
        }, 10);
        return () => clearTimeout(timeout);
    }, []);

    return <>

        {renderMap && <MapContainer
            attributionControl={false}
            className={`${className ? className : ""} ${style.leafletContainer}`}
            center={center}
            zoom={zoom}
            // bounds={bounds}
            scrollWheelZoom={true}
        >
            <TileLayer
                url={'https://tile.openstreetmap.org/{z}/{x}/{y}.png'}
            />

            {children}
        </MapContainer>}
    </>;
};


export default Map;