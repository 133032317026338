import
{
    FC
} from "react";

import TypeAhead from "../TypeAhead/TypeAheadString";

import
{
    Customer,
    PaginatedResult
} from "@ews/websocket-service";

import
{
    useCustomer,
    useTypeAheadCustomerList
} from "../ReactData/customer";

const CustomerTypeAhead: FC<{
    label?: string;
    currentId?: string;
    skipCustomers?: string[],
    onChange: (id: string) => void;
}> = ({
    label = "Customer",
    currentId = "",
    skipCustomers = [],
    onChange
}) =>
    {

        const typeAhead = useTypeAheadCustomerList();
        const customer = useCustomer(currentId);

        return <TypeAhead
            id="customer-type-ahead"
            text={label}
            debounce={300}
            value={customer.name}

            onFilter={async (customer) => new Promise(async resolve =>
            {

                const search = await typeAhead({ pageSize: 5, name: customer, customerNumber: customer, searchOperator: "OR" }) as PaginatedResult<Customer>;

                resolve(search
                    .results
                    .filter((c: Customer) => !skipCustomers.includes(c.id!))
                    .map((data: Customer) => ({ key: "name", data }))
                );
            })}
            onChange={(data) =>
            {
                const id = (data as Customer).id;
                if (id) onChange(id);
            }}
        />;

    };

export default CustomerTypeAhead;