import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonHeader, IonList, IonModal, IonTitle, IonToolbar, useIonRouter } from "@ionic/react";
import React, { useRef, useState } from "react";
import { useNamedRoutes } from "../../NamedRoutes";

import
{
    useCreateCustomer
} from "../../ReactData/customer";

import { useIsMobile } from "../../Responsive";
import SettingsTextInput from "../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import modalStyle from "./NewCustomerModal.module.scss";
import { NewCustomerModalProps } from "./types";
import { IonModalCustomEvent, OverlayEventDetail } from "@ionic/core";
import CustomerTypeAhead from "../CustomerTypeAhead";

const NewCustomerModal: React.FC<NewCustomerModalProps> = ({ trigger, onDismiss, parentId }) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);
    const [name, setName] = useState<string | null>(null);
    const [selectedParentId, setSelectedParentId] = useState<string | undefined>(parentId);
    const { generate } = useNamedRoutes();
    const router = useIonRouter();
    const createCustomer = useCreateCustomer();

    async function confirm(name: string | null)
    {
        if (!name) return;

        try {

            const customer = await createCustomer(name, selectedParentId);

            const id = customer.id;
            modal.current?.dismiss();
            const link = generate('customer', { id });
            router.push(link);

        } catch (err) {
            console.log(err);
        }
    }

    function onWillDismiss(event: IonModalCustomEvent<OverlayEventDetail<any>>)
    {
        setName(null);
        onDismiss && onDismiss(event);
    }

    return <IonModal
        ref={modal}
        className={modalStyle.newSystemModal}
        trigger={trigger}
        breakpoints={mobileView ? [.6] : undefined}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div className={`${modalStyle.titleCenter}`}>
                    <IonTitle>{`${t("new Customer")}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">
            <SettingsTextInput
                text={"Name"}
                value={name || undefined}
                onBlur={({ value }) =>
                {

                    setName(value);
                }} />


            <CustomerTypeAhead
                label="Assigned to"
                currentId={parentId || ""}
                onChange={(parentId) => setSelectedParentId(parentId)}
            />
        </IonList>
        <div className={`${modalStyle.buttonContainer} ${modalStyle.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => modal.current?.dismiss()}>{t("Cancel")}</IonButton>
            <IonButton
                disabled={!name}
                slot="end"
                color={"success"}
                onClick={() => confirm(name)}>
                {t("Create")}
            </IonButton>
        </div>
    </IonModal>;

};


export default NewCustomerModal;