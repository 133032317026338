import { IonContent, IonPage } from "@ionic/react";
import Header from "../Header/User";
import Navigation from "../Navigation";
import { UserLayoutProps } from "./types";

const Layout: React.FC<UserLayoutProps> = ({ header = true, children, user, sidebar, navbar, backbutton, id = '' }) =>
{
    return <>
        {header ? <Header backbutton={backbutton} title={user?.username || ""} /> : false}
        <Navigation sidebar={sidebar} navbar={navbar} id={id}>
            <IonPage>
                <IonContent fullscreen>
                    {children}
                </IonContent>
            </IonPage>
        </Navigation>
    </>;
};

export default Layout;