import { IonGrid, IonList } from "@ionic/react";
import React from "react";
import { useIsMobile } from "../../../../Responsive";

const SettingsTableGrid: React.FC<React.HTMLAttributes<HTMLIonListElement | HTMLIonGridElement>> = (props) =>
{

    const mobileView = useIsMobile();

    return mobileView ? <IonList  {...props} >{props.children}</IonList>
        :
        <IonGrid {...props} >{props.children}</IonGrid>;
};

export default SettingsTableGrid;