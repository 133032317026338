import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";

import List from "../../../List/List";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";
import { DocumentationHistoryProps } from "./types";

const SystemDocumentationHistory: React.FC<DocumentationHistoryProps> = ({ system }) =>
{

    const { generate } = useNamedRoutes();
    const mobileView = useIsMobile();
    const data = [
        {
            link: generate('systems'),
            compare: "any",
            systemId: system.id,
            systemname: system.name,
            xmlFilename: "",
            Documentation: "",
            currentStatus: "",
            created: new Date().toDateString(),
            finished: new Date().toDateString(),
            user: "domenic.buechler",
        },

    ];

    const [list] = useState<any[] | null>(data);

    type Filter = {
        compare?: StingFilterType,
        ele?: StingFilterType,
        systemId?: StingFilterType,
        systemname?: StingFilterType,
        xmlFilename?: StingFilterType,
        Documentation?: StingFilterType,
        currentStatus?: StingFilterType,
        created?: StingFilterType,
        finished?: StingFilterType,
        user?: StingFilterType,
    };

    const [currentState, setState] = useState<Filter>({});
    const [clearFilterInputs, setClearFilterInputs] = useState(false);

    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}
            isDetailsHidden={false}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="systemId"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, systemId: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'systemId');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"System ID"}
                    />

                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >

        </FilterList >

        <List
            rows={list}
            maxHeight={mobileView ? '86vh' : "58vh"}
            headers={[
                {
                    title: 'Compare.', key: 'compare', both: true

                },
                {
                    title: 'System ID', key: 'systemId', both: true

                },
                {
                    title: 'System name', key: 'systemname', breakpoint: 1200, up: true

                },
                {
                    title: 'XML Filename', key: 'xmlFilename', breakpoint: 1200, up: true

                },
                {
                    title: 'Documentation', key: 'Documentation', breakpoint: 1200, up: true

                },
                {
                    title: 'Current Status', key: 'currentStatus', breakpoint: 1200, up: true

                },
                {
                    title: 'Created', key: 'created', breakpoint: 1200, up: true

                },
                {
                    title: 'finished', key: 'finished', breakpoint: 1200, up: true

                },
                {
                    title: 'user', key: 'user', breakpoint: 1200, up: true

                },
            ]}

        />
    </>;
};

export default SystemDocumentationHistory;

