import
{
    SystemType,
} from "@ews/websocket-service";

//const Capitalize = (s: string) => `${s.charAt(0).toUpperCase()}${s.substring(1).toLowerCase()}`;

export const SystemTypeIcon: React.FC<{ type: SystemType; }> = ({ type }) =>
{
    return <img src={`assets/img/system_${type}.png`} alt={`System ${type}`} />;
};


