

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { SystemDocumentationProps } from "./types";

const SystemDocumentation: React.FC<SystemDocumentationProps> = ({ system }) =>
{

    /*    const license = useSystemLicense(system.id!);
       const modifySystemLicense = useModifySystemLicense();
   
       function saveSystemLicense(key: keyof SystemLicense, value: any)
       {
           if (!system.id || !(key in license)) return;
   
   
           license[key] = value;
   
           modifySystemLicense(system.id!, { [key]: value });
       }
       const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
   
   
       useEffect(() =>
       {
           if (!systemLicenseState.systemId && license.systemId) {
               setSystemLicenseState(license);
           }
       }, [system, license]); */

    return <>
        <SettingsCol >

            <SettingsCheckbox
                text={"List of zones for fire detector zones"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"List of actuations 1"}

                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"List of actuations 2"}

                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"List of actuations 3"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Inputs"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Submission - Networking"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Fire prevention plans"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Confirmation that work has been carried out by certified specialist company"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={""}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default SystemDocumentation;