

import { useL10n } from "@ews/react-localization-context";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { UserSystemProps } from "./types";

const UserSystem: React.FC<UserSystemProps> = ({ system }) =>
{

    const { translate: t } = useL10n();

    /*   const license = useSystemLicense(system.id!);
      const modifySystemLicense = useModifySystemLicense();
  
      function saveSystemLicense(key: keyof SystemLicense, value: any)
      {
          if (!system.id || !(key in license)) return;
  
  
          license[key] = value;
  
          modifySystemLicense(system.id!, { [key]: value });
      }
      const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
  
  
      useEffect(() =>
      {
          if (!systemLicenseState.systemId && license.systemId) {
              setSystemLicenseState(license);
          }
      }, [system, license]);
   */
    return <>
        <SettingsCol >
            <SettingsTextInput
                text={"Name"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"Address"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"Postal code"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"City"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />

            <SettingsSelect
                text={"Country"}
                tooltip={<TooltipIcon text={t("der Country der Anlage")} />}
                value={"austria"}
                onBlur={({ value }) =>
                {

                    /* setSystemDetailState({ ...systemDetailsState, country: value });
                    saveSystemDetails("country", value); */
                }}>
                <SettingsSelectOption text={"Österreich"} value={"austria"} />
                <SettingsSelectOption text={"Deutschland"} value={"germany"} />
                <SettingsSelectOption text={"Schweiz"} value={"schweiz"} />
                <SettingsSelectOption text={"Kroatien"} value={"kroatien"} />
                <SettingsSelectOption text={"Ungarn"} value={"ungaria"} />
                <SettingsSelectOption text={"Bosnien"} value={"bosnia"} />
                <SettingsSelectOption text={"Frankreich"} value={"france"} />
                <SettingsSelectOption text={"Niederlande"} value={"nederlands"} />
            </SettingsSelect>

            <SettingsTextInput
                text={"Tel. no."}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"tel"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />

            <SettingsTextInput
                text={"E-mail"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"email"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default UserSystem;