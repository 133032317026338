import { IonList, IonRow } from "@ionic/react";
import { useIsMobile } from "../../../../Responsive";
import { SettingsRowProps } from "./types";


const SettingsTableRow: React.FC<SettingsRowProps> = ({ children, style = undefined, hidden = false }) =>
{

    const mobileView = useIsMobile();

    return mobileView ?
        <IonList hidden={hidden} style={style}>{children}</IonList>
        :
        <IonRow hidden={hidden} style={style}>{children}</IonRow>;
};

export default SettingsTableRow;