import { Customer, CustomerDetails } from "@ews/websocket-service";
import { useEffect, useState } from "react";
import { useNamedRoutes } from "../../../NamedRoutes";

import
{
    useCustomer,
    useCustomerDetails,
    useModifyCustomer,
    useModifyCustomerDetails
} from "../../../ReactData/customer";

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import CustomerTypeAhead from "../../CustomerTypeAhead";
import { MainSettingsProps } from "./types";

const MainSettings: React.FC<MainSettingsProps> = ({ customer, }) =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const details = useCustomerDetails(customer.id || id!);
    const modifyCustomer = useModifyCustomer();
    const modifyCustomerDetails = useModifyCustomerDetails();

    const parentCustomer = useCustomer(customer.parentId || "");

    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({});
    const [allDetails, setAllDetails] = useState<boolean>(true);

    function saveCustomerDetails(key: keyof CustomerDetails, value: any)
    {
        if (!customer.id || !(key in details)) return;

        details[key] = value;
        setCustomerDetails(details);
        modifyCustomerDetails(customer.id, { [key]: value });
    }

    function saveCustomer(key: keyof Customer, value: any)
    {
        if (!customer.id || !(key in customer)) return;
        customer[key] = value;
        setCustomerDetails(details);
        modifyCustomer(customer.id, { [key]: value });
    }


    useEffect(() =>
    {
        if (customer.id && !customerDetails.customerId)
            setCustomerDetails(details);

    }, [customer, details]);

    return <>
        <SettingsCol>
            {/* <IonButton expand="full" onClick={() => { setAllDetails(!allDetails); }}>{t('show all details')}</IonButton> */}
            <SettingsCheckbox text={"Aktive"} disabled checked={true} onChange={(checked) => { }} />

            <SettingsLabel text={"Kunden-ID"} value={customer.id || ""} />
            <SettingsTextInput
                required={true}
                text={"Client name"}
                endText={' 1'}
                inputType={"text"}
                value={customer.name}
                onBlur={({ value }) =>
                {
                    if (customer.name === value) return;
                    saveCustomer("name", value);
                }} />
            <SettingsTextInput
                hidden={!allDetails}
                text={"Client name"}
                endText={' 2'}
                inputType={"text"}
                value={customerDetails.name2}
                onBlur={({ value }) =>
                {
                    if (customer.name2 === value) return;
                    saveCustomerDetails("name2", value);
                }} />
            <SettingsTextInput
                hidden={!allDetails}
                text={"Client name"}
                endText={' 3'}
                inputType={"text"}
                value={customerDetails.name3}
                onBlur={({ value }) =>
                {
                    if (customer.name3 === value) return;
                    saveCustomerDetails("name3", value);
                }} />
            <SettingsTextInput
                text={"Customer number"}
                disabled
                value={customer.customerNumber?.toString()}
            /* onBlur={({ value }) =>
            {
                if (customer.customerNumber === value) return;
                saveCustomer("customerNumber", value);
            }}  */
            />

            <SettingsTextInput
                text={"Address"}
                endText={' 1'}
                inputType={"text"}
                value={customerDetails.street}
                onBlur={({ value }) =>
                {
                    if (customerDetails.street === value) return;
                    saveCustomerDetails("street", value);
                }} />

            <SettingsTextInput
                hidden={!allDetails}
                text={"Address"}
                endText={' 2'}
                inputType={"text"}
                value={customerDetails.street2}
                onBlur={({ value }) =>
                {
                    if (customerDetails.street2 === value) return;
                    saveCustomerDetails("street2", value);
                }}
            />
            <SettingsTextInput
                text={"Postal code"}
                value={customerDetails.zip}
                onBlur={({ value }) =>
                {
                    if (customerDetails.zip === value) return;
                    saveCustomerDetails("zip", value);
                }} />
            <SettingsTextInput
                text={"City"}
                inputType={"text"}
                value={customerDetails.city}
                onBlur={({ value }) =>
                {
                    if (customerDetails.city === value) return;
                    saveCustomerDetails("city", value);
                }} />
            <SettingsSelect
                text={"Country"}
                value={customerDetails.country || ""}
                onBlur={({ value }) =>
                {
                    if (customerDetails.country === value) return;
                    saveCustomerDetails("country", value);
                }}>
                <SettingsSelectOption text={"Austria"} value={"AT"} />
                <SettingsSelectOption text={"Switzerland"} value={"CH"} />
                <SettingsSelectOption text={"Germany"} value={"DE"} />
            </SettingsSelect>
        </SettingsCol>
        <SettingsCol>
            <SettingsCheckbox
                text={"Save als template"}
                checked={false}
                disabled
                onChange={(checked) => { }} />

            <CustomerTypeAhead
                label="Assigned to"
                skipCustomers={[customer.id!]}
                currentId={parentCustomer.id || ""}
                onChange={(customerId => saveCustomer("parentId", customerId))}
            />
        </SettingsCol>
        <SettingsCol>

        </SettingsCol>
    </>;
};

export default MainSettings;