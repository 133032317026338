import { useL10n } from "@ews/react-localization-context";
import { System, SystemDetails, SystemStatus } from "@ews/websocket-service";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { useIsMobile } from "../Responsive";
import { TooltipIcon } from "../ToolTip/useTooltip";
import style from './SystemDetails.module.scss';

type DetailsProps = {
    system: System,
    details: SystemDetails;
};

const Details: React.FC<DetailsProps> = ({ system, details }) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    return (
        <div className={`${style['overview-main-container']}`}>
            <div
                className={`${style.card}`}
            >
                <IonList data-testid="list" lines={mobileView ? "full" : 'inset'} className={mobileView ? style['ion-item-mobile'] : ''}>

                    <IonItem lines="full" className={`${style.overflow}`}>

                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel >{`${t('[SID_MNU_TAB_INFO_SCR]')}:`}<TooltipIcon text={t("der name der Anlage")} /></IonLabel>
                        </IonItem>

                        <IonItem lines='none' className={`${style.overflow}`}>
                            <IonLabel>{system?.name || ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <span slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonItem lines='none' >
                                <IonLabel >{`${t('Alias')}:`}<TooltipIcon text={t("der Alias name der Anlage")} /></IonLabel>
                            </IonItem>
                        </span>
                        <IonItem lines='none' className={`${style.overflow}`}>
                            <IonLabel>{system?.alias || ''}</IonLabel>
                        </IonItem>
                    </IonItem>



                    <IonItem lines="full" className={`${style.overflow}`}>
                        <span slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonItem lines='none' >
                                <IonLabel >{`${t('Serialnumber')}:`}<TooltipIcon text={"das ist die Seriennummer zum indentifizieren des gerätes"} /></IonLabel>
                            </IonItem>
                        </span>
                        <IonItem lines='none' className={`${style.overflow}`}>
                            <IonLabel>{details.serialNumber || ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('Uid')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{system?.id || ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('Created at')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{system?.created ? new Date(system.created).toLocaleString() : ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('Updated at')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{system?.modified ? new Date(system.modified).toLocaleString() : ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('[SID_MNU_TAB_STATUS]')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{system?.status === SystemStatus.ONLINE ? "online" : "offline" || ""}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('[SID_SYSTEM_KEY]')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{system?.systemKey || ''}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('Street')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{`${details.street || ''}`}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('City')}:`}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{`${details.zip || ''} ${details.city || ''}`}</IonLabel>
                        </IonItem>
                    </IonItem>

                    <IonItem lines="full" className={`${style.overflow}`}>
                        <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                            <IonLabel>{`${t('Location')}:`}</IonLabel>
                        </IonItem>
                        {/* {details.location ? <IonItem lines="none" className={`${style.overflow}`}>
                            <IonLabel>{`Lat: ${system?.location?.lat}, Lng: ${system?.location?.lng}`}</IonLabel>
                        </IonItem> : null} */}
                    </IonItem>

                </IonList>
            </div>
        </div >
    );
};

export default Details;