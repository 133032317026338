import { SystemElement } from "../../../ReactData/system";

export const normalizeImgName = (name: string) =>
{
    return name.replaceAll(' ', '');
};

export const extractNameFromFilename = (filename: string) =>
{
    const regex = /[a-z()0-9- ]+(?=\.)/ig;
    return filename.match(regex) || '';
};

export const getPositionPXtoPercent = (posi: number, parentsize: number) =>
{
    return (posi / parentsize) * 100;
};

export function PXtoPercent(elementSize: number, parentsize: number)
{
    return (elementSize / parentsize) * 100;

}

export function isValidPositionToSetDevice(
    list: SystemElement[],
    planX: number,
    planY: number,
    width: number,
    height: number,
    noMargin = false,
    devider: number = 2
)
{

    const fullWidth = width;
    const fullHeight = height;
    const devidedWidth = noMargin ? 0 : fullWidth / devider;
    const devidedHeight = noMargin ? 0 : fullHeight / devider;

    const rangeList: boolean[] = [];

    list.forEach((exist) =>
    {
        const existX = Number(exist.planX);
        const existY = Number(exist.planY);

        const existTopCollision = existY - devidedHeight;
        const newTopCollision = planY - devidedHeight;
        const existBottomCollision = existY + fullHeight + devidedHeight;
        const newBottomCollision = planY + devidedHeight;

        const existLeftCollision = existX - devidedWidth;
        const newLeftCollision = planX - devidedWidth;

        const existRightCollision = existX + fullWidth + devidedWidth;
        const newRightCollision = planX + devidedWidth;

        const topCollision = newTopCollision <= existBottomCollision;
        const bottomCollision = newBottomCollision >= existTopCollision;

        const leftCollision = newLeftCollision <= existRightCollision;

        const rightCollision = newRightCollision >= existLeftCollision;

        const isRange = topCollision && bottomCollision && leftCollision && rightCollision;
        rangeList.push(!isRange);
    });

    return rangeList.every(item => item);

}

export const outOfRange = (planX: number, planY: number) =>
{
    if ((planX < 3 || planX > 98) || (planY < 3 || planY > 92)) return true;

    return false;
};

export const DevicesNotAtSamePositions = (list: SystemElement[], planX: number, planY: number) =>
{

    const samePlaceList: boolean[] = [];

    list.forEach(device =>
    {
        const sameHight = Number(device.planY) === planY;
        const sameWidth = Number(device.planX) === planX;

        samePlaceList.push(sameHight && sameWidth);
    });

    return samePlaceList.every((same) => same === false);
    /*  const coordinates = Object.values(object);
     if (coordinates.length) {
 
         const double = coordinates.filter((coordinate: SystemElement) =>
         {
             return coordinate.planX === planX && coordinate.planY === planY;
         });
         if (double.length) return true;
         else return false;
     }
     else {
         return false;
     } */
};
