import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonFabList, IonGrid, IonHeader, IonIcon, IonItem, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { caretDown, caretUp, close, filterOutline } from "ionicons/icons";
import { useState } from "react";
import { useIsMobile } from "../Responsive";
import style from './FilterList.module.scss';
import { BooleanFilterType, Filter, FilterFarbButtonProps, FilterListProps, NumberFilterType, StingFilterType } from "./types";


export const stringFilter: StingFilterType = (a: string, b: string) => a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
export const numberFilter: NumberFilterType = (a, b) => a >= b.from && a <= b.to;
export const booleanFilter: BooleanFilterType = (a: boolean, b: boolean) => a === b;


// const filterList = (list: any[], filter: Record<string, any>) =>
// {
//     const filtered = list.filter((data: Record<string, any>) =>
//     {
//         const checkList = [];

//         for (let key in data) {
//             if (!filter[key]) continue;
//             if (typeof filter[key] === 'function') {
//                 checkList.push(filter[key](data[key]));
//             }
//             else {
//                 checkList.push(false);
//                 throw new Error(`key: ${key} you forgot to give a filter function`);
//             };
//         }
//         return checkList.every((st) => st === true);
//     });

//     return filtered;
//};


const FilterFabButton: React.FC<FilterFarbButtonProps> = ({
    onClick,
    onReset,
    status = false,
    children
}) =>
{

    return (
        <IonFab
            vertical="top"
            horizontal="end">
            <IonFabButton
                size="small"
                color={'tertiary'}
                onClick={() => onClick(!status)}>
                <IonIcon icon={filterOutline} />
            </IonFabButton>
        </IonFab>
    );
};




const MobileFilter: React.FC<Filter> = ({
    children,
    details,
    isDetailsHidden = true,
    onReset,
    onDetailOpen,
}) =>
{
    const initialBreakpoint = 0.50;
    const [showModalDetails, setShowModalDetails] = useState(isDetailsHidden);

    // in der Zukunft Regelung der IonContent Höhe 
    //const [currentBreakpoint, setCurrentBreakpoint] = useState<number>(initialBreakpoint);

    return <>

        <FilterFabButton
            status={showModalDetails}
            onClick={(status: boolean) =>
            {
                setShowModalDetails(status);
            }}
            onReset={(value) =>
            {
                if (onReset) onReset(value);
            }}>
            <span>
                <IonIcon icon={showModalDetails ? caretUp : caretDown} />
            </span>
        </FilterFabButton>

        <IonModal
            className={style.modal}
            isOpen={showModalDetails}
            initialBreakpoint={.5}

            //breakpoints={[0.5, 1]}
            // in der Zukunft Regelung der IonContent Höhe 
            //onIonBreakpointDidChange={({ detail }) => setCurrentBreakpoint(detail.breakpoint)}
            showBackdrop={true}
            keepContentsMounted
            onDidDismiss={() => setShowModalDetails(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>filter</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => setShowModalDetails(false)}>
                            <IonIcon icon={close}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <div
            //className="ion-padding"
            >
                <IonButton
                    color={'danger'}
                    expand='full'
                    onClick={() =>
                    {
                        if (onReset) onReset(true);
                    }}
                >
                    reset
                </IonButton>
            </div>
            <IonContent
                className="ion-padding"
            >

                {/* <span style={{ display: "block", maxHeight: `${(currentBreakpoint * 100) - 7.72}vh` }}> */}
                <span>
                    {children}
                    {details}
                </span>
            </IonContent>
        </IonModal>
    </>;
};

const DesktopFilter: React.FC<Filter> = ({
    children,
    details,
    onReset,
    isDetailsHidden = true,
    showButton,
    onDetailOpen,
}) =>
{

    const [hideDetails, setHideDetails] = useState(isDetailsHidden);

    return <>
        {children ?
            <IonItem className={`filterList ${style.filterContainer}`}>
                <IonGrid>
                    <IonRow>
                        {children}
                    </IonRow>
                </IonGrid>
            </IonItem>
            : null}

        {/* {showButton ? <FilterFabButton
            status={hideDetails}
            onClick={(status: boolean) =>
            {
                setHideDetails(status);
                if (onDetailOpen) onDetailOpen(!status);
            }}
            onReset={(value) =>
            {
                if (onReset) onReset(value);
            }}
        >
            <IonIcon icon={filter} />
        </FilterFabButton>
            : null} */}

        <IonItem className={style.details} hidden={hideDetails}>
            <IonGrid hidden={hideDetails}>
                <IonRow>
                    {details}
                </IonRow>
            </IonGrid>
        </IonItem>
    </>;
};

const FilterList: React.FC<FilterListProps> = ({
    children,
    isDetailsHidden = true,
    details,
    showButton = true,
    onReset,
    onDetailOpen,

}) =>
{
    const mobileView = useIsMobile();

    function detailOpenHandler(status: boolean)
    {
        if (onDetailOpen) onDetailOpen(status);
    }

    return <div>
        {
            mobileView ?
                <MobileFilter
                    onReset={onReset}
                    isDetailsHidden={isDetailsHidden}
                    onDetailOpen={detailOpenHandler}
                    details={details}
                >
                    {children}
                </MobileFilter>
                :
                <DesktopFilter
                    showButton={showButton}
                    onDetailOpen={detailOpenHandler}
                    isDetailsHidden={isDetailsHidden}
                    onReset={onReset}
                    details={details}
                >
                    {children}
                </DesktopFilter>
        }
    </div>;
};

export default FilterList;