import
{
    useModifySystemMaintenance,
    useSystemMaintenance
} from "../../../ReactData/system";

import { SystemMaintenanceReport } from "@ews/websocket-service";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { MaintenanceReportsSettingsProps } from "./types";

const MaintenanceReportsSettings: React.FC<MaintenanceReportsSettingsProps> = ({ system }) =>
{
    const maintenance = useSystemMaintenance(system.id!);
    const modifySystemMaintenance = useModifySystemMaintenance();

    function saveSystemMaintenance(key: keyof SystemMaintenanceReport, value: any)
    {
        if (!system.id || !(key in maintenance)) return;
        maintenance[key] = value;
        modifySystemMaintenance(system.id, { [key]: value });
    }
    return <>
        <SettingsCol >
            <SettingsTitle
                text={"Time format for maintenance report"}
                endText={':'} />
            <SettingsCheckbox
                text={"Day"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Month"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Year"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Hour"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Minute"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Second"}
                disabled
                checked={false}
                onChange={(checked) => { }} />

        </SettingsCol>
        <SettingsCol >
            <SettingsTitle
                text={"Events for maintenance report"}
                endText={':'} />
            <SettingsCheckbox
                text={"Show actuations"}
                checked={maintenance.includeActuationDevices || false}
                onChange={(checked) => saveSystemMaintenance("includeActuationDevices", checked)} />
            <SettingsCheckbox
                text={"Show alarming devices"}
                checked={maintenance.includeAlarmingDevices || false}
                onChange={(checked) => saveSystemMaintenance("includeAlarmingDevices", checked)} />
            <SettingsCheckbox
                text={"Show transmitting devices"}
                checked={maintenance.includeTransmittingDevices || false}
                onChange={(checked) => saveSystemMaintenance("includeTransmittingDevices", checked)} />
            <SettingsCheckbox
                text={"Show leading zeros for elements"}
                checked={maintenance.formatLeadingZeros || false}
                onChange={(checked) => saveSystemMaintenance("formatLeadingZeros", checked)} />
            <SettingsCheckbox
                text={"Show event texts"}
                disabled
                checked={false}
                onChange={(checked) => { }} />
            <SettingsCheckbox
                text={"Show silent inputs"}
                checked={maintenance.includeSilentInputDevices || false}
                onChange={(checked) => saveSystemMaintenance("includeSilentInputDevices", checked)} />
            <SettingsCheckbox
                text={"Show silent outputs"}
                checked={maintenance.includeSilentOutputDevices || false}
                onChange={(checked) => saveSystemMaintenance("includeSilentOutputDevices", checked)} />

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default MaintenanceReportsSettings;