import { IonSegment, IonToolbar } from "@ionic/react";
import { useIsMobile } from "../../Responsive";
import style from './SettingsSegment.module.scss';
import { SettingsSegmentProps } from "./types";

const SettingsSegment: React.FC<SettingsSegmentProps> = ({ children, value, scrollable = true, onSelect }) =>
{

    const mobileView = useIsMobile();

    const scroll = mobileView ? scrollable : false;

    return <>

        <IonToolbar color="light">
            <IonSegment
                scrollable={scroll}
                className={style['segment-container']}
                value={value}
                onIonChange={(e) =>
                {
                    let segmentValue = e.detail.value;
                    onSelect(`${segmentValue!}`);
                }}>

                {children}


            </IonSegment>
        </IonToolbar>
    </>;
};

export default SettingsSegment;