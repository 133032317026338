import { NotificationChannel } from "@ews/websocket-service";
import SettingsTableColumn from "../../../../../../../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsCheckbox from "../../../../../../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { useIsMobile } from "../../../../../../../../../Responsive";
import { ItemProps } from "./types";

const Item: React.FC<
    ItemProps> = (
        {
            value,
            email = true,
            push = true,
            notificationsKey,
            className,
            disabled,
            onCheck
        }) =>
    {
        const mobileView = useIsMobile();

        return <>
            {push ?
                <SettingsTableColumn >
                    <SettingsCheckbox
                        disabled={disabled}
                        text={"Push"}
                        item={mobileView}
                        container={mobileView}
                        className={className}
                        label={mobileView}
                        checked={value ? (value & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                        onChange={(checked) => { onCheck({ key: notificationsKey, type: "PUSH", value: checked }); }} />
                </SettingsTableColumn> : null}

            {email ? <SettingsTableColumn >
                <SettingsCheckbox
                    disabled={disabled}
                    text={"E-mail"}
                    item={mobileView}
                    container={mobileView}
                    className={className}
                    label={mobileView}
                    checked={value ? (value & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                    onChange={(checked) => { onCheck({ key: notificationsKey, type: "EMAIL", value: checked }); }} />
            </SettingsTableColumn> : null}

        </>;
    };

export default Item;