import { useL10n } from "@ews/react-localization-context";
import { IonNote } from "@ionic/react";


import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsDropFile from "../../../Settings/SettingsComponents/SettingsDropFile/SettingsDropFile";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";

import { SystemDetails, SystemType } from "@ews/websocket-service";
import
{
    useCreateSystemSetup,
    useCreateSystemSetupCSV,
    useModifySystemDetails,
    useSystemDetails
} from "../../../ReactData/system";

import { useState } from "react";
import { useMyDateFormat } from "../../../Login/LoginProvider";
import { useUser, useUserName } from "../../../ReactData/user";
import { DetailsSettingsProps } from "./types";

const supportedTimezones = [
    "Europe/Vienna",
    "UTC"
]; //Intl.supportedValuesOf('timeZone');

const DetailsSettings: React.FC<DetailsSettingsProps> = ({ system }) =>
{

    const details = useSystemDetails(system.id!);
    const dateFormat = useMyDateFormat();

    const modifiedBy = useUserName(system.modifiedById || "", true);
    const createdBy = useUserName(system.createdById || "", true);

    const modifySystemDetails = useModifySystemDetails();

    const createSystemSetup = useCreateSystemSetup();
    const createSystemSetupCSV = useCreateSystemSetupCSV();

    const { translate: t } = useL10n();

    const [zonesCSV, setZonesCSV] = useState<File>();
    const [actuationsCSV, setActuationsCSV] = useState<File>();

    function saveSystemDetails(key: keyof SystemDetails, value: any)
    {
        if (!system.id || !(key in details)) return;

        details[key] = value;

        modifySystemDetails(system.id!, { [key]: value });
    }

    async function saveXMLCFile(file: File)
    {
        if (!system.id) return;
        return await createSystemSetup(system.id, file);
    }

    const saveActuationsCSV = async (file: File) =>
    {
        setActuationsCSV(file);
        if (zonesCSV) {
            if (!system.id) return;
            console.log("BBB");
            return await createSystemSetupCSV(system.id, zonesCSV, file);
        }
    };

    const saveZonesCSV = async (file: File) =>
    {
        setZonesCSV(file);
        if (actuationsCSV) {
            if (!system.id) return;
            console.log("AAA");
            return await createSystemSetupCSV(system.id, file, actuationsCSV);
        }
    };

    return <>
        <SettingsCol >
            <SettingsLabel
                text="[SID_SYSTEM_KEY]"
                value={system?.systemKey || ''} />
            <SettingsLabel
                text="Current maintenance report since"
                value={new Date().toLocaleString()} >
                <IonNote style={{ float: "right" }}>{t('for all elements')}</IonNote>
            </SettingsLabel>
            <SettingsLabel
                text="Alarm counter according to EN 54-2"
                value={"/alarmCounterToEN54_2".toString()} />

            <SettingsLabel
                text="Number of operating elements"
                value={system.inputDevices} />
            <SettingsLabel
                text="Number of actuation elements"
                value={system.outputDevices} />
            {system.systemType === SystemType.BC216 ?
                <>
                    <SettingsDropFile
                        text={"Zones csv file"}
                        onFile={(file) => saveZonesCSV(file)} />
                    <SettingsDropFile
                        text={"Actuations csv file"}
                        onFile={(file) => saveActuationsCSV(file)} />
                </>
                :
                <SettingsDropFile
                    text={"Actuation configuration file"}
                    onFile={(file) => saveXMLCFile(file)} />

            }

        </SettingsCol>
        <SettingsCol >
            <SettingsLabel
                text="Last modified"
                value={dateFormat(new Date(system.modified))} />
            <SettingsLabel
                text="Created on"
                value={dateFormat(new Date(system.created))} />
            <SettingsLabel
                text="Modified by user"
                value={modifiedBy} />
            <SettingsLabel
                text="Created by user"
                value={createdBy} />
            <SettingsLabel
                text="Created by client"
                value={system.customerId?.toString() || ""} />
            <SettingsSelect
                text={"Time zone"}
                value={details.timezone?.toString() || ""}
                onBlur={({ value }) => saveSystemDetails("timezone", value)}>
                {supportedTimezones.map((timezone) =>
                {
                    return <SettingsSelectOption key={timezone} text={timezone} value={timezone} />;
                })}
                {/* <SettingsSelectOption text={""} value={""} />
                <SettingsSelectOption text={"Wien"} value={"wien"} />
                <SettingsSelectOption text={"Berlin"} value={"berlin"} />
                <SettingsSelectOption text={"cern"} value={"cern"} /> */}
            </SettingsSelect>
            <SettingsCheckbox
                disabled
                text={"Daylight saving time"}
                checked={false}
                onChange={(checked) => { console.log(checked); }} />

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default DetailsSettings;