import { useL10n } from "@ews/react-localization-context";
import { InputChangeEventDetail, InputInputEventDetail } from "@ionic/core";
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsTextInput.module.scss';
import { SettingsTextInputProps } from "./types";
import { closeCircleOutline } from "ionicons/icons";

const SettingsTextInput: React.FC<SettingsTextInputProps> = ({
    endText = '',
    startText = '',
    onChange,
    textAlign,
    inputType = 'text',
    value,
    hidden = false,
    labelPosition = 'stacked',
    text = '',
    disabled,
    hasFocus,
    required,
    lines = undefined,
    debounce,
    tooltip,
    onFocus,
    onClick,
    onBlur,
    onClear,
    onEnter,
    id,
    inputRef,
    icon
}) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const inputClass = mobileView ? globalStyle.mobileInput : globalStyle.webInput;
    const InputValue = useRef<string | null | undefined>(value);
    const innerInputRef = useRef<HTMLIonInputElement>(null);

    const _hasFocus = useRef<boolean>(false);

    const getInputValue = () =>
    {
        return inputRef?.current?.value?.toString() || innerInputRef?.current?.value?.toString();
    };

    const handleFocusAfterReload = (ref: React.RefObject<HTMLIonInputElement>) =>
    {
        ref.current?.setFocus();
    };

    const handleFocusOut = () =>
    {
        _hasFocus.current = false;
    };
    const handleFocusIn = () =>
    {
        _hasFocus.current = true;
    };

    const handleChange = (detail: InputInputEventDetail | InputChangeEventDetail) =>
    {
        InputValue.current = detail.value;
        if (onChange) onChange(detail.value || '');
    };

    if (hasFocus) handleFocusAfterReload(inputRef || innerInputRef);

    useEffect(() =>
    {
        InputValue.current = value;
    }, [value]);

    return <IonItem
        data-testid="settings-input-container"
        hidden={hidden}
        lines={lines}
        className={`${style.overflow} ${globalStyle.setting} ${hasFocus ? style.hasFocus : ''}`}>
        <div className={style.container}>
            <div className={style.containerInput}>
                <span className={`${style.flex} ${style.justifyStart}`}>
                    <IonLabel
                        data-testid="settings-input-label"
                        className={style.label}
                        style={{ whiteSpace: "break-spaces" }}
                        position={labelPosition}>
                        {`${startText}${t(text)}${endText}`}{required ? <span className={`${globalStyle.required}`}>*</span> : null}
                    </IonLabel>
                    <span className={style.tooltip}>
                        {mobileView ? <></> : tooltip}
                    </span>

                </span>
                <IonInput
                    data-testid="settings-input"
                    id={id || undefined}
                    label={``}
                    debounce={debounce}
                    autocomplete={'off'}
                    ref={inputRef || innerInputRef}
                    onClick={(e) => { if (onClick) onClick(e as any); }}
                    onIonFocus={(e) =>
                    {
                        handleFocusIn();
                        if (onFocus) onFocus(e);
                    }
                    }
                    onIonBlur={(e) =>
                    {
                        handleFocusOut();
                        if (onBlur) onBlur({ value: InputValue.current, focus: _hasFocus.current, event: e });
                    }}
                    required={required}
                    disabled={disabled}
                    value={value}
                    type={inputType}
                    className={`${mobileView ? style.textRight : style.textLeft} `}
                    style={textAlign ? { textAlign } : {}}
                    onKeyDown={(e) =>
                    {
                        const { code } = e;
                        if (!code || code.toLowerCase() !== "enter") return;
                        const value = getInputValue();
                        if (onEnter) onEnter(value || "");
                    }}
                    onIonInput={({ detail }) =>
                    {
                        if (debounce) handleChange(detail);
                    }}
                    onIonChange={({ detail }) =>
                    {
                        if (!debounce) handleChange(detail);

                    }}  //onChange(inputType === 'number' ? (Number(detail.value) || 0) : detail.value || '')} 
                >
                    {icon && <IonButton fill="default" slot="end" aria-label="Find">
                        <IonIcon slot="icon-only" icon={icon} aria-hidden="true"></IonIcon>
                    </IonButton>}


                </IonInput>

            </div>
        </div>
    </IonItem>;
};

export default SettingsTextInput;