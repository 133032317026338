import { useL10n } from "@ews/react-localization-context";
import { UserDetails } from "@ews/websocket-service";
import { useMyDateFormat, useMyDetails } from "../../../Login/LoginProvider";
import { useModifyUserDetails, useUser, useUserDetails } from "../../../ReactData/user";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { DetailsSettingsProps } from "./types";

const DetailsSettings: React.FC<DetailsSettingsProps> = ({ id }) =>
{
    const user = useUser(id);
    const data = useUserDetails(id);
    const myDetails = useMyDetails();
    const dateFormat = useMyDateFormat();

    const { name: createdBy } = useUser(user.createdById || "");
    const { name: modifiedBy } = useUser(user.modifiedById || "");

    const modifyData = useModifyUserDetails();

    const modify = async (data: UserDetails) =>
    {
        await modifyData(user.id!, data);
    };

    const { translate: t } = useL10n();

    return <>
        <SettingsCol>
            <SettingsLabel text={"Created on"} value={createdBy} />
            <SettingsLabel text={"Created by"} value={modifiedBy} />
            <SettingsLabel text={"Last activity"} value={user.lastLogin ? dateFormat(new Date(user.lastLogin)) : ""} />
            <SettingsTextInput debounce={900} text={"Code authorization level 2"} inputType={"text"} value={data.codeLevel2} onChange={(codeLevel2) => { modify({ codeLevel2 }); }} />
            <SettingsTextInput debounce={900} text={"Code authorization level 3"} inputType={"text"} value={data.codeLevel3} onChange={(codeLevel3) => { modify({ codeLevel3 }); }} />
            <SettingsSelect text={"Icon color"} value={data.iconColor} onSelect={(iconColor) => modify({ iconColor })}>
                <SettingsSelectOption text={"Red"} value={"red"} />
                <SettingsSelectOption text={"Blue"} value={"blue"} />
                <SettingsSelectOption text={"Yellow"} value={"yellow"} />
                <SettingsSelectOption text={"Green"} value={"green"} />
                <SettingsSelectOption text={"Black"} value={"black"} />
            </SettingsSelect>

            <SettingsSelect text={"Indication in case of fire alarm"} value={data.alarmView} onSelect={(alarmView) => modify({ alarmView })}>
                <SettingsSelectOption text={"Plans"} value={"plans"} />
                <SettingsSelectOption text={"Blue"} value={"blue"} />
            </SettingsSelect>

            <SettingsSelect text={"Logoff time permission"} value={data.timerLogin} onSelect={(timerLogin) => modify({ timerLogin: Number(timerLogin) })}>
                <SettingsSelectOption text={"10 " + t('minutes')} value={"10"} />
                <SettingsSelectOption text={"11 " + t('minutes')} value={"11"} />
                <SettingsSelectOption text={"1 " + t('minutes')} value={"1"} />
                <SettingsSelectOption text={"2 " + t('minutes')} value={"2"} />
                <SettingsSelectOption text={"3 " + t('minutes')} value={"3"} />
                <SettingsSelectOption text={"5 " + t('minutes')} value={"5"} />
            </SettingsSelect>
            <SettingsSelect text={"Logoff time app"} value={data.timerAuthorisation} onSelect={(timerAuthorisation) => modify({ timerAuthorisation: Number(timerAuthorisation) })}>
                <SettingsSelectOption text={"10 " + t('days')} value={"10"} />
                <SettingsSelectOption text={"11 " + t('days')} value={"11"} />
                <SettingsSelectOption text={"1 " + t('days')} value={"1"} />
                <SettingsSelectOption text={"2 " + t('days')} value={"2"} />
                <SettingsSelectOption text={"3 " + t('days')} value={"3"} />
                <SettingsSelectOption text={"5 " + t('days')} value={"5"} />
            </SettingsSelect>
        </SettingsCol>
        <SettingsCol>

            <SettingsTitle text={"Icons flashing at"} endText={':'} />

            <SettingsCheckbox text={"[SID_MNU_HK_FIRE_ALARMS]"} checked={false} onChange={(checked) => { console.log("please implement me in the Database"); }} />
            <SettingsCheckbox text={"[SID_DEV_STATE_PREALARM]"} checked={false} onChange={(checked) => { console.log("please implement me in the Database"); }} />
            <SettingsCheckbox text={"[SID_EV_FIRE_PREALARM_SH]"} checked={false} onChange={(checked) => { console.log("please implement me in the Database"); }} />
            <SettingsCheckbox text={"[SID_EV_FIRE_TEST_ALARM_SH]"} checked={false} onChange={(checked) => { console.log("please implement me in the Database"); }} />

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default DetailsSettings;