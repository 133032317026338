import { useL10n } from "@ews/react-localization-context";
import
{
    appsOutline,
    options, peopleOutline, personAddOutline, personOutline, statsChartOutline, trashOutline
} from "ionicons/icons";

import { FC } from "react";
import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import
{
    SystemStatusIcon
} from "../../Icons";

import type {
    MenuToggleProps
} from "../types";

import
{
    AcquireAuthorization
} from "./Main";

import
{
    useMyPermissions
} from "../../Login/LoginProvider";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/websocket-service";

import NewCustomerModal from "../../Customer/NewCustomer/NewCustomerModal";
import { ReactClickEvent } from "../../types";
import NewUserModal from "../../User/NewUser/NewUserModal";
import NewSystemModal from "../../System/NewSystem/NewSystemModal";


type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const Customer: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const { id: customerId } = routeParams;
    const permissions: UserPermissions = useMyPermissions();

    title = title || t("Customersettings");

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {/* <AcquireAuthorization onMenuToggle={onMenuToggle} /> */}
                {permissionAllowed(permissions.createCustomer) ? <MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('customer', routeParams)}
                    icon={options}
                /> : <></>}

                {permissionAllowed(permissions.createCustomer) ? <MenuLink
                    title={t('[SID_MNU_HK_DELETE]')}
                    onClick={onMenuToggle}
                    icon={trashOutline}
                /> : <></>}
                {permissionAllowed(permissions.createCustomer) ? <>
                    {/* <MenuLink
                    title={t('Create Customer')}
                    onClick={onMenuToggle}
                    link={generate('customer:addcustomer', routeParams)}
                    icon={peopleOutline}
                /> */}
                    <MenuLink
                        title={t('Create Customer')}

                        id="newCustomerFromCustomer"
                        icon={peopleOutline}
                    />
                    <NewCustomerModal
                        parentId={customerId}
                        onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                        trigger={"newCustomerFromCustomer"} />
                    <MenuSpacer /></> : <></>}
                <MenuLink
                    title={t('Darunterliegende Kunden')}
                    onClick={onMenuToggle}
                    link={generate('customer:customers', routeParams)}
                    icon={options}
                />
                <MenuSpacer />
                {permissionAllowed(permissions.createUser) ?
                    // <MenuLink
                    //     title={t('Create User')}
                    //     onClick={onMenuToggle}
                    //     link={generate('customer:adduser', routeParams)}
                    //     icon={personAddOutline}
                    // />
                    <>
                        <MenuLink
                            title={t('Create User')}
                            id="newUserFromCustomer"
                            icon={personAddOutline}
                        />
                        <NewUserModal
                            customerId={customerId}
                            onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                            trigger={"newUserFromCustomer"} /></>
                    : <></>}
                <MenuLink
                    title={t('List users')}
                    onClick={onMenuToggle}
                    link={generate('customer:users', routeParams)}
                    icon={personOutline}
                />
                <MenuSpacer />
                {permissionAllowed(permissions.grantSystemAccess) ?
                    // <MenuLink
                    //     title={t('Add system')}
                    //     onClick={onMenuToggle}
                    //     icon={<SystemStatusIcon height={24} width={24} />}
                    // /> 
                    <>
                        <MenuLink
                            title={t('Add system')}
                            id="newSystemFromCustomer"
                            icon={<SystemStatusIcon height={24} width={24} />}
                        />
                        <NewSystemModal
                            customerId={customerId}
                            onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                            trigger={"newSystemFromCustomer"} /></>

                    : <></>}
                <MenuLink
                    title={t('List systems')}
                    onClick={onMenuToggle}
                    link={generate('customer:systems', routeParams)}
                    icon={appsOutline}
                />
                <MenuSpacer />
                <MenuLink
                    title={t('Event storage')}
                    link={generate('customer:events', routeParams)}
                    onClick={onMenuToggle}
                    icon={statsChartOutline}
                />
            </SidebarSection>
        </Sidebar>
    );

};

export default Customer;