import packageInfo from '../../../../package.json';

import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    useIonRouter
} from "@ionic/react";

import
{
    help,
    lockClosed,
    lockOpen,
    person,
    power
} from "ionicons/icons";

import
{
    FC, ReactElement, useState
} from "react";

import
{
    MenuItem,
    MenuLink,
    Sidebar,
    SidebarSection,
} from "./Sidebar";

import
{
    MenuToggleProps
} from "../types";

import
{
    useNamedRoutes
} from "../../NamedRoutes";

import
{
    AuthorizationLevel
} from "@ews/zlt-events";

import
{
    useAuthorization
} from "../../Authorization";

import
{
    ReactClickEvent
} from "../../types";

import
{
    useAuth,
    useMyPermissions,
    //useSendDeveloperInformation
} from "../../Login/LoginProvider";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/websocket-service";

import style from './Main.module.scss';

type MenuToggleEvent = ReactClickEvent;
type BlurEvent = React.FocusEvent<HTMLIonInputElement>;

export type MainSidebarProps = MenuToggleProps & {
    children?: ReactElement;
};

export const AcquireAuthorization: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{

    const { authorize, authorizationLevel } = useAuthorization();
    const [error, setError] = useState("");
    const { translate: t } = useL10n();
    const permissions: UserPermissions = useMyPermissions();

    const authorizeWrapper = async (e: MenuToggleEvent, level: AuthorizationLevel, code?: string) =>
    {
        if (await authorize(level, code)) {
            onMenuToggle && onMenuToggle(e);
            return true;
        } else {
            setError("Authorization failed");
            return false;
        }
    };

    const handleBlur = async (e: BlurEvent, authorizationLevel: AuthorizationLevel) =>
    {
        const code = e.target.value || "";
        const success = await authorizeWrapper(e as unknown as MenuToggleEvent, authorizationLevel, `${code}`);
        if (!success) {
            e.target.focus();
            (e.target as unknown as HTMLInputElement).select();
        }
    };

    if (authorizationLevel > AuthorizationLevel.LEVEL1) {
        return (
            <IonItem detail={false} onClick={(e) => authorizeWrapper(e, AuthorizationLevel.LEVEL1)} button={true} >
                <IonLabel>{t('Exit Authorization')}</IonLabel>
                <IonIcon icon={lockClosed} />
            </IonItem>
        );
    }

    return (
        <>
            {permissionAllowed(permissions.permitAccessLevel2) ? <MenuItem className={error ? "ion-invalid" : ""}>
                <IonLabel position="stacked">{t('Authorization Level %{authorizationLevel}', { authorizationLevel: '2' })}</IonLabel>
                <IonInput label="" placeholder={t('Enter Code')} errorText={t(error)} onBlur={(e) => handleBlur(e, AuthorizationLevel.LEVEL2)} />
                {/* INFO:
                    seit V7 nicht mehr unterstüzt bitte bei IonItem errorText verwenden
                    <IonNote slot="error">{t(error)}</IonNote> 
                */}
                <IonIcon slot="end" icon={lockOpen}></IonIcon>
            </MenuItem > : <></>}
            {permissionAllowed(permissions.permitAccessLevel3) ? <MenuItem className={error ? "ion-invalid" : ""}>
                <IonLabel position="stacked">{t('Authorization Level %{authorizationLevel}', { authorizationLevel: '3' })}</IonLabel>
                <IonInput label="" placeholder={t('Enter Code')} errorText={t(error)} onBlur={(e) => handleBlur(e, AuthorizationLevel.LEVEL3)} />
                {/* INFO:
                    seit V7 nicht mehr unterstüzt bitte bei IonItem errorText verwenden
                    <IonNote slot="error">{t(error)}</IonNote> 
                */}
                <IonIcon slot="end" icon={lockOpen}></IonIcon>
            </MenuItem > : <></>}
        </>
    );

};

const LanguageSelector: FC = () =>
{
    const { translate: t, getLocale, getLocales } = useL10n();
    const routes = useNamedRoutes();
    const router = useIonRouter();

    const localeChange = (e: CustomEvent) =>
    {
        const locale = e.detail.value;
        router.push(routes.generate(null, { locale }));
    };

    return (
        <MenuItem>
            <IonSelect
                onIonChange={localeChange}
                value={getLocale()}
                interface="popover"
                style={{ width: "100%" }}
                placeholder={t('Select language')}
            >
                {getLocales().map((locale) =>
                {
                    return (
                        <IonSelectOption
                            key={locale}
                            value={locale}
                        >
                            {t(locale)}
                        </IonSelectOption>
                    );
                })}

            </IonSelect>
        </MenuItem>
    );
};

const Main: FC<MainSidebarProps> = ({ onMenuToggle, children }) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const { logout } = useAuth();
    //const [token, setToken] = useState("");
    //const sendDeveloperInformation = useSendDeveloperInformation();

    //NotificationPlugin.getToken().then((result) => setToken(result.value));

    return (
        <Sidebar title={t('Menu')} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {/* <AcquireAuthorization onMenuToggle={onMenuToggle} /> */}
                <LanguageSelector />
                <MenuLink
                    title={t('My profile')}
                    onClick={onMenuToggle}
                    link={generate('home')}
                    icon={person}
                />
            </SidebarSection>
            {children && <SidebarSection>{children}</SidebarSection>}
            <SidebarSection>
                <MenuLink
                    title={t('Help')}
                    onClick={onMenuToggle}
                    link={generate('home')}
                    icon={help}
                />
                <MenuLink
                    title={t('[SID_DLG_HEADER_AUTHEND]')}
                    onClick={logout}
                    // onClick={onMenuToggle}
                    // link={generate('home')}
                    icon={power}
                />

                <MenuItem className={style.versionInfo}>
                    {packageInfo.version}
                </MenuItem>
                {/*                {token && <MenuLink
                    title={t('Dev Info')}
                    onClick={() => sendDeveloperInformation(token)}
                />} */}

            </SidebarSection>

        </Sidebar >
    );

};

export default Main;;;