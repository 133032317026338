import { useL10n } from "@ews/react-localization-context";
import { IonContent, IonInput, IonLabel, IonSegmentButton } from "@ionic/react";
import { useRef, useState } from "react";
import { SwiperSlide, useSwiper } from "swiper/react";
import { useIsMobile } from "../../../Responsive";
import SettingsSwiper from "../../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import SettingsGrid from "../../../Settings/SettingsGrid/SettingsGrid";
import SettingsSegment from "../../../Settings/SettingsSegment/SettingsSegment";


import { DisplayTabProps, Page, SettingsOverlayProps } from "./types";
//import style from './SettingsOverlay.module.scss';
import Overflow from "../../../Overflow/Overflow";
import DetailsSettings from "../DetailsSettings/DetailsSettings";
import EmailSettings from "../EmailSettings/EmailSettings";
import LanguageFormatSettings from "../LanguageFormatSettings/LanguageFormatSettings";
import MainSettings from "../MainSettings/MainSettings";
import PushSettings from "../PushSettings/PushSettings";
import PermissionsSettings from "../PermissionsSettings /PermissionsSettings";
import style from './SettingsOverlay.module.scss';


const DisplayTab: React.FC<DisplayTabProps> = ({ name, display, children }) =>
{

    if (name === display) {
        return <>{children}</>;
    }

    return <></>;

};


const SettingsOverlay: React.FC<SettingsOverlayProps> = ({ user, onClose }) =>
{

    console.log("SettingsOverlay user: ", user);
    const pageList: Page[] = [
        {
            value: 'general',
            title: "General",
            child: <MainSettings id={user.id} />
        },
        {
            value: 'details',
            title: "Details",
            child: <DetailsSettings id={user.id} />
        },
        {
            value: 'system-view',
            title: "System View",
            child: <>{/* <SystemViewSettings buttonRef={systemViewButtonRef} user={user}  /> */}</>
        },
        {
            value: 'rights',
            title: "Rights",
            child: <PermissionsSettings id={user.id} />
        },
        {
            value: 'push',
            title: "Push",
            child: <PushSettings id={user.id} />
        },
        {
            value: 'email',
            title: "Email",
            child: <EmailSettings id={user.id} />
        },
        {
            value: 'language/format',
            title: "Language/format",
            child: <LanguageFormatSettings id={user.id} />
        },
    ];

    const mobileView = useIsMobile();
    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <section>{children}</section>;

    const [currentSegment, setCurrentSegment] = useState<string>('general');
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const { translate: t } = useL10n();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={(e) =>
            {
                swiper.slideTo(slideToRef.current);
            }}
            hidden />;
    };

    return <>{user.id ?
        <IonContent id="settingsContent" className={style.overlaySettings} fullscreen>

            <SettingsSegment value={currentSegment} onSelect={(current: string) => setCurrentSegment(current)}>
                {pageList.map((segment, index) => (

                    <IonSegmentButton key={segment.value} value={segment.value} onClick={() =>
                    {
                        if (mobileView) {
                            slideToRef.current = index;
                            SwiperRef.current.click();
                            setCurrentSegment(segment.value);
                        }
                    }}>
                        <IonLabel>{t(segment.title)}</IonLabel>
                    </IonSegmentButton>
                ))}
            </SettingsSegment>

            <SettingsSwiper onSlideChange={(index) =>
            {
                slideToRef.current = index;
                setCurrentSegment(pageList[index].value);

            }}>
                {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}

                {pageList.map((page, index) =>
                {
                    const { child, value } = page;
                    return <SliderComponent key={index}>
                        <DisplayTab name={value} display={currentSegment}>
                            <Overflow minHeight="80vh" maxHeight="87vh">
                                <SettingsGrid>
                                    {child}
                                </SettingsGrid>
                            </Overflow>
                        </DisplayTab>
                    </SliderComponent>;
                })}

            </SettingsSwiper>
        </IonContent> : null}

    </>;

};

export default SettingsOverlay;