import { useModifySystemShareNotifications, useSystemNotifications, useSystemShareNotifications } from '../../../../../../../ReactData/system';
import { useUserNotifications } from '../../../../../../../ReactData/user';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import NotificationTable from '../components/NotificationsTable/NotificationsTable';
import NumberRange from '../components/NumberRange';
import Ranges from '../components/Ranges/Ranges';
import { Props } from './types';

import
{
    SystemShareNotifications
} from '@ews/websocket-service';

const Push: React.FC<Props> = ({ systemId, userId }) =>
{
    const notifications = useSystemShareNotifications(systemId || "", userId);
    const userNotifications = useUserNotifications(userId || "");
    const systemNotifications = useSystemNotifications(systemId || "");

    const modify = useModifySystemShareNotifications();
    const modifyData = async (data: SystemShareNotifications) => await modify(systemId, userId, data);


    return <>

        <SettingsCol>
            <NotificationTable
                notification={notifications}
                title='Share'
                onCheck={(v) => console.log(v)}
            />
        </SettingsCol >
        <SettingsCol>
            <NotificationTable
                notification={systemNotifications}
                title='System'
                disabled
                onCheck={(v) => console.log(v)}
            />
        </SettingsCol >
        <SettingsCol>
            <NotificationTable
                notification={userNotifications}
                title='User'
                disabled
                onCheck={(v) => console.log(v)}
            />
        </SettingsCol >
        <SettingsCol>

            <NumberRange
                label='Zone'
                from={notifications.fromZone}
                to={notifications.toZone}
                onChange={(fromZone, toZone) => modifyData({ fromZone, toZone })}
            />

            <NumberRange
                label='Action'
                from={notifications.fromActuation}
                to={notifications.toActuation}
                onChange={(fromActuation, toActuation) => modifyData({ fromActuation, toActuation })}
            />

            <NumberRange
                label='AlarmingDevice'
                from={notifications.fromAlarmingDevice}
                to={notifications.toAlarmingDevice}
                onChange={(fromAlarmingDevice, toAlarmingDevice) => modifyData({ fromAlarmingDevice, toAlarmingDevice })}
            />
            <NumberRange
                label='TransmissionDevice'
                from={notifications.fromTransmissionDevice}
                to={notifications.toTransmissionDevice}
                onChange={(fromTransmissionDevice, toTransmissionDevice) => modifyData({ fromTransmissionDevice, toTransmissionDevice })}
            />
        </SettingsCol >
    </>;
};
export default Push;
