import React from "react";
import { Swiper } from "swiper/react";
import { useIsMobile } from "../../../Responsive";
import style from './SettingsSwiper.module.scss';
import { SettingsSwiperProps } from "./types";

const SettingsSwiper: React.FC<SettingsSwiperProps> = ({

    onSlideChange,
    children,
    height,
    className,
    loop,
    modules,
    spaceBetween = 40,
    slidesPerView = 1 }) =>
{
    const mobileView = useIsMobile();

    const SwiperComponent = Swiper;//mobileView ? Swiper : React.Fragment; //({ spaceBetween, slidesPerView, className, children }: { className?: string, spaceBetween: number, slidesPerView: number, children: React.ReactNode; }) => <>{children}</>;

    return <SwiperComponent
        modules={modules}
        loop={loop}
        className={`${className} ${style.container}`}
        style={{ minHeight: height, maxHeight: height }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        onSlideChange={(e: any) =>
        {
            if (onSlideChange) onSlideChange(e);
        }}
    >
        {children}
    </SwiperComponent >;
};

export default SettingsSwiper;