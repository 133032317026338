// import { systems } from "../../data/systems";

// let filterValue = '';

// const list = Array.from(new Set(systems.map((system) => system.name)));
// let timercount = 0;

/* export const getList = async (value: string) =>
{
    filterValue = value;
    if (!(timercount >= 1)) {
        let response: Promise<any[]> = new Promise((resolve) =>
        {
            setTimeout(() =>
            {
                timercount = 0;
                resolve(list);
            }, 5000);
        });

        return (await response).filter((item: string) => item.toLocaleLowerCase().includes(filterValue.toLowerCase()));
    }
    timercount++;


}; */
interface Index
{

    [index: string]: any;
}


export const getList = (list: any[], key: string): Promise<any[]> =>
{
    return new Promise(resolve =>
        resolve(
            Array.from(
                new Set(list.map((item: Index) => item[key]
                ))))
    );
};


export const getTypeaheadListPerAPI = async (url: string, headers?: HeadersInit) =>
{
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    });
    const jsonData = await response.json();
    return jsonData;
};