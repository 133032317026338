import { useL10n } from "@ews/react-localization-context";
import { FC } from "react";
import
{
    Navbar,
    NavbarLink
} from "./Navbar";

import
{
    apps,
    map,
    menu

} from "ionicons/icons";
import { useIsMobile } from "../../Responsive";
import { MenuToggleProps } from "../types";
import { useNamedRoutes } from "../../NamedRoutes";
import { iif } from "../../../utils";

const System: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const isMobile = useIsMobile();

    const color = isMobile ? 'secondary' : 'light';

    return (
        <Navbar
            onMenuToggle={onMenuToggle}
            color={color}
        >
            <NavbarLink
                show={!isMobile}
                title={t('TD_SYSTEM')}
                link={generate('system')}
            />
            <NavbarLink
                icon={iif(isMobile, apps)}
                title={t('[SID_MNU_HK_EVENTS]')}
                link={generate('system:events')}
            />
            <NavbarLink
                icon={iif(isMobile, menu)}
                title={t('Elements')}
                link={generate('system:elements')}
            />
            <NavbarLink
                icon={iif(isMobile, map)}
                title={t('Plan')}
                link={generate('system:plan')}
            />
        </Navbar>
    );

};

export default System;