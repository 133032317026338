import { IonBadge, IonCard, IonCardContent } from "@ionic/react";


import { useL10n } from "@ews/react-localization-context";
import { EventCategoryIcon } from "../../Icons";
import { EventType } from '../types';

import eventStyle from './EventCardButton.module.scss';

type EventCardButtonProps = {
    // eventColor?: string,
    count: number;
    swiperIndex: number;
    title: string;
    onClick: (page: number) => void;
    // color?: string | undefined;
    type: EventType;
};

const CardColors: Partial<Record<EventType, string>> = {
    'Alarm': "firealarm"
};

const EventCardButton: React.FC<EventCardButtonProps> = ({
    // eventColor,
    count = 0,
    swiperIndex,
    title,
    onClick,
    type
}) =>
{

    /*let colorCard = "event";
    if (eventColor && count > 0) colorCard = "firealarm";
    if (count === 0) colorCard = "secondary";
    */

    const colorCard = count ? CardColors[type] || 'event' : 'secondary';

    const { translate: t, getLocale } = useL10n();

    /* style={colorCard === 'event' ? { background: 'linear-gradient(#1d8bff 0%, #0f5cae 100%)' } : {}} */
    return (<>
        <IonCard color={colorCard} className={`${eventStyle.middle} ${eventStyle.eventOverviewButton}`} onClick={() =>
        {
            onClick(swiperIndex);
        }}>
            <IonCardContent className={eventStyle.cardFlex}>

                <div>

                    <EventCategoryIcon
                        width={45}
                        height={45}
                        className={`${eventStyle.eventBtn}`}
                        category={type as EventType}
                    />
                </div>
                <div className={`${eventStyle.paddingBottom}`}>
                    <p className={`${eventStyle.eventCategoryTitle} ${getLocale() === 'de' || getLocale() === 'en' ? '' : eventStyle.textOverflow}`}>
                        {t(title.split('')[0].toUpperCase() + title.slice(1, title.length))}
                    </p>

                </div>
                <div>
                    <IonBadge className={`${eventStyle.eventCategorieBadge}`} color={'dark'}  >{count}</IonBadge>
                </div>
            </IonCardContent>

        </IonCard>



    </>);
};
export default EventCardButton;