import { IonItem } from "@ionic/react";
import { Polygon } from 'geojson';
import L, { DrawEvents, LatLngExpression, Map as LeafletMap, Point } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import React, { useRef } from "react";
import { FeatureGroup, GeoJSON } from "react-leaflet";
import { EditControl } from 'react-leaflet-draw';
import Language from "../../Map/Components/Language";
import Map from "../../Map/Map";
import style from "./CompanyMapPolygon.module.scss";
import { Props } from "./types";

const CompanyMapPolygon: React.FC<Props> = ({
    area,
    position,
    onChange
}) =>
{
    // work around broken icons when using webpack, see https://github.com/PaulLeCam/react-leaflet/issues/255
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
    });

    const editLayer = useRef<L.FeatureGroup>(null);

    console.log(area, position);

    const notifyChange = (layer: L.Layer) =>
    {

        if (layer instanceof L.Polygon) {
            if (onChange) onChange({ "GPSArea": layer.toGeoJSON().geometry as Polygon });
        } else if (layer instanceof L.Marker) {
            if (onChange) onChange({ "GPSPosition": layer.toGeoJSON().geometry });
        }
    };

    const _onCreate = (e: DrawEvents.Created) =>
    {
        notifyChange(e.layer);
    };

    const _onDelete = (e: DrawEvents.Deleted) =>
    {
        if (onChange) onChange({ "GPSArea": null, "GPSPosition": null });
    };

    const onAreaAdded = ((feature: any, layer: L.Layer) =>
    {
        setTimeout(() =>
        {
            const map: LeafletMap = (layer as any)._map;
            map && map.fitBounds(new L.GeoJSON(feature).getBounds());
        }, 10);
    });

    return <>
        <IonItem >
            <div className={style.container}>
                <Map
                    className={style.leafletContainer}
                    center={[48.19, 16.33]}
                    zoom={12}

                // bounds={bounds}
                >
                    <Language />
                    <FeatureGroup ref={editLayer}>
                        <EditControl
                            position="topright"
                            onCreated={_onCreate}
                            onDeleted={_onDelete}
                            edit={{ remove: {}, edit: false }}
                            draw={{
                                marker: position ? false : { repeatMode: false },
                                circle: false,
                                rectangle: false,
                                polygon: area ? false : {
                                    repeatMode: false,
                                    showArea: true,
                                    metric: true,
                                    allowIntersection: false
                                },
                                polyline: false,
                                circlemarker: false
                            }}
                        />

                        {area && <GeoJSON data={area} onEachFeature={onAreaAdded} />}
                        {position && <GeoJSON data={position} onEachFeature={area ? () => { } : onAreaAdded} />}

                    </FeatureGroup >
                </Map>
            </div>
        </IonItem >
    </>;
};

export default CompanyMapPolygon;