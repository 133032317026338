import
{
    FC,
    PropsWithChildren
} from "react";

import styles from './Pane.module.scss';

export type PaneProperties = PropsWithChildren & {
    vertical?: boolean;
};

export const Pane: FC<PaneProperties> = ({ children, vertical }) =>
{
    return <div className={`${styles.pane} ${vertical ? styles.vertical : styles.horizontal}`} >
        {children}
    </div>;
};

export type PaneSectionProperties = PropsWithChildren & {
    grow?: boolean;
};

export const PaneSection: FC<PaneSectionProperties> = ({ children, grow }) =>
{
    return <div className={`${styles.section} ${grow ? styles.grow : ''}`}>
        {children}
    </div>;
};