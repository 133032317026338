import
{
    FC
} from "react";

import TypeAhead from "../TypeAhead/TypeAheadString";

import
{
    User,
    PaginatedResult
} from "@ews/websocket-service";

import
{
    useUser,
    useTypeAheadUserList
} from "../ReactData/user";

const UserTypeAhead: FC<{
    label?: string;
    currentId?: string;
    skipUsers?: string[],
    onChange: (id: string) => void;
}> = ({
    label = "Customer",
    currentId = "",
    skipUsers = [],
    onChange
}) =>
    {

        const typeAhead = useTypeAheadUserList();
        const user = useUser(currentId);

        return <TypeAhead
            id="user-type-ahead"
            text={label}
            debounce={300}
            value={user.username}

            onFilter={async (user) => new Promise(async resolve =>
            {

                const search = await typeAhead({ pageSize: 5, name: user, email: user, searchOperator: "OR" }) as PaginatedResult<User>;

                resolve(search
                    .results
                    .filter((c: User) => !skipUsers.includes(c.id!))
                    .map((data: User) => ({ key: "username", data }))
                );
            })}
            onChange={(data) =>
            {
                const id = (data as User).id;
                if (id) onChange(id);
            }}
        />;

    };

export default UserTypeAhead;