

import { useL10n } from "@ews/react-localization-context";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { DelayTimeProps } from "./types";

const DelayTime: React.FC<DelayTimeProps> = ({ system }) =>
{

    const { translate: t } = useL10n();

    /*  const license = useSystemLicense(system.id!);
     const modifySystemLicense = useModifySystemLicense();
 
     function saveSystemLicense(key: keyof SystemLicense, value: any)
     {
         if (!system.id || !(key in license)) return;
 
 
         license[key] = value;
 
         modifySystemLicense(system.id!, { [key]: value });
     }
     const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
 
 
     useEffect(() =>
     {
         if (!systemLicenseState.systemId && license.systemId) {
             setSystemLicenseState(license);
         }
     }, [system, license]); */

    return <>
        <SettingsCol >

            <SettingsNumberInput
                text={"Exploration time after transmitting device"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Reaction time after transmitting device"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />

            <SettingsTextInput
                text={"Time fo disablement"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsCheckbox
                text={"Sprinkler system"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />
            <SettingsCheckbox
                text={"Full-time works fire brigade"}
                checked={false}
                onChange={(checked) =>
                {
                    /*  const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                     setSystemState({ ...systemState, status });
                     saveSystem("status", status); */
                }} />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default DelayTime;