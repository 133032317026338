import { PaginatedResult, PaginationRequest, SystemShare } from "@ews/websocket-service";
import { IonButton, IonContent, IonPage, IonRow } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useFetchSystemShares } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import NewShare from "./Component/NewShare/NewShare";
import { ReleasesTypes } from "./types";


const Shares: React.FC<ReleasesTypes> = () =>
{

    const { generate, params } = useNamedRoutes();
    const { id: systemId } = params();

    const mobileView = useIsMobile();


    //const [search, setSearch] = useState(0);
    //const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);
    const [currentShares, setCurrentShares] = useState<SystemShare[]>([]);
    //const [userId, setUserId] = useState<string>("");

    //const [filterDetailsOpen, setFilterDetailsOpen] = useState(true);

    const page = useRef<number | undefined>(0);
    const numberOfPages = useRef<number | undefined>(10);
    const numberOfItemsPerPage = useRef(pageRange[1]);

    const filter = useFetchSystemShares();
    const [currentState, setState] = useState<PaginationRequest>({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const [list, setList] = useState<any[] | null>(data);

    // const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    // const eventSelectValue = useRef('');

    // type MaintenanceReportFilter = {
    //     systemId?: StingFilterType;
    //     customerId?: StingFilterType;
    //     customername?: StingFilterType;
    //     userId?: StingFilterType;
    //     username?: StingFilterType;
    //     remainingTime?: StingFilterType;
    // };

    // const [currentState, setState] = useState<MaintenanceReportFilter>({});

    // const [clearFilterInputs, setClearFilterInputs] = useState(false);

    async function handleFilterResponse(filterCriteria: PaginationRequest)
    {
        const response: PaginatedResult<SystemShare> = await filter(systemId!, filterCriteria) as PaginatedResult<SystemShare>;

        const { currentPage, pageSize, results, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setCurrentShares(results.map((s: SystemShare) =>
        {
            s.link = generate("system:share-settings", { systemId: s.systemId, userId: s.userId });
            return s;
        }));
    }
    useEffect(() =>
    {
        handleFilterResponse(currentState);
    }, [currentState]);

    return <IonPage>
        <IonContent>

            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                {/* <FilterList
                isDetailsHidden={false}
                onReset={(value: true) =>
                {
                    setState({});  
                }}

                details={<>
                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, systemId: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'systemId');
                                return typeheadList;
                            }}
                            text={"System ID"}
                        />

                    </IonCol>

                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            lines="none"
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, customerId: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'customerId');
                                return typeheadList;
                            }}
                            text={"Customer ID"}
                        />
                    </IonCol>

                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, remainingTime: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'nr');
                                return typeheadList;
                            }}
                            text={"Remaining Time"}
                        />


                    </IonCol>
                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, username: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'username');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Username"}
                        />
                    </IonCol>
                </>}
            >

            </FilterList > */}

                {/* <UserTypeAhead
                label="Freigabe hinzufügen"
                onChange={user => setUserId(user)}
            /> */}
                <IonRow class="ion-justify-content-end">
                    <IonButton
                        //disabled={!userId}
                        onClick={() => setIsOpen(true)}
                    //onClick={() => createShare(systemId!, userId)}
                    >
                        Freigabe hinzufügen
                    </IonButton>
                </IonRow>



                <List
                    rows={currentShares}

                    sort={currentState.sortCriteria ? {
                        key: currentState.sortCriteria,
                        direction: currentState.sortOrder || "ASC"
                    } : undefined}

                    onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
                    onPageChange={(page) => setState({ ...currentState, page })}

                    currentPage={page.current}
                    numberOfPages={numberOfPages.current}

                    maxHeight={mobileView ? '86vh' : "59vh"}
                    headers={[
                        { title: 'System ID', key: 'systemNumber', both: true },
                        { title: 'System', key: 'systemName', both: true },
                        { title: 'Customer ID', key: 'customerNumber', both: true },
                        { title: 'Customer', key: 'customerName', both: true },
                        { title: 'User ID', key: 'username', both: true },
                        { title: 'First name', key: 'userFirstName', both: true },
                        { title: 'Last name', key: 'userLastName', both: true },
                        // { title: 'Username', key: 'username', breakpoint: 1200, up: true },
                        // { title: 'Remaining Time', key: 'remainingTime', breakpoint: 1200, up: true },
                    ]}

                />
            </div>
            <NewShare
                isOpen={isOpen}
                systemId={systemId}
                onClose={() =>
                {
                    setIsOpen(false);
                }} />
        </IonContent>
    </IonPage>;
};

export default Shares;
