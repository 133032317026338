import { IonItemSliding } from "@ionic/react";
import { useIsMobile } from "../../../../Responsive";

import { ItemContainerSliderProps } from "./types";


const ItemContainerSlider: React.FC<ItemContainerSliderProps> = ({ children }) =>
{

    const mobileView = useIsMobile();
    return mobileView ? <IonItemSliding>{children} </IonItemSliding>
        :
        <>{children} </>;
};

export default ItemContainerSlider;