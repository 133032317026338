import { useL10n } from "@ews/react-localization-context";
import { IonItem, IonTitle } from "@ionic/react";
import style from "../../Plan.module.scss";
import { GroupButtonProps } from "./types";


const GroupButton: React.FC<GroupButtonProps> = ({ color, title, onClick }) =>
{

    const { translate: t } = useL10n();

    return <IonItem
        style={{ marginBottom: "10px " }}
        className={style.pointer}
        color={color}
        onClick={
            () =>
            {
                if (onClick) onClick(title);
            }
        }
    >
        <IonTitle style={{ textAlign: "center" }}>{t(title)}</IonTitle>
    </IonItem>;

};


export default GroupButton;