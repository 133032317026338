import { useL10n } from "@ews/react-localization-context";
import { useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsTableRow from "../../../Settings/SettingsComponents/SettingsTable/SettingsRow/SettingsTableRow";
import SettingsTableColumn from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsTableGrid from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableGrid/SettingsTableGrid";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { EmailSettingsProps } from "./types";
import { useUser } from "../../../ReactData/user";

const EmailSettings: React.FC<EmailSettingsProps> = ({ id }) =>
{

    const test = Array.from({ length: 100 }, (_, i) => i + 1);

    const user = useUser(id);

    const userData = {
        fireAlarm: {
            begin: true, end: true,
        },
        preAlarm: {
            begin: true, end: true,
        },
        firePreWarning: {
            begin: true, end: true,
        },
        fault: {
            begin: true, end: true,
        },
        technicalMessage: {
            begin: true, end: true,
        },
        disablement: {
            begin: true, end: true,
        },
        extinguishingEvent: {
            begin: true, end: true,
        },
        online: {
            begin: true, end: true,
        },
        activeOutput: {
            begin: true, end: true,
        },
        testAlarm: {
            begin: true,
            end: true,
        },
        newClient: false,
        newUser: false,
        newReseller: false,
        newSystem: false,
        newEnablement: false,
        newGpsArea: false,
        ...user
    };
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();



    const EmailDataRef = useRef(userData);

    const { fireAlarm, preAlarm, firePreWarning, testAlarm, online, fault, technicalMessage, extinguishingEvent, disablement, activeOutput, } = EmailDataRef.current;


    return <>
        <SettingsCol size={mobileView ? "12" : undefined} >
            <SettingsTableGrid>
                <SettingsTableRow hidden={mobileView}>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_HK_EVENTS]')}:`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <p style={{ textAlign: "center", margin: 0 }}>{`${t('begin')}:`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <p style={{ textAlign: "center", margin: 0 }}>{`${t('end')}:`}</p>
                    </SettingsTableColumn>
                </SettingsTableRow>



                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_HK_FIRE_ALARMS]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={fireAlarm.begin} onChange={(checked) => { fireAlarm.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={fireAlarm.end} onChange={(checked) => { fireAlarm.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_DEV_STATE_PREALARM]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={preAlarm.begin} onChange={(checked) => { preAlarm.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={preAlarm.end} onChange={(checked) => { preAlarm.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>


                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_EV_FIRE_PREALARM_SH]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={firePreWarning.begin} onChange={(checked) => { firePreWarning.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={firePreWarning.end} onChange={(checked) => { firePreWarning.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>


                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_EV_FIRE_TEST_ALARM_SH]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={testAlarm.begin} onChange={(checked) => { testAlarm.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={testAlarm.end} onChange={(checked) => { testAlarm.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_ACT_OUTP]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={activeOutput.begin} onChange={(checked) => { activeOutput.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={activeOutput.end} onChange={(checked) => { activeOutput.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_FAULTS]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={fault.begin} onChange={(checked) => { fault.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={fault.end} onChange={(checked) => { fault.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_ALARM]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={technicalMessage.begin} onChange={(checked) => { technicalMessage.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={technicalMessage.end} onChange={(checked) => { technicalMessage.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_SW_OFF_ZONE_SH]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={disablement.begin} onChange={(checked) => { disablement.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={disablement.end} onChange={(checked) => { disablement.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_EXT_EV]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={extinguishingEvent.begin} onChange={(checked) => { extinguishingEvent.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={extinguishingEvent.end} onChange={(checked) => { extinguishingEvent.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_STATUS]')}`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={online.begin} onChange={(checked) => { online.begin = checked; }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={online.end} onChange={(checked) => { online.end = checked; }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                {test.map(i => (
                    <SettingsTableRow key={i}>
                        <SettingsTableColumn size="4" center={false}>
                            <p style={{ margin: 0 }}>{`${'Test ' + i}`}</p>
                        </SettingsTableColumn>
                        <SettingsTableColumn >
                            <SettingsCheckbox text={"begin"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={online.begin} onChange={(checked) => { online.begin = checked; }} />
                        </SettingsTableColumn>
                        <SettingsTableColumn >
                            <SettingsCheckbox text={"end"} lines={mobileView ? "full" : undefined} item={mobileView} container={mobileView} label={mobileView} checked={online.end} onChange={(checked) => { online.end = checked; }} />
                        </SettingsTableColumn>
                    </SettingsTableRow>
                ))}

            </SettingsTableGrid>

        </SettingsCol>
        <SettingsCol>
            <SettingsTitle text={"E-mail notifications at"} endText={':'} />
            <SettingsCheckbox text={"New client"} checked={EmailDataRef.current.newClient} onChange={(checked) => { EmailDataRef.current.newClient = checked; }} />
            <SettingsCheckbox text={"New resseler"} checked={EmailDataRef.current.newReseller} onChange={(checked) => { EmailDataRef.current.newReseller = checked; }} />
            <SettingsCheckbox text={"New user"} checked={EmailDataRef.current.newUser} onChange={(checked) => { EmailDataRef.current.newUser = checked; }} />
            <SettingsCheckbox text={"New system"} checked={EmailDataRef.current.newSystem} onChange={(checked) => { EmailDataRef.current.newSystem = checked; }} />
            <SettingsCheckbox text={"New enablement"} checked={EmailDataRef.current.newEnablement} onChange={(checked) => { EmailDataRef.current.newEnablement = checked; }} />
            <SettingsCheckbox text={"New GPS area"} checked={EmailDataRef.current.newGpsArea} onChange={(checked) => { EmailDataRef.current.newGpsArea = checked; }} />

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default EmailSettings;