

import { useL10n } from "@ews/react-localization-context";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { DatesProps } from "./types";

const Dates: React.FC<DatesProps> = ({ system }) =>
{

    const { translate: t } = useL10n();

    /*  const license = useSystemLicense(system.id!);
     const modifySystemLicense = useModifySystemLicense();
 
     function saveSystemLicense(key: keyof SystemLicense, value: any)
     {
         if (!system.id || !(key in license)) return;
 
 
         license[key] = value;
 
         modifySystemLicense(system.id!, { [key]: value });
     }
     const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({});
 
 
     useEffect(() =>
     {
         if (!systemLicenseState.systemId && license.systemId) {
             setSystemLicenseState(license);
         }
     }, [system, license]); */

    return <>
        <SettingsCol >

            <SettingsNumberInput
                text={"Date of first decison"}
                tooltip={<TooltipIcon text={t("")} />}
                inputType="date"
                value={new Date().getTime()}
                onBlur={({ value }) =>
                {
                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Start of planning approx.:"}
                tooltip={<TooltipIcon text={t("")} />}
                inputType="date"

                value={new Date().getTime()}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Start of installation approx.:"}
                tooltip={<TooltipIcon text={t("")} />}
                inputType="date"
                value={new Date().getTime()}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Commisioning approx.:"}
                tooltip={<TooltipIcon text={t("")} />}
                inputType="date"
                value={new Date().getTime()}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default Dates;