import { IonContent, IonPage } from "@ionic/react";
import Header from "../Header/System";
import Navigation from "../Navigation";
import { SystemLayoutProps } from "./types";

const Layout: React.FC<SystemLayoutProps> = ({ header = true, children, system, title, sidebar, statusbar, navbar, backbutton, id = '' }) =>
{
    return <>
        {header ? <Header backbutton={backbutton} title={title || ''} /> : false}
        <Navigation sidebar={sidebar} statusbar={statusbar} navbar={navbar} id={id}>
            <IonPage>
                <IonContent fullscreen>
                    {children}
                </IonContent>
            </IonPage>
        </Navigation>
    </>;
};

export default Layout;