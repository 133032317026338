
import listStyle from "../GruppList.module.scss";
import
{
    ColProps
} from "../types";

export const Col: React.FC<ColProps> = ({
    children,
    className
}) =>
{

    return (
        <div
            className={`${listStyle.col}  ${className}`}>
            {children}
        </div >
    );
};