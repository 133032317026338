import React, { FC, useState } from 'react';

import Layout from '../components/Layout/System';

import NavbarMain from '../components/Navigation/Navbar/Main';
import SidebarMain from '../components/Navigation/Sidebar/Main';
import StatusbarMain from '../components/Navigation/Status/Main';

import NavbarSystem from '../components/Navigation/Navbar/System';
import SidebarSystem from '../components/Navigation/Sidebar/System';
import StatusbarSystem from '../components/Navigation/Status/System';

import NavbarReports from "../components/Navigation/Navbar/Reports";
import NavbarSettings from "../components/Navigation/Navbar/Settings";

import SystemList from '../components/System/SystemList';

//import { systems } from '../data/systems';
import { addCircleSharp, list, map } from 'ionicons/icons';
import ButtonContainer from '../components/ButtonContainer/ButtonContainer';
import EventOverview from '../components/Event/EventOverview/EventOverview';

import GroupOverview from '../components/Group/GroupOverview/GroupOverview';
import { useNamedRoutes } from '../components/NamedRoutes';
import Plan from '../components/Plan/Plan';
import AnalogueValues from '../components/System/Reports/AnalogueValues/AnalogueValues';
import EventMemory from '../components/System/Reports/EventMemory/EventMemory';
import MaintenanceReport from '../components/System/Reports/MaintenanceReport/MaintenanceReport';
import PhoneNumbers from '../components/System/Reports/Phonenumbers/Phonenumbers';
import Queries from '../components/System/Reports/Queries/Queries';
import Shares from '../components/System/Reports/Shares/Shares';
import Details from '../components/System/SystemDetails';
import SettingsOverlay from '../components/System/SystemSettings/SettingsOverlay/SettingsOverlay';
import ReleaseSettingsOverlay from '../components/System/Reports/Shares/Component/Settings/SettingsOverlay';

import
{
    useSystem,
    useSystemDetails,
    useSystemRTM,
    useSystemSetup
} from '../components/ReactData/system';

//import { add } from "ionicons/icons";
import NewSystemModal from '../components/System/NewSystem/NewSystemModal';
//import PermissionModal from '../components/System/Permission/PermissionModal';
import { useL10n } from '@ews/react-localization-context';
import { MenuLink } from '../components/Navigation/Sidebar/Sidebar';
import { MenuToggleProps } from '../components/Navigation/types';
import DocumentationsSettingsOverlay from '../components/System/DocumentationsSettings/DocumentationsSettings/DocumentationsSettingsOverlay';
import DisplayDevice from '../components/System/Reports/DisplayDevice/DisplayDevice';
import InputsSystemDocumentation from '../components/System/Reports/InputsSystemDocumentation/InputsSystemDocumentation';
import OtherAlarmDevices from '../components/System/Reports/OtherAlarmDevices/OtherAlarmDevices';
import OtherDevices from '../components/System/Reports/OtherDevices/OtherDevices';
import PowerUnitsDocumentation from '../components/System/Reports/PowerUnitsDocumentation/PowerUnitsDocumentation';
import DocumentationHistory from '../components/System/Reports/SystemDocumentationHistory/SystemDocumentationHistory';
import TypeDefinitionSubmissionDocuments from '../components/System/Reports/TypeDefinitionSubmissionDocuments/TypeDefinitionSubmissionDocuments';
import { ReactClickEvent } from '../components/types';

import
{
    Pane,
    PaneSection
} from '../components/Layout/Pane';

import { useMyPermissions } from '../components/Login/LoginProvider';
import { UserPermissions, permissionAllowed } from "@ews/websocket-service";
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';

const SidebarWrapper: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
    const { translate: t } = useL10n();
    const permissions: UserPermissions = useMyPermissions();

    return (<SidebarMain onMenuToggle={onMenuToggle}>
        {permissionAllowed(permissions.grantSystemAccess) ?
            <>

                <MenuLink
                    title={t('Add system')}
                    id="newSystem"
                    icon={addCircleSharp}
                />
                <NewSystemModal
                    trigger='newSystem'
                    onDismiss={(e) => onMenuToggle && onMenuToggle(e as unknown as ReactClickEvent)}
                />
            </> : <></>}
    </SidebarMain>);
};



const System: React.FC<{ filterByCustomer?: boolean; }> = ({ filterByCustomer }) =>
{

    const { params } = useNamedRoutes();
    const { id: customerId } = params();
    //const [openAddSystemSettings, setOpenAddSystemSettings] = useState<boolean>(false);

    const [view, setView] = useState<'list' | 'map'>('list');
    const toggle = () =>
    {
        setView(view === 'list' ? 'map' : 'list');
    };

    //const Component = view === 'list' ? SystemList : SystemMap;
    const Component = SystemList;
    const Map = () => <>hier kommt eine Map</>;
    //const icon = view === 'list' ? map : reorderThree;

    /*
    hier gibt es zwei möglichkeiten

    1) SystemList und SystemMap einen onFilter function erstellen 
    2) in jeder Componente direkt die useMySystems() implementieren

    */
    const Bar = () =>
    {
        return <>
            <IonFab horizontal='start' style={{ marginTop: "1px", peddingTop: "1px" }}>
                <IonFabButton size='small'>
                    <IonIcon
                        size='small'
                        icon={view === "list" ? map : list}
                        onClick={toggle} />
                </IonFabButton>
            </IonFab>
            <StatusbarMain></StatusbarMain>
        </>;
    };

    return (
        <>
            <Layout sidebar={SidebarWrapper} statusbar={Bar} navbar={NavbarMain} id="system" header={false}>
                {view === "list" ?
                    <Component customerId={filterByCustomer ? customerId : undefined} />
                    :
                    <Map></Map>}
            </Layout>
        </>
    );
};

const SystemEvent: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);
    const rtm = useSystemRTM(id!);

    return (
        <>
            <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:events">
                <Pane vertical>
                    <PaneSection grow>
                        <EventOverview
                            rtm={rtm}
                        />
                    </PaneSection>
                    <PaneSection>
                        <ButtonContainer
                            systemId={system.id || ""}
                            rtm={rtm} />
                    </PaneSection>
                </Pane>
            </Layout >
        </>
    );
};

const SystemDetails: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);
    const details = useSystemDetails(id!);
    const rtm = useSystemRTM(id!);

    return (
        <>
            <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:system">
                <Pane vertical>
                    <PaneSection grow>
                    </PaneSection>
                    <PaneSection>
                        <ButtonContainer
                            systemId={system.id || ""}
                            rtm={rtm} />
                    </PaneSection>
                </Pane>
                <Details system={system!} details={details!} />
            </Layout>
        </>
    );
};

const SystemGroup: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    //const id = "ee17afb2-f438-4336-86f3-dc28c40c54c3";
    const system = useSystem(id!);
    const setup = useSystemSetup(id!);
    const rtm = useSystemRTM(id!);

    return (
        <>
            <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:elements">
                <Pane vertical>
                    <PaneSection grow>
                        <GroupOverview groups={setup.groups || []} />
                    </PaneSection>
                    <PaneSection>
                        <ButtonContainer
                            systemId={system.id || ""}
                            rtm={rtm} />
                    </PaneSection>
                </Pane>
            </Layout >
        </>
    );
};

const SystemPlan: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    const rtm = useSystemRTM(id!);

    const system = useSystem(id!);

    return (
        <>
            <Layout title={system?.name} statusbar={StatusbarSystem} sidebar={SidebarSystem} navbar={NavbarSystem} id="system:plan">
                <Pane vertical>
                    <PaneSection grow>
                        <Plan system={system!} />
                    </PaneSection>
                    <PaneSection>
                        <ButtonContainer
                            systemId={system.id || ""}
                            rtm={rtm} />
                    </PaneSection>
                </Pane>
            </Layout>
        </>
    );
};

const SystemReleases: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:shares">
                <Shares system={system!} />
            </Layout>
        </>
    );
};

const SystemReleasesSettings: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id, userId } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:share-settings">
                <ReleaseSettingsOverlay systemId={id} userId={userId} />
            </Layout>
        </>
    );
};


const SystemEventMemory: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:eventmemory">
                <EventMemory system={system!} />
            </Layout>
        </>
    );
};

const SystemAnalogueValues: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:analoguevalues">
                <AnalogueValues system={system!} />
            </Layout>
        </>
    );
};

const SystemMaintenanceReport: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:maintenancereport">
                <MaintenanceReport system={system!} />
            </Layout>
        </>
    );
};

const SystemQueries: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:queries">
                <Queries system={system!} />
            </Layout>
        </>
    );
};


const SystemPhoneNumber: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:phonenumber">
                <PhoneNumbers system={system!} />
            </Layout>
        </>
    );
};

const SystemDocumentationHistory: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:Documentation-history">
                <DocumentationHistory system={system!} />
            </Layout>
        </>
    );
};

const SystemDisplayDevice: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:displaydevice">
                <DisplayDevice system={system!} />
            </Layout>
        </>
    );
};
const SystemPowerUnitsDocumentation: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:powerunitsdocumentation">
                <PowerUnitsDocumentation system={system!} />
            </Layout>
        </>
    );
};
const SystemOtherAlarmDevices: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:otheralarmdevices">
                <OtherAlarmDevices system={system!} />
            </Layout>
        </>
    );
};

const SystemOtherDevices: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:otherdevices">
                <OtherDevices system={system!} />
            </Layout>
        </>
    );
};
const SystemInputsSystemDocumentation: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:inputssystemDocumentation">
                <InputsSystemDocumentation system={system!} />
            </Layout>
        </>
    );
};

const SystemDocumentationsSettingsOverlay: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:Documentationhistory">
                <DocumentationsSettingsOverlay system={system!} />
            </Layout>
        </>
    );
};

const SystemTypeDefinitionSubmissionDocuments: React.FC = () =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const system = useSystem(id!);

    return (
        <>
            <Layout backbutton={true} title={system?.name} statusbar={StatusbarMain} sidebar={SidebarSystem} navbar={NavbarReports} id="system:typedefinitionsubmissiondocuments">
                <TypeDefinitionSubmissionDocuments system={system!} />
            </Layout>
        </>
    );
};




const SystemSettings: React.FC = () =>
{

    const { params } = useNamedRoutes();
    const { id } = params();
    //const id = "ee17afb2-f438-4336-86f3-dc28c40c54c3";
    //console.log('params id SystemSettings: ', id);
    const system = useSystem(id!);

    return system?.id ? <Layout backbutton={true} title={system?.name} sidebar={SidebarSystem} navbar={NavbarSettings} id="system:settings">

        <SettingsOverlay system={system} />
    </Layout> : <>loading data</>;
};

export
{
    System,
    SystemAnalogueValues,
    SystemDetails, SystemDisplayDevice, SystemDocumentationHistory, SystemDocumentationsSettingsOverlay, SystemEvent,
    SystemEventMemory,
    SystemGroup, SystemInputsSystemDocumentation, SystemMaintenanceReport, SystemOtherAlarmDevices,
    SystemOtherDevices, SystemPhoneNumber as SystemPhonenumber,
    SystemPlan, SystemPowerUnitsDocumentation as SystemPowerUnitsDocumentation, SystemQueries,
    SystemReleases,
    SystemSettings,
    SystemTypeDefinitionSubmissionDocuments,
    SystemReleasesSettings
};

