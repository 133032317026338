
import { useL10n } from "@ews/react-localization-context";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { TransmissionZonesProps } from "./types";

const TransmissionZones: React.FC<TransmissionZonesProps> = ({ system }) =>
{

    const { translate: t } = useL10n();

    /*   const permission = useSystemPermissions(system.id!);
      const modifySystemPermissions = useModifySystemPermissions();
  
      function saveSystemPermission(key: keyof SystemPermissions, value: any)
      {
          if (!system.id || !(key in permission)) return;
  
          permission[key] = value;
  
          modifySystemPermissions(system.id!, { [key]: value });
      }
      const [systemPermissionState, setSystemPermissionState] = useState<SystemPermissions>({});
  
  
      useEffect(() =>
      {
  
          if (!systemPermissionState.systemId && permission.systemId) {
              setSystemPermissionState(permission);
          }
      }, [system, permission]); */

    return <>
        <SettingsCol >
            <SettingsNumberInput
                text={"Area monitored by fire detection system [m2]"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
        </SettingsCol>

        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default TransmissionZones;