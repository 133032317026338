import { IonFab, IonFabButton, IonIcon } from "@ionic/react";

import { SystemPlanPageAddBtnProps } from "./types";

const SystemPlanPageAddBtn: React.FC<SystemPlanPageAddBtnProps> = ({ onClick, icon, color }) =>
{
    return <IonFab horizontal="start" vertical="bottom" style={{ marginLeft: "90px" }}>
        <IonFabButton id="newPlanPage" color={color} onClick={() =>
        {
            if (onClick) onClick();
        }}>
            <IonIcon icon={icon} />
        </IonFabButton>
    </IonFab>;
};
export default SystemPlanPageAddBtn;