import { CustomerEventsListType } from "./types";
import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";
import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useIsMobile } from "../../../Responsive";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";

const CustomerEventsList: React.FC<CustomerEventsListType> = () =>
{

    const mobileView = useIsMobile();


    const data = [
        {
            time: new Date().toDateString(),
            customerId: '46483248',
            userId: '48788519412492549',
            systemId: "f53a0ae2-9b00-4a9a-9ba8-2b3bb40b4611",
            event: 'delete customer id:49419418948',
            modifiedAtId: new Date().toDateString(),
        },
        {
            time: new Date().toDateString(),
            customerId: '464981961515',
            userId: '452346525626',
            systemId: "6314f41a-c6c3-47bb-a15f-493f3e0cc921",
            event: 'change settings',
            modifiedAtId: new Date().toDateString(),
        }

    ];


    const [list, setList] = useState<any[] | null>(data);

    type Filter = {
        time?: StingFilterType,
        customerId?: StingFilterType,
        userId?: StingFilterType,
        systemId?: StingFilterType,
        event?: StingFilterType,
        modifiedAtId?: StingFilterType,
    };

    const [currentState, setState] = useState<Filter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);
    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);
    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="modifiedAtId"
                        inputType="date"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, modifiedAtId: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'modifiedAtId');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"Modified at"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        lines="none"
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, event: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'event');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"Event"}
                    />
                </IonCol>
                <IonCol>

                    <TypeaheadString
                        reset={clearFilterInputs}
                        lines="none"
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, systemId: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'systemId');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"system ID"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        lines="none"
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, userId: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'userId');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"User ID"}
                    />
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >


            <IonCol >
                <TypeaheadString
                    reset={clearFilterInputs}
                    onFilter={async (value: string) =>
                    {
                        setState({ ...currentState, time: (a: string, b: string): boolean => stringFilter(a, value) });
                        const list = await getList(data, 'time');
                        return list;
                    }}
                    disabled={!data.length}
                    text={"Time"}
                />
            </IonCol>
            <IonCol>
                <TypeaheadString
                    reset={clearFilterInputs}
                    onFilter={async (value: string) =>
                    {
                        setState({ ...currentState, customerId: (a: string, b: string): boolean => stringFilter(a, value) });
                        const list = await getList(data, 'customerId');
                        return list;
                    }}
                    disabled={!data.length}
                    text={"Customer ID"}
                />
            </IonCol>
            <IonCol>
                <SettingsSelect
                    value={currentRowLimit.toString()}
                    text={"Items per Page"}
                    onSelect={(value) => setCurrentRowLimit(Number(value))}
                >
                    {pageRange.map(range =>
                    {
                        const value = range.toString();
                        return <SettingsSelectOption key={range} text={range === Infinity ? "all" : value} value={value} />;
                    })}
                </SettingsSelect>

            </IonCol>
            <IonCol></IonCol>
            <IonCol></IonCol>
            <IonCol></IonCol>
        </FilterList >
        <List
            rows={list}
            //maxHeight={mobileView ? undefined : filterDetailsOpen ? '45vh' : "61vh"}
            noLink={true}
            headers={[
                { title: 'Time', key: 'time', both: true },
                { title: 'Customer ID', key: 'customerId', both: true, option: { link: "customer" } },
                { title: 'User ID', key: 'userId', breakpoint: 600, up: true, option: { link: "user" } },
                { title: 'System ID', key: 'systemId', breakpoint: 600, up: true, option: { link: "system" } },
                { title: 'Event', key: 'event', breakpoint: 600, up: true },
                { title: 'Modified at ID', key: 'modifiedAtId', breakpoint: 1200, up: true },
            ]}

        />
    </>;
};
export default CustomerEventsList;

