import { IonButton, IonContent, IonItem, IonModal, IonTitle, useIonRouter } from '@ionic/react';
import { useState } from 'react';
import { useNamedRoutes } from '../../../../../NamedRoutes';
import { useCreateSystemShare, useSystem } from '../../../../../ReactData/system';
import SettingsLabel from '../../../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel';
import UserTypeAhead from '../../../../../User/UserTypeAhead';
import { NewShareProps } from './types';

const NewShare: React.FC<NewShareProps> = ({ isOpen, onClose, systemId }) =>
{

    /* 
    
    für Anlagen-ID *        LABEL
    Benutzer-ID *           INPUT
    E-Mail *                INPUT
    
    Dauer der Freigabe *    INPUT "Stunden"                 
    Unbegrenzte Freigabe    CHECKBOX
    Alle Benutzer           CHECKBOX
    Informieren vor Ablauf  CHECKBOX
    
    */

    const { generate } = useNamedRoutes();
    const router = useIonRouter();
    const createShare = useCreateSystemShare();
    const [userId, setUserId] = useState("");
    const system = useSystem(systemId || "");

    const createShareAndRedirect = async () =>
    {
        const share = await createShare(systemId!, userId!);
        const link = generate('system:share-settings', { id: share.systemId, userId: share.userId });
        router.push(link);
    };

    return <IonModal
        isOpen={isOpen}
        onDidDismiss={() => onClose()}
    >
        <IonItem color={"primary"} lines='none'>
            <IonTitle>Freigabe hinzufügen</IonTitle>
        </IonItem>
        <IonContent>
            <SettingsLabel text={"Für Anlage"} value={system.name} />
            <UserTypeAhead
                label="Freigabe hinzufügen"
                onChange={user => setUserId(user)}
            />
            <IonButton
                disabled={!userId}
                color={"success"}
                onClick={() => createShareAndRedirect()}
            >Freigabe erstellen</IonButton>
        </IonContent>
    </IonModal>;
};
export default NewShare;
