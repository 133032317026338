export function isValidNumber(start: string | number, end: string | number)
{
    const a = start ? typeof start === "string" ? Number(start) : start : 0;
    const b = end ? typeof end === "string" ? Number(end) : end : Infinity;
    return a < b;
}

export function isValidDate(from: Date, to: Date)
{
    const result = (from.getFullYear() > to.getFullYear()) //2025 - 2024
        ||
        (from.getMonth() > to.getMonth() && from.getFullYear() === to.getFullYear()) // 5 > 2 && 2024 === 2024 
        ||
        (from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear() && from.getDate() > to.getDate()); // 5 === 5 && 2024 === 2024 && 21 > 15
    return !result;
}