import planStyle from "../../../Plan.module.scss";
import dropStyle from "../DropZone.module.scss";
import { ImageContainerProps } from "./types";


const ImageContainer: React.FC<ImageContainerProps> = ({ children, containerRef, imgRef, onDragOver, onClick, onDragLeave, onHover, containerSize, zoom }) =>
{




    return <section
        className={dropStyle.section}
    >

        <div
            className={dropStyle.imgContainer}
            ref={containerRef}
            style={{ width: `${containerSize - zoom}%`, margin: `auto ${zoom >= 0 ? `${zoom}%` : 0}` }}>
            <img
                ref={imgRef}
                className={planStyle.img}
                src="assets/img/plan.png"
                alt="plan"
                draggable={false}
                onPointerMove={(e) => { if (onHover) onHover(e); }}
                /* onDragOver={(e) =>
                {
                    console.log('e: ', e);
                    if (onDragOver) onDragOver(e);
                }} */
                onClick={(e) => { if (onClick) onClick(e); }}
            //onDragLeave={(e) => { if (onDragLeave) onDragLeave(e); }}
            />
            {children}
        </div>

    </section>;


};

export default ImageContainer;