import React from "react";
import { IonContent, IonImg, IonPage } from "@ionic/react";

const Home: React.FC = () =>
{
    return (

        <IonPage>
            <IonContent fullscreen class="ion-padding">
                <IonImg style={{ height: "700px" }} src="assets/img/react-schema.png" ></IonImg>
            </IonContent>
        </IonPage>
    );

};

export default Home;