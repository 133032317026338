import { useL10n } from "@ews/react-localization-context";
import { IonItem, IonLabel, IonList, } from "@ionic/react";


import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";

import { RemarksProps } from "./types";

const Remarks: React.FC<RemarksProps> = ({ system }) =>
{
    const { translate: t } = useL10n();
    /*    const mobileView = useIsMobile();
   
       const modifySystemNotifications = useModifySystemNotifications();
   
       const notification = useSystemNotifications(system.id!);
   
       const [sendEmailFireAlarmPDF, setSendEmailFireAlarmPDF] = useState<boolean>(true);
       const receiver1Ref = useRef<HTMLIonInputElement>(null);
       const receiver2Ref = useRef<HTMLIonInputElement>(null);
       const receiver3Ref = useRef<HTMLIonInputElement>(null);
   
       function saveSystemNotifications(key: keyof SystemNotifications, value: any)
       {
           if (!system.id) return;
           modifySystemNotifications(system.id!, { [key]: value });
       }
   
       function CheckedDevice(key: keyof SystemNotifications, device: keyof typeof NotificationChannel, checked: boolean)
       {
   
           if (!(key in notification)) return;
   
           const { EMAIL, PUSH } = NotificationChannel;
   
           if (NotificationChannel[device] === EMAIL) {
               (notification as Record<string, number>)[key] = checked ? (notification as Record<string, number>)[key] |= EMAIL : (notification as Record<string, number>)[key] &= PUSH;
           }
           if (NotificationChannel[device] === PUSH) {
               (notification as Record<string, number>)[key] = checked ? (notification as Record<string, number>)[key] |= PUSH : (notification as Record<string, number>)[key] &= EMAIL;
           }
           saveSystemNotifications(key, notification[key]);
       } */

    return <>
        <SettingsCol >
            <IonList>
                <IonItem lines="none">
                    <IonLabel slot="start">{t("Remark")}</IonLabel>

                </IonItem>
                <IonItem lines="full">
                    <textarea slot="end" rows={20} cols={50} style={{ border: "1px solid gray", minWidth: "100px", marginBottom: "10px" }} onBlur={(e) => console.log(e)}></textarea>
                </IonItem>
            </IonList>
        </SettingsCol>

        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default Remarks;