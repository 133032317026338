import React, {
    FC
} from "react";

import
{
    IonItem,
    IonLabel
} from '@ionic/react';

import
{
    NoDeviceNumber
} from '@ews/zlt-events';

import SettingsNumberInput from '../../../../../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput';

type OnChangeHandler = (from: number, to: number) => void;

const NumberRange: FC<{ label: string, from?: number, to?: number; onChange?: OnChangeHandler; }> = ({
    label,
    from = 0,
    to = NoDeviceNumber,
    onChange
}) =>
{
    return <IonItem>

        <div style={{ width: "100%" }}>
            <IonLabel class="">{label}</IonLabel>
        </div>

        <SettingsNumberInput
            lines="none"
            text={"von.:"}
            value={from}
            onChange={(value) => onChange && onChange(value, to)}
        />

        <SettingsNumberInput
            lines="none"
            text={"bis.:"}
            value={to}
            onChange={(value) => onChange && onChange(from, value)}
        />
    </IonItem>;
};

export default NumberRange;
