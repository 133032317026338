import { IonCard, IonCardHeader, IonIcon, IonItem, IonRange, IonTitle } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { useIsMobile } from "../../../../Responsive";
import dropStyle from "../DropZone.module.scss";
import { DropZoneCardHeaderProps } from "./types";
import { useL10n } from "@ews/react-localization-context";

const DropZoneCardHeader: React.FC<DropZoneCardHeaderProps> = ({ zoom, onZoom, info }) =>
{
    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    return <IonCardHeader
        color={'dark'}
        className={dropStyle.header}
    >
        <div
            className={dropStyle.zoom}
        >


            {mobileView ?

                <div>
                    <IonItem
                        color={'dark'}
                    >
                        <IonIcon slot="start" color="danger" icon={remove} onClick={() => onZoom(zoom - 1)} />
                        <div
                            className={dropStyle.center}
                        >
                            <IonTitle >Zoom {zoom}%</IonTitle>
                        </div>
                        <IonIcon slot="end" color="success" icon={add} onClick={() => onZoom(zoom + 1)} />
                    </IonItem>
                </div> : <>
                    <div
                        className={dropStyle.center}
                    >
                        <IonTitle >Zoom {zoom}%</IonTitle>
                    </div>
                    <div>
                        <IonRange
                            value={zoom}
                            snaps={true}
                            min={0}
                            max={50}
                            onIonChange={({ detail }) =>
                            {

                                onZoom(detail.value as number);
                            }}
                        >
                            <IonIcon slot="start" color="danger" icon={remove} onClick={() => onZoom(zoom - 1)} />
                            <IonIcon slot="end" color="success" icon={add} onClick={() => onZoom(zoom + 1)} />
                        </IonRange>
                    </div></>}
        </div>
        {info.length && !mobileView ? <IonCard color={'danger'} className={dropStyle.outOfRange}><h1 >{t(info)}</h1></IonCard> : null}
    </IonCardHeader>;
};

export default DropZoneCardHeader;