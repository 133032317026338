import { IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useIsMobile } from "../../Responsive";
import { SettingsGridProps } from "./types";

/* 
    height: 100vh;
    overflow: auto;
    padding-bottom: 20%; */

const SettingsGrid: React.FC<SettingsGridProps> = ({ children, height }) =>
{
    const mobileView = useIsMobile();

    return <IonGrid style={{ minHeight: height?.min ? height.min : '10vh', maxHeight: height?.max ? height?.max : "fit-content", overflow: 'auto' }} > {/*, paddingBottom: '20%' */}
        <IonRow>
            {children}
        </IonRow>
    </IonGrid>;
};

export default SettingsGrid;