import { useL10n } from "@ews/react-localization-context";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsRangeInput.module.scss';
import { SettingsRangeInputProps } from "./types";

const SettingsRangeInput: React.FC<SettingsRangeInputProps> = ({
    onChange,
    value,
    hidden = false,
    labelPosition = 'stacked',
    text = '',
    disabled,
    reset = false,
    required,
    lines = undefined,
    inline = false,
    debounce,
    type = 'number'
}) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const inputStyle = mobileView ? 'mobileInput' : 'webInput';

    const from = useRef<HTMLIonInputElement>(null);
    const to = useRef<HTMLIonInputElement>(null);

    useEffect(() =>
    {
        from.current!.value = value?.from;
        to.current!.value = value?.to;
    }, [reset]);

    return <IonItem
        hidden={hidden}
        lines={lines}
        className={`${style.overflow} ${globalStyle.setting}`}>
        <div className={style.container} style={{ width: "100%" }}>
            <div className={style.containerInput}>

                <IonLabel
                    style={{ whiteSpace: "break-spaces" }}
                    position={labelPosition}>{t(text)}{required ? <span className={`${globalStyle.required} ${style.label}`}>*</span> : null}
                </IonLabel>

                <div className={style.range} style={inline ? {
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                } : {}}>
                    <IonInput
                        label=""
                        ref={from}
                        required={required}
                        disabled={disabled}
                        value={value?.from || from.current?.value}
                        placeholder={t('from')}
                        type={type}
                        className={`${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                        onIonChange={(e) =>
                        {
                            const fromValue = e.detail.value || 0;
                            let toValue = to.current?.value || Infinity;

                            if (fromValue > toValue) {
                                toValue = Number(fromValue) + 1;
                                to.current!.value = toValue.toString();
                            };
                            onChange({ from: Number(fromValue), to: Number(toValue) });
                        }} />
                    <p>{' - '}</p>
                    <IonInput
                        label=""
                        ref={to}
                        required={required}
                        disabled={disabled}
                        value={value?.to || to.current?.value}
                        placeholder={t('to')}
                        type={type}
                        className={`${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                        onIonChange={(e) =>
                        {
                            const toValue = e.detail.value || Infinity;
                            let fromValue = from.current?.value || 0;

                            if (toValue < fromValue) {
                                fromValue = Number(toValue) - 1;
                                from.current!.value = fromValue.toString();
                            };
                            onChange({ from: Number(fromValue), to: Number(toValue) });
                        }} />
                </div>
            </div>
        </div>
    </IonItem>;
};

export default SettingsRangeInput;