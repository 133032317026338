import { useRef } from "react";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { InvoiceSettingsProps } from "./types";

const InvoiceSettings: React.FC<InvoiceSettingsProps> = ({ customer, }) =>
{
    const customerData = {
        orderNumber: 0,
        term: '10',

        ...customer
    };

    const InvoiceDataRef = useRef(customerData);

    return <>
        <SettingsCol>

            <SettingsNumberInput text={"Order number"} value={InvoiceDataRef.current.orderNumber} onChange={(value) => InvoiceDataRef.current.orderNumber = value} />

            <SettingsSelect text={"Payment term"} value={InvoiceDataRef.current.term} onSelect={(value) => InvoiceDataRef.current.term = value}>
                <SettingsSelectOption text={"10"} value={"10"} />
                <SettingsSelectOption text={"11"} value={"11"} />
                <SettingsSelectOption text={"1"} value={"1"} />
                <SettingsSelectOption text={"2"} value={"2"} />
                <SettingsSelectOption text={"3"} value={"3"} />
                <SettingsSelectOption text={"5"} value={"5"} />
            </SettingsSelect>

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default InvoiceSettings;