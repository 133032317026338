import { IonCol } from "@ionic/react";
import { useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { NumberFilterType, StingFilterType } from "../../../FilterList/types";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";
import { OtherAlarmDevicesProps } from "./types";

const OtherAlarmDevices: React.FC<OtherAlarmDevicesProps> = ({ system }) =>
{


    const { generate } = useNamedRoutes();
    const mobileView = useIsMobile();


    const data = [
        {
            link: generate('systems'),
            output: "",
            quantity: 5,
            deviceCategory: system.name,
            type: "",
        },

    ];


    const [list, setList] = useState<any[] | null>(data);

    type Filter = {
        output?: StingFilterType,
        quantity?: NumberFilterType,
        deviceCategory?: StingFilterType,
        type?: StingFilterType,

    };

    const [currentState, setState] = useState<Filter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);
    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);

    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);


    return <>
        <FilterList
            onReset={(value: true) =>
            {
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}
            isDetailsHidden={false}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="output"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            setState({ ...currentState, output: (a: string, b: string): boolean => stringFilter(a, value) });
                            const list = await getList(data, 'output');
                            return list;
                        }}
                        disabled={!data.length}
                        text={"Output"}
                    />

                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >
        </FilterList >



        <List
            rows={list}
            maxHeight={mobileView ? '86vh' : "58vh"}
            headers={[
                {
                    title: 'TD_ELEM_AUSGANG', key: 'output', both: true
                },
                {
                    title: 'Quantity', key: 'quantity', both: true
                },
                {
                    title: 'Device category', key: 'deviceCategory', both: true
                },
                {
                    title: '[SID_KIND]', key: 'type', breakpoint: 1200, up: true
                },
            ]}

        />
    </>;
};

export default OtherAlarmDevices;

