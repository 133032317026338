export type Options = {
    size?: string,
    center?: boolean;
    style?: Record<string, any>;//CSSProperties,
    label?: string;
    disabled?: boolean,
    hidden?: boolean,
    options: {
        push?: string;
        end?: string;
        prioritat?: string;
    },
    value: number;
    sound?: string;
    onChange?: (value: number) => void;
    onSelect?: (value: number) => void;
};

export const NotificationChannel =
    {
        NONE: 0,
        PUSH: 1 << 0,
        EMAIL: 1 << 1,
        END: 1 << 2,
        PRIORITAT: 1 << 3
    } as const;
