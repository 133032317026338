import { useL10n } from "@ews/react-localization-context";
import { FC } from "react";
import
{
    Navbar,
    NavbarLink
} from "./Navbar";

import
{
    apps,
    people,
    person
} from "ionicons/icons";

import { useIsMobile } from "../../Responsive";
import { MenuToggleProps } from "../types";
import { useNamedRoutes } from "../../NamedRoutes";
import { iif } from "../../../utils";

const Main: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const isMobile = useIsMobile();

    const color = isMobile ? 'primary' : 'light';

    return (
        <Navbar
            onMenuToggle={onMenuToggle}
            color={color}
        >
            <NavbarLink show={!isMobile}
                icon={<img style={{ maxHeight: "100%  " }} alt="logo" src='assets/img/logo.png' />}
                link={generate('home')}
            />
            <NavbarLink
                icon={iif(isMobile, people)}
                title={t('Customers')}
                link={generate('customers')}
            />
            <NavbarLink
                icon={iif(isMobile, apps)}
                title={t('Systems')}
                link={generate('systems')}
            />
            <NavbarLink
                icon={iif(isMobile, person)}
                title={t('Users')}
                link={generate('users')}
            />
        </Navbar>
    );

};

export default Main;