
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonIcon, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";

import { useL10n } from "@ews/react-localization-context";

import { document, folder } from "ionicons/icons";
import React, { ChangeEvent, useRef, useState } from "react";

import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsDropFile.module.scss';
import { SettingsDropFileProps } from "./type";

const SettingsDropFile: React.FC<SettingsDropFileProps> = ({
    text = '',
    startText = '',
    labelPosition = 'stacked',
    endText = '',
    onFile,
    lines = undefined,
}) =>
{

    const mobileView = useIsMobile();




    const handleChange = function (e: ChangeEvent<HTMLInputElement>) 
    {

        e.preventDefault();
        if (e.target.files && e.target.files[0]) {

            const file = e.target.files[0] as File;
            const filename = file.name;
            setFileName(filename);
            onFile(file);
            //const formData = new FormData();
            //formData.append(filename, file);

        }
    };

    const handleDrag = function (e: React.DragEvent<HTMLFormElement | HTMLDivElement>)
    {

        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLDivElement>)
    {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            setFileName(file.name);
            onFile(file);
        }
    };

    const onButtonClick = () =>
    {
        fileInputRef.current!.click();
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>('');
    const [dragActive, setDragActive] = useState(false);


    const { translate: t } = useL10n();


    return <IonItem lines={lines} className={`${style.overflow} ${globalStyle.setting}`}>

        <IonLabel style={{ whiteSpace: "break-spaces" }} position={labelPosition}>{`${startText}${t(text)}${endText}`}</IonLabel>

        <div className={style.container}>
            {mobileView ? <span className={style.position}>
                <IonText className={style.vertikalPosition} >{fileName ? fileName : t('no file selected')}</IonText>
                <IonButton className={style.buttonSpace} onClick={() =>
                {
                    fileInputRef.current!.click();
                }}>
                    <input ref={fileInputRef} hidden type='file' onChange={handleChange} />
                    <IonIcon icon={folder}></IonIcon>
                </IonButton> </span> :
                <form className={style["form-file-upload"]} onDragEnter={handleDrag} onClick={onButtonClick} onSubmit={(e) =>
                {
                    e.preventDefault();
                }}>
                    <input ref={fileInputRef} type="file" className={style["input-file-upload"]} hidden onChange={handleChange} />
                    <label htmlFor="input-file-upload" className={`${style['label-file-upload']} ${dragActive ? style["drag-active"] : ""}`}>
                        {fileName ? <IonCard>
                            <IonCardHeader color={'dark'}>
                                <IonIcon style={{ width: '20px', height: '20px' }} icon={document}></IonIcon>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonCardSubtitle>{fileName}</IonCardSubtitle>
                            </IonCardContent>
                        </IonCard> :
                            <div>
                                <p>{t('Drag and drop your file here or')}</p>
                                <p >{t('Upload a file')}</p>
                            </div>}
                    </label>
                    {dragActive && <div className={style["drag-file-element"]} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </form>}
        </div>
        {mobileView ? null : <IonNote>{t('Maximum upload size: ')}<b>8.00 MB</b></IonNote>}
    </IonItem>;
};

export default SettingsDropFile;