import { useL10n } from "@ews/react-localization-context";
import
{
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
    useIonRouter
} from "@ionic/react";

import { close } from "ionicons/icons";
import
{
    FC, PropsWithChildren, ReactElement
} from "react";

import type { MenuToggleProps } from "../types";

import style from './Sidebar.module.scss';
import { ReactClickEvent } from "../../types";

type MenuLinkProps = {
    title: string;
    link?: string;
    icon?: string | ReactElement;
    onClick?: (MenuToggleProps["onMenuToggle"]),
    id?: string;

};

export const MenuLink: FC<MenuLinkProps> = ({
    title,
    link = "",
    icon,
    onClick,
    id
}) =>
{
    const { translate: t } = useL10n();
    const router = useIonRouter();

    const onClickWrapper = (e: ReactClickEvent) =>
    {
        onClick && onClick(e);
        if (link) setTimeout(() => router.push(link));
    };

    return (
        <IonItem id={id} detail={false} onClick={onClickWrapper} button={true} >
            <IonLabel>{t(title)}</IonLabel>
            {icon && (typeof icon === 'string' ? <IonIcon icon={icon} /> : icon)}
        </IonItem>
    );

};

type MenuItemPops = PropsWithChildren & {
    className?: string;
};

export const MenuItem: FC<MenuItemPops> = ({
    className,
    children
}) =>
{
    return (
        <IonItem detail={false} button={false} className={className}>
            {children}
        </IonItem>
    );
};

type MenuSpacerProps = {
    label?: string;
};

export const MenuSpacer: FC<MenuSpacerProps> = ({
    label
}) =>
{
    const className = label ? "" : style.spacerFlat;
    return <IonItemDivider className={className}>{label && <IonLabel>{label}</IonLabel>}</IonItemDivider>;
};

type SidebarSectionProps = PropsWithChildren<{
    grow?: boolean;
}>;

export const SidebarSection: FC<SidebarSectionProps> = ({
    children,
    grow = false
}) =>
{
    const style = grow ? { flexGrow: 1 } : {};

    return (
        <IonList lines="none" style={style}>
            {children}
        </IonList >
    );
};

type SidebarProps = MenuToggleProps & PropsWithChildren<{
    title: string;
}>;

export const Sidebar: FC<SidebarProps> = ({
    title,
    children,
    onMenuToggle = () => { }
}) =>
{
    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton size="large" onClick={onMenuToggle}>
                            <IonIcon icon={close}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    {children}
                </div>
            </IonContent>
        </>
    );
};