import { en as reactEn } from '@ews/react-translations';


const en = {

    ...reactEn,

    /* navbar
    "Home": "home",
    "Systems": "Systems",
    "Customers": "Customers",
    "Users": "Users",
    */

    /* MainSettings */
    "Salutation.m": "Herr",
    "Salutation.f": "Frau",
    "Salutation.c": "Firma",
    "Salutation.other": "-",

    /* sidebar menu
    "Menu": "Menu",
    "Select language": "Select language",
    "My profile": "My profile",
    "Help": "Help",
    "Logout": "Logout",
    */

    /* locales */
    "en": "English",
    "de": "German",

    /* FilterCategory */
    //"TD_None": "Keine",
    "FC_Alarm": "Alarm",
    "FC_Fault": "Fault",
    "FC_Disablement": "Disablement",
    "FC_TechnicalMessage": "Technical messages",
    "FC_ActiveOutput": "Active output",
    "FC_Confirmation": "Confirmation",
    "FC_ExtinguishingEvent": "Extinguishing event",
    "FC_TestAlarm": "Test alarm",
    "FC_TestCondition": "Test condition",
    "FC_FaultAlarm": "Fault alarm",
    "FC_Status": "Status",
    "FC_All": "All",

    "OP_range_selected": "Range selected",
    "OP_range_begin": "Begin selection",
    "OP_range_end": "End selection",

    "OP_disable": "Disable",
    "OP_enable": "Enable",
    "OP_activate": "Activate",
    "OP_beginTest": "Test condition",
    "OP_endTest": "End test condition",
    "OP_silence": "Silence",
    "OP_reactivate": "Reactivate",
};

export default en;