import { useL10n } from "@ews/react-localization-context";
import { IonItem } from "@ionic/react";

import listStyle from "../GruppList.module.scss";

export const NoContent = () =>
{
    const { translate: t } = useL10n();

    return <IonItem className={listStyle.noContent} lines={'full'}><div>{t('No Content')}</div></IonItem>;
};