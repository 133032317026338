import
{
    IonIcon
} from "@ionic/react";

import
{
    ellipsisVertical
} from "ionicons/icons";

import
{
    FC,
    useEffect,
    useRef
} from "react";

import { ReactClickEvent } from "../../../types";
import listStyle from "../GruppList.module.scss";

type MenuComponentProps = {
    onClick?: (e: ReactClickEvent) => void;
};

export const MenuComponent: FC<MenuComponentProps> = ({
    onClick
}) =>
{
    //const color = event && event > 0 ? { color: "yellow" } : {};
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        const clickNoBubble = (e: MouseEvent) =>
        {
            e.stopPropagation();
        };
        ref.current?.addEventListener("click", clickNoBubble);
    });

    return <div
        // style={color}
        // slot={slot}
        ref={ref}
        onMouseUp={onClick}
        onTouchEnd={onClick}
        className={`${listStyle.settingsIcon}`}>

        <IonIcon
            //color={event && event > 0 ? "warning" : undefined}
            icon={ellipsisVertical}

        />
    </div>;
};