import Icon, { IconProps } from "@ews/react-icons";

import
{
    AuthorizationLevel
} from "@ews/zlt-events";

import
{
    FC,
    ReactNode,
    createContext,
    useContext,
    useState
} from "react";

import
{
    StatusColor
} from "./Icons";

import
{
    useWebsocket
} from "./ReactData/ReactDataProvider";

type AcquireAuthorizationContextType = (level: AuthorizationLevel, code?: string) => Promise<boolean>;
export type AuthorizationContextType = {
    authorize: AcquireAuthorizationContextType,
    authorizationLevel: AuthorizationLevel;
};

const defaultAuthorizationContext: AuthorizationContextType = {
    authorize: async () => false,
    authorizationLevel: AuthorizationLevel.LEVEL1
};
const AuthorizationContext = createContext<AuthorizationContextType>(defaultAuthorizationContext);

export const useAuthorization = () =>
{
    return useContext(AuthorizationContext);
};

// const mockCodes: string[] = [
//     "0000", // unused
//     "1111",
//     "00000"
// ];

type AuthorizationStatusProps = Omit<IconProps, 'icon'>;

export const AuthorizationStatus: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const { authorizationLevel } = useAuthorization();

    style = { ...style, ...{ "--fill-override": StatusColor.ONLINE } as React.CSSProperties };

    switch (authorizationLevel) {
        case AuthorizationLevel.LEVEL1:
            return <Icon icon="lock_icon_locked" {...props} style={style} />;
        case AuthorizationLevel.LEVEL2:
            return <Icon icon="lock_icon_unlocked" {...props} style={style} />;
        case AuthorizationLevel.LEVEL3:
            return <Icon icon="lock_icon_unlocked2" {...props} style={style} />;
    }
};

export const Authorization: FC<{ children: ReactNode; }> = ({ children }) =>
{
    const [authorizationLevel, setAuthorizationLevel] = useState(AuthorizationLevel.LEVEL1);
    const authClient = useWebsocket();

    const authorize: AcquireAuthorizationContextType = async (level: AuthorizationLevel, code?: string) =>
    {

        if (level <= authorizationLevel) {
            setAuthorizationLevel(level);
            return true;
        }

        if (!code) return false;

        try {

            await authClient.auth({
                type: "authorize",
                level,
                code
            });

            setAuthorizationLevel(level);
            return true;

        } catch (e) {
            setAuthorizationLevel(AuthorizationLevel.LEVEL1);
            return false;
        }

        /*
        if (mockCodes[level] === code) {
            setAuthorizationLevel(level);
            return true;
        }
        */

    };

    const authorizationContext = { authorize, authorizationLevel };

    return (
        <AuthorizationContext.Provider value={authorizationContext}>
            {children}
        </AuthorizationContext.Provider>

    );
};