import { IonCol, IonContent, IonPage } from "@ionic/react";
import { useRef, useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";
import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { getList } from "../../../TypeAhead/helper";
import { PhonenumbersTypes } from "./types";


const Phonenumbers: React.FC<PhonenumbersTypes> = ({ system }) =>
{


    const { generate } = useNamedRoutes();

    const mobileView = useIsMobile();


    const data = [
        {
            link: generate('systems'),
            phonenumber: '4181188',
            name: '',
            fireAlarm: '',
            testCondition: '',
            fault: '',
            technicalMessage: '',
            disablement: '',
            activeOutput: '',
            offline: '',

        },

    ];

    const [list, setList] = useState<any[] | null>(data);

    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);
    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);

    const eventSelectValue = useRef('');

    type MaintenanceReportFilter = {
        phonenumber?: StingFilterType;
        name?: StingFilterType;
        fireAlarm?: StingFilterType;
        testCondition?: StingFilterType;
        fault?: StingFilterType;
        technicalMessage?: StingFilterType;
        disablement?: StingFilterType;
        activeOutput?: StingFilterType;
        offline?: StingFilterType;
    };

    const [currentState, setState] = useState<MaintenanceReportFilter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);

    return <IonPage>
        <IonContent>
            <FilterList
                isDetailsHidden={false}
                onReset={(value: true) =>
                {
                    eventSelectValue.current = '';
                    setState({});
                    setClearFilterInputs(!clearFilterInputs);
                }}

                details={<>
                    <IonCol >

                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, testCondition: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'testCondition');
                                return typeheadList;
                            }}
                            text={"Test Condition"}
                        />

                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, offline: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'offline');
                                return typeheadList;
                            }}
                            text={"Offline"}
                        />
                    </IonCol>
                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, phonenumber: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'phonenumber');
                                return typeheadList;
                            }}
                            text={"Phonenumber"}
                        />
                        <TypeaheadString
                            reset={clearFilterInputs}
                            lines="none"
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, name: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'name');
                                return typeheadList;
                            }}
                            text={"Name"}
                        />
                    </IonCol>

                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, fireAlarm: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'fireAlarm');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Fire Alarm"}
                        />
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, fireAlarm: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'testCondition');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Test Condition"}
                        />


                    </IonCol>
                    <IonCol>
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, fault: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'fault');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Fault"}
                        />
                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, disablement: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'disablement');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Disablement"}
                        />

                    </IonCol>
                    <IonCol>

                        <TypeaheadString
                            reset={clearFilterInputs}
                            onFilter={async (value: string) =>
                            {
                                if (value.length) setState({ ...currentState, activeOutput: (a: string, b: string): boolean => stringFilter(a, value) });
                                const typeheadList = await getList(data, 'activeOutput');
                                return typeheadList;
                            }}
                            inputType={'date'}
                            text={"Active Output"}
                        />
                    </IonCol>
                </>}
            >
            </FilterList >


            <List
                rows={list}
                //maxHeight={mobileView ? undefined : filterDetailsOpen ? '45vh' : "61vh"}
                headers={[
                    { title: 'Phonenumber', key: 'phonenumber', both: true },
                    { title: 'Name', key: 'name', both: true },
                    { title: 'Fire Alarm', key: 'fireAlarm', breakpoint: 1200, up: true },
                    { title: 'Test Condition', key: 'testCondition', breakpoint: 1200, up: true },
                    { title: 'Fault', key: 'fault', breakpoint: 1200, up: true },
                    { title: 'Technical Message', key: 'technicalMessage', breakpoint: 1200, up: true },
                    { title: 'Disablement', key: 'disablement', breakpoint: 1200, up: true },
                    { title: 'Active Output', key: 'activeOutput', breakpoint: 1200, up: true },
                    { title: 'Offline', key: 'offline', breakpoint: 1200, up: true },
                ]}

            />
        </IonContent>
    </IonPage>;
};

export default Phonenumbers;