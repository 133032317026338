import { IonContent, IonItem, IonList, IonPopover, IonText } from "@ionic/react";
import React, { useRef, useState } from "react";
import style from "../../Plan.module.scss";
import { deviceList } from "./deviceList";
import { DeviceItemListProps, DeviceItemProps, DeviceListProps } from "./types";
import { useL10n } from "@ews/react-localization-context";

function getImgPath(filename: string)
{

    return `/assets/device-icons/${filename}_0.png`;
}

// const DeviceSelect: React.FC<DeviceSelectProps> = ({ current, text, onBlur, onSelect, list }) =>
// {

//     return <SettingsSelect
//         value={current}
//         text={text}
//         onBlur={(e) =>
//         {
//             if (onBlur) onBlur(e);
//         }}
//         onSelect={
//             (image) =>
//             {
//                 if (onSelect) onSelect({ image });
//             }
//         }
//     >
//         {list.map(item =>
//         {
//             const [title, value] = item;
//             return <>
//                 <SettingsSelectOption
//                     key={title}
//                     text={title}
//                     value={value}
//                 />
//             </>;
//         })}
//     </SettingsSelect>;
// };

const DeviceItem: React.FC<DeviceItemProps> = ({ text, imageName, className, color, onClick }) =>
{
    const filename = getImgPath(imageName);

    return <IonItem
        lines="none"
        className={className}
        color={color}
        onClick={() =>
        {
            if (onClick) onClick({ image: filename });
        }}
    >
        <IonText slot="start">{text}</IonText>
        <img slot="end" src={filename} alt="" />
    </IonItem>;
};

const DeviceItemList: React.FC<DeviceItemListProps> = ({ current, text, onBlur, onSelect, list, color }) =>
{

    const [currentValue, setCurrentValue] = useState(current);
    const { translate: t } = useL10n();

    const popover = useRef<HTMLIonPopoverElement>(null);

    return (
        <>
            <IonItem
                lines="full"
                className={style.pointer}
                id="deviceItemList"
                color={color}
            >
                <IonText slot="start">{`${t(text)} `}{currentValue.length ? "" : `  ...${t("please choose a device")}`}</IonText>
                {
                    list.filter(item => item[0] === currentValue).map(item =>
                    {
                        const [text, imageName] = item;
                        return <DeviceItem key={text} text={text} imageName={imageName} />;

                    })
                }
            </IonItem>
            <IonPopover
                trigger="deviceItemList"
                triggerAction="click"
                showBackdrop={false}
                style={{ "--min-width": "350px", "--max-width": "350px" }}
                ref={popover} >
                <IonContent>
                    <IonList>
                        {
                            list.map((item, index) =>
                            {
                                const [text, imageName] = item;
                                return <DeviceItem
                                    key={index}
                                    className={style.pointer}
                                    color={text === currentValue ? "secondary" : undefined}
                                    text={text}
                                    imageName={imageName}
                                    onClick={(device) =>
                                    {
                                        setCurrentValue(text);
                                        popover.current?.dismiss();
                                        if (onSelect) onSelect(device);
                                    }}
                                />;
                            })
                        }
                    </IonList>
                </IonContent>
            </IonPopover>
        </>
    );
};


const DeviceList: React.FC<DeviceListProps> = ({ current, InputValue, text, sortDirection, onBlur, onSelect }) =>
{

    const list = deviceList.filter(filename =>
    {
        return filename[0].toLocaleLowerCase().includes(InputValue.toLocaleLowerCase() || '');
    });

    if (sortDirection) {
        sortDirection === "ASC" ?
            list.sort((title1, title2) => title1[0].localeCompare(title2[0]))
            :
            list.reverse();
    }

    return <DeviceItemList
        list={list}
        current={current}
        text={text}
        onSelect={(image) =>
        {
            if (onSelect) onSelect(image);
        }}
    />;
};

export default DeviceList;