import { useL10n } from "@ews/react-localization-context";
import { SystemPlan } from "@ews/websocket-service";
import { IonButton, IonHeader, IonItem, IonList, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useRef, useState } from "react";

import { useModifySystemPlan, useSystemPlan } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import modalStyle from "./NewPlanPage.module.scss";
import { NewPlanPageProps } from "./types";

const NewPlanPageModal: React.FC<NewPlanPageProps> = ({ systemId, list }) =>
{

    const mobileView = useIsMobile();

    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);
    const [name, setName] = useState<string | undefined>(undefined);
    const [floor, setFloor] = useState<number>(0);
    const [error, setError] = useState("");
    const pageIdExistState = useRef<null | boolean>(null);

    const pages = useSystemPlan(systemId);

    const modifySystemPlan = useModifySystemPlan();

    const disableBtn = Boolean(!(!error.length && name && pageIdExistState));

    async function confirm(label: string | undefined, floor: number,)
    {
        if (!label) return;

        const newPage: SystemPlan = {
            label,
            floor
        };

        list.push(newPage);

        //modifySystemPlan(systemId, list);
        modal.current?.dismiss();
    }

    function onWillDismiss()
    {
        setName(undefined);
        setFloor(0);
        setError("");
    }
    function pageIdExist(id: number)
    {
        const page = pages.filter(p => p.id === id);
        return Boolean(page.length);
    }

    return <IonModal
        ref={modal}
        className={modalStyle.newSystemModal}
        trigger="newPlanPage"
        breakpoints={mobileView ? [.6] : undefined}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div style={{ width: "fit-content", margin: "auto" }}>
                    <IonTitle>{`${t("new Plan page")}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">
            <SettingsTextInput
                text={"Name"}
                value={name}
                onBlur={({ value }) =>
                {
                    setError(!value || !value.length ? "please type in a name" : '');
                    setName(value);
                }} />
            <SettingsNumberInput
                text={"Floor"}
                value={floor}
                onBlur={({ value }) =>
                {
                    const page = Number(value);
                    setError(pageIdExist(page) ? "allready exist" : '');
                    setFloor(page);
                }} />
            <IonItem lines={error.length ? "full" : "none"}>
                <div style={{ width: "fit-content", margin: "5px auto" }}>
                    <IonText color={"danger"}>{error}</IonText>
                </div>
            </IonItem>
        </IonList>
        <div className={`${modalStyle.buttonContainer} ${modalStyle.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => modal.current?.dismiss()}>{t("Cancel")}</IonButton>
            <IonButton
                disabled={disableBtn}
                slot="end"
                color={"success"}
                onClick={() => confirm(name, floor)}>
                {t("Create")}
            </IonButton>
        </div>
    </IonModal>;

};


export default NewPlanPageModal;