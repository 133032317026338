import
{
    ReactClickEvent
} from "./types";

export type LongPressAction = (event?: ReactClickEvent) => void;

const LongPress = (action: LongPressAction, timeout: number = 300) =>
{
    let timer: NodeJS.Timeout;

    const schedule = (event?: ReactClickEvent) =>
    {
        if (event?.cancelable) event?.preventDefault();

        return timer = setTimeout(() =>
        {
            action(event);
        }, timeout);

    };

    const cancel = (event?: ReactClickEvent) =>
    {
        if (!timer && event?.cancelable) event?.preventDefault();
        clearTimeout(timer);
    };

    return {
        onTouchEnd: cancel,
        onTouchMove: cancel,
        onTouchStart: schedule
    };
};

export default LongPress;