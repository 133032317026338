
import { IonItem, IonLabel } from "@ionic/react";

import { useL10n } from "@ews/react-localization-context";

import { SettingsTitleProps } from "./type";

const SettingsTitle: React.FC<SettingsTitleProps> = ({ text = '', startText = '', endText = '' }) =>
{


    const { translate: t } = useL10n();

    return <IonItem >
        <IonLabel style={{ whiteSpace: "break-spaces", textAlign: 'center' }} >{`${startText}${t(text)}${endText}`}</IonLabel>
    </IonItem>;
};

export default SettingsTitle;