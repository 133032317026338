import { useL10n } from "@ews/react-localization-context";
import { IonInput, IonLabel, IonSegmentButton } from "@ionic/react";
import React, { useRef, useState } from "react";
import { SwiperSlide, useSwiper } from "swiper/react";
import { useIsMobile } from "../../../Responsive";
import SettingsSwiper from "../../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import SettingsGrid from "../../../Settings/SettingsGrid/SettingsGrid";
import SettingsSegment from "../../../Settings/SettingsSegment/SettingsSegment";


import { DisplayTabProps, Page, SettingsOverlayProps } from "./types";
//import style from './SettingsOverlay.module.scss';
import Overflow from "../../../Overflow/Overflow";
import InvoiceSettings from "../InvoiceSettings/InvoiceSettings";
import LanguageFormatSettings from "../LanguageFormatSettings/LanguageFormatSettings";
import LogoSettingsSettings from "../LogoSettings/LogoSettings";
import MainSettings from "../MainSettings/MainSettings";
import PermissionsSettings from "../PermissionsSettings/PermissionsSettings";

const DisplayTab: React.FC<DisplayTabProps> = ({ name, display, children }) =>
{

    if (name === display) {
        return <>{children}</>;
    }

    return <></>;

};


const SettingsOverlay: React.FC<SettingsOverlayProps> = ({ customer, onClose }) =>
{
    const pageList: Page[] = [
        {
            value: 'general',
            title: "General",
            child: <MainSettings customer={customer} />
        },
        {
            value: 'rights',
            title: "Rights",
            child: <PermissionsSettings customer={customer} />
        },
        {
            value: 'language/format',
            title: "Language/Format",
            child: <LanguageFormatSettings customer={customer} />
        },
        {
            value: 'logo',
            title: "Logo",
            child: <LogoSettingsSettings customer={customer} />
        },
        {
            value: 'invoicing',
            title: "invoicing",
            child: <InvoiceSettings customer={customer} />
        },
    ];


    const mobileView = useIsMobile();
    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;

    const [currentSegment, setCurrentSegment] = useState<string>('general');
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();


    const { translate: t } = useL10n();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={(e) =>
            {

                swiper.slideTo(slideToRef.current);
            }}
            hidden />;
    };

    return <>
        {/*  <IonContent id="settingsContent" className={style.overlaySettings} fullscreen> */}

        <SettingsSegment value={currentSegment} onSelect={(current: string) => setCurrentSegment(current)}>
            {pageList.map((segment, index) => (

                <IonSegmentButton key={segment.value} value={segment.value} onClick={() =>
                {
                    if (mobileView) {
                        slideToRef.current = index;
                        SwiperRef.current.click();
                    }
                }}>
                    <IonLabel>{t(segment.title)}</IonLabel>
                </IonSegmentButton>
            ))}
        </SettingsSegment>


        <SettingsSwiper onSlideChange={(index) =>
        {
            slideToRef.current = index;
            setCurrentSegment(pageList[index].value);
        }}>
            {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}

            {pageList.map((page, index) =>
            {
                const { child, value } = page;
                return <SliderComponent key={index}>
                    <DisplayTab name={value} display={currentSegment}>
                        <Overflow minHeight={"80vh"} maxHeight={"87vh"}>
                            <SettingsGrid>
                                {child}
                            </SettingsGrid>
                        </Overflow>
                    </DisplayTab>
                </SliderComponent>;
            })}

        </SettingsSwiper>


        {/*   </IonContent> */}

    </>;

};

export default SettingsOverlay;