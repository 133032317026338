import { useRef } from "react";
import Box from "./components/Box";
import wrapperStyle from "./Wrapper.module.scss";
import { WrapperProps } from "./types";

const Checkbox: React.FC<WrapperProps> = ({
    children,
    color,
    size,
    style,
    position,
    checked,
    onClick,
    uncheckedIcon,
    checkedIcon,
    border,
    className,
    id,
    hidden = false,
    disabled
}) =>
{
    const divRef = useRef<HTMLDivElement>(null);


    const _className = `${className || ""} ${children ? "" : wrapperStyle.middle} 
                        ${wrapperStyle.container} 
                        ${border ? `${wrapperStyle.border} 
                        ${disabled ? wrapperStyle.disabled : checked ? wrapperStyle.checkedBorder : wrapperStyle.uncheckedBorder}` : ""}`;

    return <div
        id={id}
        className={_className}
        style={style || undefined}
        hidden={hidden}
        onClick={() =>
        {
            if (!disabled) divRef.current?.click();
        }}
    >
        {children}
        <Box
            disabled={disabled}
            position={position || {
                top: 0,
                right: 0
            }}
            reference={divRef}
            hasSiblings={Boolean(children)}
            color={color}
            size={size}
            checked={checked}
            uncheckedIcon={uncheckedIcon}
            checkedIcon={checkedIcon}
            onClick={(value) =>
            {
                if (onClick) onClick(value);
            }} />
    </div>;
};

export default Checkbox;