import { useL10n } from "@ews/react-localization-context";
import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../../../../../Responsive";
import globalStyle from '../../../GlobalSettings.module.scss';
import style from '../../SettingsRangeInput.module.scss';
import { SettingsZoneRangeInputProps, Zone } from "./types";
import { InputChangeEventDetail, IonInputCustomEvent } from "@ionic/core";
import { isValidNumber } from "../../isValid";

/* 
TODO:   anschauen wie ich das machen kann das ich buchstaben verbieten kann
        momentan funktioniert es nicht ganz 

*/

const SettingsZoneRangeInput: React.FC<SettingsZoneRangeInputProps> = ({
    onChange,
    value = {},
    hidden = false,
    inputLabels,
    labels,
    labelPosition = 'stacked',
    title,
    disabled,
    required,
    lines = undefined,
    position = { parent: "line", children: "line" },
    debounce,
    devider = true
}) =>
{

    const regex = /\D/g;

    const includesForbittenLetters = (str: string) => regex.test(str);


    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const inputStyle = mobileView ? 'mobileInput' : 'webInput';

    const [zone, setZone] = useState<Zone>(value);

    function handleChange(value: IonInputCustomEvent<InputChangeEventDetail>["detail"]["value"], type: keyof Zone)
    {

        if (!value) return;

        //if (includesForbittenLetters(value)) value.replace(regex, "");

        if (Number(value) <= 0 || Number.isNaN(Number(value))) return;

        let valid;

        if (type === "startGroup" || type === "endGroup") {
            valid = type === "startGroup" ? isValidNumber(value, zone.endGroup || Infinity) : isValidNumber(zone.startGroup || Infinity, value);
            if (!valid) value = type === "startGroup" ? zone.endGroup! : zone.startGroup;
        }
        if (type === "startElement" || type === "endElement") {
            valid = type === "startElement" ? isValidNumber(value || 0, zone.endElement || Infinity) : isValidNumber(zone.startElement || 0, value || Infinity);
            if (!valid) value = type === "startElement" ? zone.endElement! : zone.startElement;
        };

        const currentZone = { ...zone };

        currentZone[type! as keyof typeof zone] = value;

        setZone(currentZone);
    }


    useEffect(() =>
    {
        if (Object.keys(zone).length) {

            const startZoneElement = zone.startGroup ? `${zone.startGroup}${zone.startElement ? `/${zone.startElement}` : ''}` : undefined;
            const endZoneElement = zone.endGroup ? `${zone.endGroup}${zone.endElement ? `/${zone.endElement}` : ''}` : undefined;

            const params = { startZoneElement, endZoneElement };

            onChange(params);
        }

    }, [zone]);


    return <IonItem
        hidden={hidden}
        lines={lines}
        className={`${globalStyle.setting}`}>
        <div className={style.row}>

            {title ? <IonLabel
                style={{ width: "fit-content", margin: "0 auto" }}
            >
                {t(title)}
            </IonLabel> : null}

            <div>
                <section>
                    {labels?.start ?
                        <IonText className={style.subLabel}>{t(labels.start)}</IonText>
                        : null}
                    <div style={{ display: "flex" }}>
                        <IonInput
                            debounce={debounce}
                            color={"danger"}
                            required={required?.startGroup}
                            disabled={disabled}
                            type="number"
                            value={zone.startGroup}
                            placeholder={t(inputLabels?.parent || "")}
                            style={{ minHeight: "30px" }}
                            className={`${style.group} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "startGroup");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "startGroup");
                            }}
                        />
                        <IonInput
                            debounce={debounce}
                            color={"danger"}
                            required={required?.startElement}
                            disabled={!zone.startGroup || disabled}
                            type="number"
                            value={zone.startElement}
                            placeholder={t(inputLabels?.children || "")}
                            style={{ minHeight: "30px" }}
                            className={`${style.element} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "startElement");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "startElement");
                            }}
                        />
                    </div>
                </section>
                {devider ? <p className={`${style.devider}`} >{' __ '}</p> : null}
                <section className={style.input}>
                    {labels?.end ?
                        <IonText className={style.subLabel}>{t(labels.end)}</IonText>
                        : null}
                    <div style={{ display: "flex" }}>
                        <IonInput
                            debounce={debounce}
                            color={"danger"}
                            required={required?.endGroup}
                            disabled={disabled}
                            type="number"
                            value={zone.endGroup}
                            placeholder={t(inputLabels?.parent || "")}
                            style={{ minHeight: "30px" }}
                            className={`${style.group} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "endGroup");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "endGroup");
                            }}

                        />
                        <IonInput
                            debounce={debounce}
                            color={"danger"}
                            required={required?.endElement}
                            disabled={!zone.endGroup || disabled}
                            type="number"
                            value={zone.endElement}
                            placeholder={t(inputLabels?.children || "")}
                            style={{ minHeight: "30px" }}
                            className={`${style.element} ${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                            onIonInput={(e) =>
                            {
                                if (debounce) handleChange(e.detail.value, "endElement");
                            }}
                            onIonChange={(e) =>
                            {
                                if (!debounce) handleChange(e.detail.value, "endElement");
                            }}
                        />
                    </div>
                </section>
            </div>

        </div>
    </IonItem >;
};

export default SettingsZoneRangeInput;