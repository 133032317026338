import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPopover, IonTitle, IonToolbar } from "@ionic/react";
import { close, searchCircle, searchCircleOutline, searchCircleSharp } from "ionicons/icons";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useIsMobile } from "../Responsive";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import styleTypeahead from "./Typeahead.module.scss";
import { Item, TypeaheadContainerProps, TypeaheadProps } from "./types";


const TypeAheadContainer: React.FC<TypeaheadContainerProps> = ({
    id,
    children,
    showBackdrop,
    className,
    modalRef,
    initialBreakpoint,

}) =>
{

    const mobileView = useIsMobile();

    return mobileView ?
        <IonModal
            showBackdrop={showBackdrop}
            keepContentsMounted
            keyboardClose={false}
            className={className}
            ref={modalRef as RefObject<HTMLIonModalElement>}
            initialBreakpoint={initialBreakpoint}
        >{children}
        </IonModal>
        :
        <IonPopover
            ref={modalRef as RefObject<HTMLIonPopoverElement>}
            keyboardClose={false}
            className={className}
            size="cover"
            id={`popover-${id}`}
            showBackdrop={false}
            trigger={`open-typeahead-${id}`}
        >
            {children}
        </IonPopover>;
};


const TypeAhead: React.FC<TypeaheadProps> = ({
    id,
    text,
    list,
    onFilter,
    onChange,
    onClear,
    showResetButton,
    debounce,
    lines,
    disabled,
    required,
    value,
    color,
    endText,
    startText,
    inputType = 'text'
}) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();

    const modalRef = useRef<HTMLIonModalElement | HTMLIonPopoverElement>(null);

    const [searchValue, setSearchValue] = useState(value || '');

    const [typeaheadList, setTypeaheadList] = useState<Item[] | []>(list || []);

    const handleClose = () =>
    {
        modalRef.current?.dismiss();
    };
    const handleOpen = () =>
    {
        modalRef.current?.present();
    };


    const handleClick = (item: Item) =>
    {
        const { key, data } = item;
        const value = data[key];
        setSearchValue(value);
        if (onChange) onChange(data);
        else loadTypeaheadList(value);
        handleClose();
    };

    const loadTypeaheadList = async (value: string) =>
    {
        const results = await onFilter(value);
        setTypeaheadList(results || []);
    };

    useEffect(() =>
    {
        if (!value) setSearchValue("");
        else if (value !== searchValue) setSearchValue(value);

    }, [value]);

    return <>
        <SettingsTextInput
            id={`open-typeahead-${id}`}
            debounce={debounce}
            textAlign="left"
            color={color}
            endText={endText}
            startText={startText}
            disabled={disabled}
            required={required}
            lines={lines}
            //inputRef={input}
            icon={searchCircleSharp}
            text={text}
            inputType={inputType}
            value={searchValue}
            onClick={handleOpen}
            onFocus={() => loadTypeaheadList(searchValue)}
            onEnter={(value) => setSearchValue(value)}
            onChange={(value) =>
            {
                setSearchValue(value || "");
                loadTypeaheadList(value);
            }}
        // onClear={() =>
        // {
        //     if (!showResetButton) return;
        //     setSearchValue("");
        //     if (onClear) onClear();
        // }}
        />
        {
            <TypeAheadContainer
                id={id || ''}
                modalRef={modalRef}
                showBackdrop={true}
                className={`${styleTypeahead.backdrop} ${styleTypeahead.modal}`}
                initialBreakpoint={1}
            >
                {mobileView && <IonHeader>
                    <IonToolbar color="dark">
                        <IonTitle>{text}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => handleClose()}>
                                <IonIcon icon={close}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>}

                <IonContent
                    data-testid="typeahead-content"

                >
                    {/*   
                    
                    hier muss man sich überlegen ob man das in der Mobilen Version haben will
                    momentan funktioniert es ohne

                    {mobileView && <IonSearchbar
                        data-testid="typeahead-searchbar"
                        value={searchValue}
                        onIonChange={({ detail }) => setSearchValue(detail.value || '')}
                        onIonClear={(e) => console.log(e.type, e)}
                        onIonInput={({ detail }) =>
                        {
                            setSearchValue(detail.value || "");
                        }}
                    />} */}
                    {typeaheadList.length ? typeaheadList.map((item, index) =>
                    {
                        const { key, data } = item;

                        return <IonItem
                            data-testid="typeahead-chooice-item"
                            key={index}
                            button={true}
                            detail={false}
                            lines="full"
                            color={'secondary'}
                            onClick={async () => handleClick(item)
                            }
                        >
                            <span className={styleTypeahead.textContainer}>
                                <IonLabel>
                                    {data[key]}
                                </IonLabel>
                            </span>
                        </IonItem>;
                    })
                        : <IonItem
                            detail={false}
                            lines="full"
                            color={'secondary'}
                        >
                            <span className={styleTypeahead.textContainer}>
                                <IonLabel>
                                    {t("no content")}
                                </IonLabel>
                            </span>
                        </IonItem>}
                </IonContent>

            </TypeAheadContainer >}

    </>;
};

export default TypeAhead;

