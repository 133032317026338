import
{
    IonButton,
    IonButtons, IonFooter,
    IonHeader,
    IonIcon,
    IonLabel,
    IonTabBar,
    IonTabButton
} from "@ionic/react";

import
{
    FC,
    PropsWithChildren,
    ReactElement
} from "react";

import { Color } from "@ionic/core";
import { ellipsisVertical, options } from "ionicons/icons";
import { useNamedRoutes } from "../../NamedRoutes";
import { useIsMobile } from "../../Responsive";
import { MenuToggleProps } from "../types";

import style from './Navbar.module.scss';

type LabelOrIcon = {
    title: string;
    icon: string | ReactElement;
} | {
    title?: never;
    icon: string | ReactElement;
} | {
    title: string;
    icon?: never;
};

type NavbarLinkProps = LabelOrIcon & {
    link?: string;
    show?: boolean;
};

export const NavbarLink: FC<NavbarLinkProps> & { isTabButton: boolean; } = ({
    link = "",
    show = true,
    title,
    icon
}) =>
{
    const { generate, router } = useNamedRoutes();

    if (!show) return <></>;

    const current = generate();
    const selected = current === link;
    const tab = link.split("/").filter(p => p).join("-");
    const click = () => router?.push(link);

    return (
        <IonTabButton className={style.tabButton} selected={selected} tab={tab} href={link} onClick={click}>
            {icon && (typeof icon === 'string' ? <IonIcon icon={icon} /> : icon)}
            {title && <IonLabel>{title}</IonLabel>}
        </IonTabButton>
    );
};

NavbarLink.isTabButton = true;

type NavbarProps = MenuToggleProps & PropsWithChildren<{
    color?: Color;
}>;

export const Navbar: FC<NavbarProps> = ({
    children,
    color = "primary",
    onMenuToggle
}) =>
{
    const isMobile = useIsMobile();
    const Envelope = isMobile ? IonFooter : IonHeader;

    return (
        <Envelope className={isMobile ? style.mobile : style.desktop} style={{ display: "flex", flexDirection: "row" }}>
            <IonTabBar style={{ display: "flex", flexDirection: "row" }} color={color} className={style.left}>
                {children}
            </IonTabBar>
            <IonButtons className={style.right}>
                <IonButton color={color} fill="solid" className={style.button} onClick={onMenuToggle}>
                    <IonIcon icon={isMobile ? ellipsisVertical : options} />
                </IonButton>
            </IonButtons>
        </Envelope >
    );
};



// const icon = item[flavour]?.icon || item.icon;
// const label = item[flavour]?.label || item.label;

// if (!icon && !label) return <></>;

// const route = item.route;
// const path = generate(route);

// const selected = generate() === path;

// return (
//     <IonTabButton selected={selected} className={`${style['tab']} ${selected ? style['selected'] : ''}`} key={route} tab={route} href={path}>
//         {icon && (icon.startsWith("data:") ? <IonIcon icon={icon} /> : <img src={icon} alt={label} className={style['icon-img']} />)}
//         {label && <IonLabel class={textTransformation}>{t(label)}</IonLabel>}
//     </IonTabButton>
// );
// })}