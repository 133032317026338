import { IonCheckbox, IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useRef, useState } from "react";
import boxStyle from "./Box.module.scss";
import { BoxProps } from "./types";


const Box: React.FC<BoxProps> = ({
    disabled = false,
    reference,
    size = "small",
    checked = true,
    position,
    checkedIcon = checkmarkCircleOutline,
    uncheckedIcon = closeCircleOutline,
    hasSiblings,
    onClick
}) =>
{
    const boxRef = useRef<HTMLIonCheckboxElement>(null);
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    return (
        <div
            className={`icon-box ${boxStyle.container} ${hasSiblings ? boxStyle.hasSiblings : boxStyle.noSiblings}`}
            style={position}
            ref={reference}
            onClick={(e) =>
            {
                e.stopPropagation();
                if (!disabled) boxRef.current?.click();
            }}
        >
            <span hidden>
                <IonCheckbox
                    aria-label=""
                    ref={boxRef}
                    disabled={disabled}
                    checked={isChecked}
                    onIonChange={({ detail }) =>
                    {
                        setIsChecked(detail.checked);
                        if (onClick) onClick(detail.checked);
                    }} >{""}</IonCheckbox>
            </span>
            <IonIcon
                size={size}
                color={disabled ? "medium" : isChecked ? "success" : "danger"}
                icon={isChecked ? checkedIcon : uncheckedIcon}
                onClick={(e) =>
                {
                    e.stopPropagation();
                    if (!disabled) boxRef.current?.click();
                }}
            />
        </div >);
};

export default Box;