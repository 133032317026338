import { IonCol } from "@ionic/react";
import { useIsMobile } from "../../../../Responsive";
import { SettingsColumnProps } from "./types";


const SettingsTableColumn: React.FC<SettingsColumnProps> = ({ children, size, sizeMd, sizeLg, sizeSm, sizeXl, style = undefined, center = true }) =>
{
    const mobileView = useIsMobile();
    const columnMargin = { margin: '5px 0' };
    const checkboxPosition = center ? { display: 'flex', justifyContent: 'center', } : {};
    const columnStyle = { ...checkboxPosition, ...columnMargin, ...style };



    return mobileView ?
        <>{children}</>
        :
        <IonCol style={columnStyle}
            size={size}
            sizeMd={sizeMd}
            sizeLg={sizeLg}
            sizeSm={sizeSm}
            sizeXl={sizeXl}
        >{children}</IonCol>;
};

export default SettingsTableColumn;