export const deviceList = [
    ['Rauchmelder', 'ON_RM'],
    ['Temperaturmelder', 'ON_WM'],
    ['Flammenmelder', 'ON_FLM'],
    ['Rauchmelder Zw.boden', 'ON_RMZWB'],
    ['Rauchmelder Zw.decke', 'ON_RMZWD'],
    ['HFM', 'ON_HFM'],
    ['Lüft. Leitungs. melder', 'ON_LLM'],


    ['RAS', 'ON_RAS'],
    ['linearer Rauchmelder', 'ON_LRM'],
    ['BMZ', 'BMZ'],
    ['SG', 'SG'],
    /*  ['FBF', 'FBF', 'das finde ich im Ordner nicht'],   
     ['ABF', 'ABF', 'das finde ich im Ordner nicht'],
     ['FSS', 'FSS', 'das finde ich im Ordner nicht'], */


    ['RWA', 'RWA'],
    ['Sirene_1', 'Siren1', 'in der Liste steht das Siren_1 ist aber laut ordner Siren1'],
    ['Sirene_2 (ähnlich VdS)', 'Siren2', 'in der Liste steht das Siren_2 ist aber laut ordner Siren2'],
    ['Blitzleuchte', 'Flash'],
    ['Strg', 'Strg2', 'in der Liste steht das Strg_2 ist aber laut ordner Strg2'],
    ['Strg mit Relaisspule', 'Strg1'],
    ['Button', 'Button1'],

    ['LED', 'LED'],
    ['UeE Tag_0 = N = bl_71 = T = ge', 'UeE_T-N'],
    ['UeE Erkund. _0 = grau _72 = gelb', 'UeE_Erkund'],
    ['UeE Button', 'UeE_Button'],
    ['VdS_ORM', 'VdS_ORM'],
    ['VdS_ORM Zwi. decke', 'VdS_ORMZWD'],
    ['VdS_ORM Doppelboden', 'VdS_ORMDB'],


    ['VdS_WM Diff', 'VdS_WMDiff'],
    ['VdS_WM Max', 'VdS_WMMax'],
    ['VdS_HFM', 'vds_hfm', 'in der Liste steht das Vds_HFM ist aber laut ordner vds_hfm'],
    ['VdS_ARM', 'VdS_ARM'],
    ['VdS_FLM IR', 'VdS_FLM-IR'],
    ['VdS_FLM UV', 'VdS_FLM-UV'],


    ['VdS_FLM UV / IR', 'vDs_flm-uv-ir', 'in der Liste VdS_FLM-UV-IR steht das ist aber laut ordner vDs_flm-uv-ir'],
    ['VdS_IRM', 'VdS_IRM'],
    ['VdS_LKM', 'VdS_LKM'],
    ['VdS_LRM Empfänger', 'VdS_LRM_Empf', 'in der Liste VdS_LRM-Empf steht das ist aber laut ordner VdS_LRM_Empf'],
    ['VdS_LRM Sender Empfänger', 'VdS_LRM-Send-Empf'],
    ['VdS_LRM Sender', 'VdS_LRM-Send'],


    ['VdS_LWM Diff', 'VdS_LWM_Diff', 'in der Liste VdS_LWM-DIFF steht das ist aber laut ordner VdS_LWM_DIFF'],
    ['VdS_LWM Max', 'VdS_LWM-Max'],
    ['VdS_Multi Melder', 'VdS_MMeld'],
    ['VdS_Nicht autom Melder', 'vds_NAM', 'in der Liste VdS_NAM steht das ist aber laut ordner vds_NAM'],
    ['VdS_Quadrat', 'Button2'],

    ['VdS_Quadrat mit Symbolen', 'Button2_Symb'],
    ['VdS opt. Signalgeber', 'VdS_Blitzleuchte'],
    ['VdS Steuermodul', 'VdS_Strg-modul'],
    ['VdS Türhaltemagnet', 'VdS_tuermag', 'in der Liste "VdS_Tuermag" steht das ist aber laut ordner "VdS_tuermag"'],

    ['VdS Übertragungsgerät', 'VdS_UeE'],

];