
import { IonIcon } from "@ionic/react";
import { closeCircle, informationCircleOutline } from "ionicons/icons";
import React, { ReactNode, createContext, useContext, useRef, useState } from "react";
import { useIsMobile } from "../Responsive";
import style from './ToolTip.module.scss';


const TooltipContext = createContext(
    (x: number, y: number, text: string) => { }
);


export const ToolTipProvider: React.FC<{ children: ReactNode; }> = ({ children }) =>
{
    const [box, setBox] = useState<{ x: number, y: number, text: string; }>({ x: -100, y: -100, text: '' });
    function presentTooltip(x: number, y: number, text: string)
    {
        setBox({ x, y, text });

    }

    return <TooltipContext.Provider
        value={presentTooltip}
    >
        <>{children}</>

        <TooltipBox x={box.x} y={box.y} text={box.text}></TooltipBox>
    </TooltipContext.Provider>;
};


const TooltipBox: React.FC<{ x: number, y: number, text: string; }> = ({ x, y, text }) =>
{
    const mobileView = useIsMobile();

    const position = mobileView ? { left: '2%' } : { right: '2%' };


    const closeHandler = (e: any) => // React.MouseEvent<HTMLIonIconElement> oder React.PointerEvent<HTMLIonIconElement> 
    {
        e.nativeEvent.target.offsetParent.style.cssText = `
        top:${-100}px;
        left:${-100}px;`;
    };

    return <div className={`${style.tooltip} ${style.right}`} style={{ top: `${y}px`, left: `${x + 30}px` }}>
        <div className={style.closeBtnWrapper}>
            {mobileView ? <IonIcon
                size="small"
                color={'danger'}
                className={style.closeBtn}
                style={{ ...position }}
                onClick={closeHandler} icon={closeCircle} /> : null}
        </div>
        <p className={style.text} >{text}</p>
        <i></i>
    </div>;
};

export const TooltipIcon: React.FC<{ text: string, cb?: () => void; }> = ({ text }) =>
{

    const tooltip = useTooltip();

    const isHovering = useRef<boolean>(false);
    const mobileView = useIsMobile();

    return <IonIcon
        icon={informationCircleOutline}
        className={`${style.pointer}`}
        onMouseEnter={(e) =>
        {

            if (isHovering.current || mobileView) return;
            isHovering.current = true;
            tooltip(e.pageX, e.pageY, text);
        }}
        onMouseLeave={(e) =>
        {
            if (mobileView) return;
            isHovering.current = false;
            tooltip(-100, -100, text);
        }}
        onClick={(e) =>
        {
            if (mobileView) tooltip(e.pageX, e.pageY, text);
        }} />;

};

export function useTooltip()
{
    const tooltip = useContext(TooltipContext);
    return tooltip;
}