import { useL10n } from "@ews/react-localization-context";
import { IonItem, IonLabel, IonSelect } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsSelect.module.scss';
import { SettingsSelectProps, Value } from "./types";

const SettingsSelect: React.FC<SettingsSelectProps> = ({
    onSelect,
    onBlur,
    id,
    className,
    hidden = false,
    children,
    startText = '',
    text = '',
    endText = '',
    tooltip,
    value,
    labelPosition = 'stacked',
    confirmText = 'confirm',
    disabled,
    cancelText = 'cancel',
    lines = undefined,
    multiple = undefined,
}) =>
{
    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const selectInterface = mobileView ? 'alert' : 'popover';
    const selectValue = useRef<Value | null | undefined>(value);

    const hasFocus = useRef<boolean>(false);

    const handleFocusOut = () =>
    {
        hasFocus.current = false;
    };
    const handleFocusIn = () =>
    {
        hasFocus.current = true;
    };

    useEffect(() =>
    {
        if (value === "") return;

        selectValue.current = value;

    }, [value]);

    return <IonItem
        data-testid="settings-select-container"
        className={`${mobileView ? style.textRight : style.textLeft} ${style.itemContainer} ${style.overflow} ${globalStyle.setting} ${className}`}
        hidden={hidden}
        lines={lines}>
        <span className={style.flex}>
            <IonLabel
                data-testid="settings-select-label"
                className={style.label}
                position={labelPosition}>{`${t(startText)}${t(text)}${t(endText)}`}
                {mobileView ? <></> : tooltip}
            </IonLabel>

        </span>
        <div className={`${style.container}`}>
            <IonSelect
                label=""
                id={id}
                data-testid="settings-select-input"
                disabled={disabled}
                multiple={multiple}
                className={style.select}
                interface={selectInterface}
                value={value}
                okText={t(confirmText)}
                cancelText={t(cancelText)}
                onIonFocus={(e) =>
                {
                    handleFocusIn();
                    //if (onFocus) onFocus(e);
                }
                }
                onIonBlur={(e) =>
                {
                    handleFocusOut();

                    if (value !== selectValue.current) {
                        if (onBlur) onBlur({ value: selectValue.current, focus: hasFocus.current, event: e });
                    }
                }}
                onIonChange={(e: any) =>
                {
                    if (onBlur) selectValue.current = e.detail.value;
                    if (onSelect) onSelect(e.detail.value);
                }}>
                {children}
            </IonSelect>
        </div>
    </IonItem>;
};

export default SettingsSelect;