import { useL10n } from '@ews/react-localization-context';
import { IonContent, IonInput, IonSegment, IonSegmentButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { SwiperSlide, useSwiper } from 'swiper/react';
import Overflow from '../../../../../Overflow/Overflow';
import { useIsMobile } from '../../../../../Responsive';
import SettingsSwiper from '../../../../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper';
import SettingsGrid from '../../../../../Settings/SettingsGrid/SettingsGrid';
import { DisplayTab } from '../../../../SystemSettings/SettingsOverlay/SettingsOverlay';
import { Page } from '../../../../SystemSettings/types';
import General from './Pages/General/General';
import Operation from './Pages/Operation/Operation';
import Push from './Pages/Push/Push';
import View from './Pages/View/View';
import { SettingsOverlayProps } from './types';


const SettingsOverlay: React.FC<SettingsOverlayProps> = ({ systemId, userId }) =>
{

    if (!systemId) systemId = "";
    if (!userId) userId = "";

    const mobileView = useIsMobile();

    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const { translate: t } = useL10n();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={() => swiper.slideTo(slideToRef.current)}
            hidden />;
    };


    const pageList: Page[] = [
        {
            value: 'general',
            title: t("General"),
            child: <General systemId={systemId || ""} userId={userId || ""} />
        },
        {
            value: 'view',
            title: t("View"),
            child: <View systemId={systemId || ""} userId={userId || ""} />
        },
        {
            value: 'operation',
            title: t("Operation"),
            child: <Operation systemId={systemId || ""} userId={userId || ""} />
        },
        {
            value: 'push',
            title: t("Push"),
            child: <Push systemId={systemId || ""} userId={userId || ""} />
        },

    ];

    const [currentSegment, setCurrentSegment] = useState<Page["value"]>('general');

    return <IonContent
    >
        <>
            <IonSegment>
                {pageList.map(page =>
                {
                    const { value, title } = page;
                    return <IonSegmentButton key={value} onClick={() => setCurrentSegment(value)}>{title}</IonSegmentButton>;
                })}
            </IonSegment>
        </>

        <SettingsSwiper onSlideChange={(index) =>
        {
            slideToRef.current = index;
            setCurrentSegment(pageList[index].value);
        }}>
            {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}

            {pageList.map((page, index) =>
            {
                const { child, value } = page;
                return <SliderComponent key={index}>
                    <DisplayTab name={value} display={currentSegment}>
                        <Overflow minHeight={"92%"} maxHeight={"92%"}>
                            <SettingsGrid>
                                {child}
                            </SettingsGrid>
                        </Overflow>
                    </DisplayTab>
                </SliderComponent>;
            })}

        </SettingsSwiper>

    </IonContent>;
};
export default SettingsOverlay;
