import { useL10n } from "@ews/react-localization-context";


import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";

import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { DegreeProtectionProps } from "./types";


const DegreeProtection: React.FC<DegreeProtectionProps> = ({ system }) =>
{

    const { translate: t } = useL10n();

    return <>
        <SettingsCol >
            <SettingsSelect
                text={"Degree of protection "}
                tooltip={<TooltipIcon text={t("der Country der Anlage")} />}
                value={"austria"}
                onBlur={({ value }) =>
                {

                    /* setSystemDetailState({ ...systemDetailsState, country: value });
                    saveSystemDetails("country", value); */
                }}>
                <SettingsSelectOption text={"Vollschutz"} value={"fullProtection"} />
            </SettingsSelect>

            <SettingsNumberInput
                text={"Area monitored by fire detection system [m2]"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsNumberInput
                text={"Number of fire areas"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                value={0}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


        </SettingsCol>
        <SettingsCol >

        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default DegreeProtection;