import { useL10n } from "@ews/react-localization-context";
import { System, SystemDetails, SystemStatus } from "@ews/websocket-service";
import { useEffect, useState } from "react";

import
{
    useModifySystem,
    useModifySystemDetails,
    useSystemDetails
} from "../../../ReactData/system";

import CustomerTypeAhead from "../../../Customer/CustomerTypeAhead";
import { useCustomer, useTypeAheadCustomerList } from "../../../ReactData/customer";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import CompanyMapPolygon from "../../CompanyMapPolygon/CompanyMapPolygon";
import { MainSettingsProps } from "./types";


const MainSettings: React.FC<MainSettingsProps> = ({ system }) =>
{
    const details = useSystemDetails(system.id!);
    const modifySystem = useModifySystem();
    const modifySystemDetails = useModifySystemDetails();

    const customer = useCustomer(system.customerId || "");

    const { translate: t } = useL10n();

    async function saveSystem(data: System)
    {
        await modifySystem(system.id!, data);
    }
    async function saveSystemDetails(data: SystemDetails)
    {

        await modifySystemDetails(system.id!, data);//
    }

    return <>
        <SettingsCol >
            <SettingsCheckbox
                text={"[SID_MNU_TAB_STATUS]"}
                checked={system.status === SystemStatus.ONLINE}
                onChange={(checked) =>
                {
                    const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                    saveSystem({ status });
                }} />
            {/*<SettingsLabel
                text="Assigned client"
                tooltip={<TooltipIcon text={t("der Assigned client der Anlage")} />}
                value={systemState.customerId || ''} /> */}
            <CustomerTypeAhead
                currentId={customer.id || ""}
                onChange={(customerId => saveSystem({ customerId }))}
            />


            {/*  <SettingsLabel
                text="TD_SERIENNR"
                tooltip={<TooltipIcon text={t("der Serial number der Anlage")} />}
                value={details.serialNumber || ''} />
            <SettingsLabel
                text={"System ID"}
                tooltip={<TooltipIcon text={t("der System-ID der Anlage")} />}
                value={system.id || ""} /> */}
            <SettingsTextInput
                text={"System name"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={system.name || ''}
                onBlur={({ value }) =>
                {
                    if (system.name === value) return;
                    saveSystem({ "name": value });
                }
                } />
            <SettingsTextInput
                text={"Location"}
                endText={' 1'}
                tooltip={<TooltipIcon text={t("der Location der Anlage")} />}
                inputType={"text"}
                value={details.location || ''}
                onBlur={({ value }) =>
                {
                    if (details.location === value) return;
                    saveSystemDetails({ "location": value });
                }} />
            <SettingsTextInput
                text={"Site"}
                tooltip={<TooltipIcon text={t("der Site number der Anlage")} />}
                inputType={"text"}
                value={details.site || ''}
                onBlur={({ value }) =>
                {
                    if (details.site === value) return;
                    saveSystemDetails({ "site": value });
                }} />
            <SettingsTextInput
                text={"Address"}
                tooltip={<TooltipIcon text={t("der Address number der Anlage")} />}
                value={details.street || ''}
                onBlur={({ value }) =>
                {
                    if (details.street === value) return;
                    saveSystemDetails({ "street": value });
                }} />
            <SettingsTextInput
                text={"Postal code"}
                tooltip={<TooltipIcon text={t("der Postal code der Anlage")} />}
                inputType={"text"}
                value={details.zip || ''}
                onBlur={({ value }) =>
                {
                    if (details.zip === value) return;
                    saveSystemDetails({ "zip": value });
                }} />
            <SettingsTextInput
                text={"City"}
                tooltip={<TooltipIcon text={t("der City der Anlage")} />}
                inputType={"text"}
                value={details.city || ''}
                onBlur={({ value }) =>
                {
                    if (details.city === value) return;
                    saveSystemDetails({ "city": value });
                }
                } />
            <SettingsSelect
                text={"Country"}
                tooltip={<TooltipIcon text={t("der Country der Anlage")} />}
                value={details.country || ''}
                onBlur={({ value }) =>
                {
                    if (details.country === value) return;
                    saveSystemDetails({ "country": value });
                }}>
                <SettingsSelectOption text={"Österreich"} value={"austria"} />
                <SettingsSelectOption text={"Deutschland"} value={"germany"} />
                <SettingsSelectOption text={"Schweiz"} value={"schweiz"} />
                <SettingsSelectOption text={"Kroatien"} value={"kroatien"} />
                <SettingsSelectOption text={"Ungarn"} value={"ungaria"} />
                <SettingsSelectOption text={"Bosnien"} value={"bosnia"} />
                <SettingsSelectOption text={"Frankreich"} value={"france"} />
                <SettingsSelectOption text={"Niederlande"} value={"nederlands"} />
            </SettingsSelect>
        </SettingsCol>
        <SettingsCol lg={'6'} xl={'8'}>

            <SettingsCheckbox
                text={"Send location of system to devices"}
                tooltip={<TooltipIcon text={t("Send location of system to devices")} />}
                checked={details.exposeGPS || false}
                onChange={(exposeGPS) =>
                {
                    saveSystemDetails({ "exposeGPS": exposeGPS });
                }} />

            <CompanyMapPolygon
                area={details.GPSArea}
                position={details.GPSPosition}
                onChange={(changes) => saveSystemDetails(changes)}

            />
        </SettingsCol>
    </>;
};

export default MainSettings;