import L10n, { setTranslations, DefaultProvider } from '@ews/react-localization-context';

import de from './de';
import en from './en';

export { L10n };
export { DefaultProvider as L10nProvider };

export const Locales = {
    available: ['de', 'en'],
    default: 'de'
};

setTranslations({ en, de });

export const localeRouteParam = `:locale(${Locales.available.join('|')})`;