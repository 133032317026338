import { useIsMobile } from "../../../../../Responsive";
import SettingsCheckbox from "../../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { OptionCheckbox } from "./types";

const Checkbox: React.FC<OptionCheckbox> = ({
    text,
    disabled,
    checked,
    onChange
}) =>
{
    const mobileView = useIsMobile();
    return <SettingsCheckbox
        text={text}
        disabled={disabled}
        lines={mobileView ? "full" : undefined}
        item={mobileView}
        container={mobileView}
        label={mobileView}
        checked={checked}
        onChange={(value) => onChange(value)} />;
};


export default Checkbox;