import { useL10n } from "@ews/react-localization-context";
import { IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal, IonPopover } from "@ionic/react";
import React, { useRef, useState } from "react";
import { useIsMobile } from "../../../../../Responsive";
import { isValidDate } from "../../isValid";
import rangeStyle from "./DatePicker.module.scss";
import style from "../../SettingsRangeInput.module.scss";
import { DatePickerProps } from "./types";

const today = new Date();
today.setHours(0, 0, 0, 0);

const defaultFromDate = new Date(today.getTime() - 32262878401);//.toISOString();
const defaultToDate = new Date(today);//.toISOString();

defaultFromDate.setDate(today.getDate() - 5);
defaultToDate.setDate(today.getDate() + 1);

const formatter = new Intl.DateTimeFormat('sv-SE', { dateStyle: 'short', timeStyle: 'medium' });
const dateFormat = (date: Date) => formatter.format(date).replace(" ", "T");

const Popup = ({ children, mobileView }: { children: React.ReactNode, mobileView: boolean; }) => mobileView ?
    <IonModal keepContentsMounted style={{ "--backdrop-opacity": ".5" }} >{children}</IonModal> :
    <IonPopover keepContentsMounted >{children}</IonPopover>;

const DatePicker: React.FC<DatePickerProps> = ({
    value,
    onChange,
    onBlur,
    title,
    className,
    id,
    devider = true,
    position = "line",
    presentation = "date-time",
    childText
}) =>
{

    const { translate: t } = useL10n();
    const [rerender, setRerender] = useState<boolean>();

    function rerenderHandler()
    {
        setRerender(!rerender);
    }


    const range = useRef<{ from: Date, to: Date; }>({ from: new Date(value.from || defaultFromDate), to: new Date(value.to || defaultToDate) });
    const mobileView = useIsMobile();


    return <IonItem className={` ${className}`} id={id}>
        {title ? <div style={{ width: "100%" }}>
            <IonLabel style={{ width: "fit-content", margin: "0 auto" }} >{t(title)}</IonLabel>
        </div> : null
        }
        <div className={`${position === "line" ? style.line : style.row}`}>
            <section>
                {childText?.start ? <div >
                    <IonLabel className={`${rangeStyle.label}`}>{t(childText?.start)}</IonLabel>
                </div> : null}
                <IonDatetimeButton datetime="date-time-from" />
            </section>
            {devider ? <div className={`${rangeStyle.devider}`}>{" __ "}</div> : null}
            <section>

                {childText?.end ? <div >
                    <IonLabel className={`${rangeStyle.label}`}>{t(childText?.end)}</IonLabel>
                </div> : null}
                <IonDatetimeButton datetime="date-time-to" />
            </section>

        </div>

        <Popup
            mobileView={mobileView}
        >
            <IonDatetime
                id="date-time-from"
                value={dateFormat(range.current.from)}
                presentation={presentation}
                showDefaultButtons={true}
                cancelText={t("Cancel")}
                doneText={t("confirm")}
                max={dateFormat(range.current.to)}
                onIonChange={(e) =>
                {

                    const { detail, target } = e;
                    if (detail.value?.length) {

                        const from = typeof detail.value === "string" ? new Date(detail.value) : defaultFromDate;
                        let to = range.current.to || defaultToDate; //? to : new Date();

                        if (!isValidDate(from, to)) {
                            range.current = { ...range.current, to };
                            rerenderHandler();
                        }
                        else {

                            range.current = { from, to };
                        };
                        if (onChange) onChange({ from: from.toISOString(), to: to.toISOString() });
                    } else {

                        range.current = { ...range.current, from: defaultFromDate };
                        if (onChange) onChange({ from: range.current.from.toISOString(), to: range.current.to.toISOString() });

                    }
                    target.cancel(true);
                }}
                onIonBlur={(e) =>
                {
                    const { from, to } = range.current;
                    if (onBlur) onBlur({ from: from.toISOString(), to: to.toISOString() });
                }}
            />
        </Popup>


        <Popup
            mobileView={mobileView}
        >
            <IonDatetime
                id="date-time-to"
                value={dateFormat(range.current.to)}
                presentation={presentation}
                showDefaultButtons={true}
                min={dateFormat(range.current.from)}
                max={dateFormat(defaultToDate)}
                cancelText={t("Cancel")}
                doneText={t("confirm")}
                onIonChange={(e) =>
                {

                    const { detail, target } = e;

                    if (detail.value?.length) {

                        const to = typeof detail.value === "string" ? new Date(detail.value) : defaultToDate;
                        let from = range.current.from || defaultFromDate;


                        if (!isValidDate(from, to)) {
                            range.current.to = from;
                            rerenderHandler();
                        }
                        else {
                            range.current = { from, to };
                        }


                        if (onChange) onChange({ from: from.toISOString(), to: to.toISOString() });

                    } else {
                        range.current.to = defaultToDate;
                        if (onChange) onChange({ from: range.current.from.toISOString(), to: range.current.to.toISOString() });
                    }

                    target.cancel(true);
                }}

                onIonBlur={(e) =>
                {
                    const { from, to } = range.current;
                    if (onBlur) onBlur({ from: from.toISOString(), to: to.toISOString() });
                }}
            />

        </Popup>
    </IonItem >;
};

export default DatePicker;