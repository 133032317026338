import { IonLabel, IonTitle } from "@ionic/react";

import { useL10n } from "@ews/react-localization-context";
import { useIsMobile } from "../../../../../../../../Responsive";
import SettingsTableRow from "../../../../../../../../Settings/SettingsComponents/SettingsTable/SettingsRow/SettingsTableRow";
import SettingsTableColumn from "../../../../../../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsTableGrid from "../../../../../../../../Settings/SettingsComponents/SettingsTable/SettingsTableGrid/SettingsTableGrid";
import Item from "./Item/Item";
import { Props } from "./types";


const NotificationTable: React.FC<Props> = ({ disabled, title, notification, className, onCheck }) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    return <SettingsTableGrid>
        {title ? <SettingsTableRow hidden={mobileView}>
            <SettingsTableColumn size="12" center={true}>
                <IonTitle style={{ margin: 0 }}>{`${t(title)}`}</IonTitle>
            </SettingsTableColumn>
        </SettingsTableRow> : null}

        <SettingsTableRow hidden={mobileView}>
            <SettingsTableColumn size="4" center={false}>
                <p style={{ margin: 0 }}>{`${t('event')}:`}</p>
            </SettingsTableColumn>
            <SettingsTableColumn >
                <p style={{ textAlign: "center", margin: 0 }}>{`${t('Push')}:`}</p>
            </SettingsTableColumn>
            <SettingsTableColumn >
                <p style={{ textAlign: "center", margin: 0 }}>{`${t('E-mail')}:`}</p>
            </SettingsTableColumn>
        </SettingsTableRow>


        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_HK_FIRE_ALARMS]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.fireAlarm}
                className={className}
                notificationsKey={"fireAlarm"}
                onCheck={onCheck} />
        </SettingsTableRow>


        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_DEV_STATE_PREALARM]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.preAlarm}
                className={className}
                notificationsKey={"preAlarm"}
                onCheck={onCheck} />
        </SettingsTableRow>


        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_FIRE_PREALARM_SH]')}`}</IonLabel>
            </SettingsTableColumn>

            <Item
                disabled={disabled}
                value={notification.fireAlert}
                className={className}
                notificationsKey={"fireAlert"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_ONE_TEST_ALARM]')}`}</IonLabel>
            </SettingsTableColumn>

            <Item
                disabled={disabled}
                value={notification.testAlarm}
                className={className}
                notificationsKey={"testAlarm"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_ACT_OUTP]')}`}</IonLabel>
            </SettingsTableColumn>

            <Item
                disabled={disabled}
                value={notification.activeOutput}
                className={className}
                notificationsKey={"activeOutput"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_FAULTS]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.fault}
                className={className}
                notificationsKey={"fault"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_ALARM]')}`}</IonLabel>
            </SettingsTableColumn>

            <Item
                disabled={disabled}
                value={notification.technicalMessage}
                className={className}
                notificationsKey={"technicalMessage"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_SW_OFF_ZONE_SH]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.disablement}
                className={className}
                notificationsKey={"disablement"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_EXT_EV]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.extinguishingEvent}
                className={className}
                notificationsKey={"extinguishingEvent"}
                onCheck={onCheck} />
        </SettingsTableRow>

        <SettingsTableRow>
            <SettingsTableColumn size="4" center={false}>
                <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_STATUS]')}`}</IonLabel>
            </SettingsTableColumn>
            <Item
                disabled={disabled}
                value={notification.status}
                className={className}
                notificationsKey={"status"}
                onCheck={onCheck} />
        </SettingsTableRow>

    </SettingsTableGrid>;
};

export default NotificationTable;