import React, { useRef } from "react";
import style from './Melder.module.scss';
import { MelderProps } from "./types";


const Melder: React.FC<MelderProps> = ({
    onDrop,
    icon,
    text,
    element,
    top,
    left,
    className,
    width = `20px`,
    height = `20px`,
    transform,
    pointer,
    draggable
}) =>
{
    const melderRef = useRef<HTMLImageElement | null>(null);

    function drag(e: React.DragEvent<HTMLImageElement>)
    {
        if (onDrop) onDrop(element);
    }

    const position: any | undefined = top || left ? 'absolute' : undefined;
    const pos: React.CSSProperties = { position, top, left, transform, };// border

    return <div style={pos}>
        <div
            className={style.center}
        >
            <img
                draggable={draggable}
                ref={melderRef}
                className={`${className} ${style.img} ${pointer ? style.pointer : ''}`}
                style={{ height, width }}
                src={icon}
                alt=""
                onDrag={drag} />
        </div>

        {text ? <p className={style.text} style={{ fontSize: `${width}%` }}>{text}</p> : null}
    </div>;
};

export default Melder;