import { useL10n } from "@ews/react-localization-context";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsNumberInput.module.scss';
import { SettingsNumberInputProps } from "./types";
import { InputChangeEventDetail, InputInputEventDetail } from "@ionic/core";

const SettingsNumberInput: React.FC<SettingsNumberInputProps> = ({
    onChange,
    value,
    inputType = "number",
    hidden = false,
    labelPosition = 'stacked',
    text = '',
    tooltip,
    disabled,
    hasFocus,
    required,
    lines = undefined,
    debounce,
    onFocus,
    onBlur,
    id,
    inputRef
}) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const inputStyle = mobileView ? 'mobileInput' : 'webInput';
    const InputValue = useRef<number | null | undefined>(value);
    const innerInputRef = useRef<HTMLIonInputElement>(null);


    const handleFocusAfterReload = (ref: React.RefObject<HTMLIonInputElement>) =>
    {
        ref.current?.setFocus();
    };

    const _hasFocus = useRef<boolean>(false);

    const handleFocusOut = () =>
    {
        _hasFocus.current = false;
    };
    const handleFocusIn = () =>
    {
        _hasFocus.current = true;
    };

    const handleChange = (detail: InputInputEventDetail | InputChangeEventDetail) =>
    {
        const value = Number(detail.value);
        InputValue.current = value;
        if (onChange) onChange(value);
    };

    if (hasFocus) handleFocusAfterReload(inputRef || innerInputRef);

    return <IonItem hidden={hidden} lines={lines} className={`${style.overflow} ${globalStyle.setting} ${hasFocus ? style.hasFocus : ''}`}>
        <div className={style.container} style={{ width: "100%" }}>
            <div className={style.containerInput}>
                <span className={`${style.flex} ${style.justifyStart}`}>
                    <IonLabel style={{ whiteSpace: "break-spaces" }} position={labelPosition}>{t(text)}{required ? <span className={globalStyle.required}>*</span> : null}</IonLabel>
                    <span className={style.tooltip}>
                        {mobileView ? <></> : tooltip}
                    </span>
                </span>
                <IonInput
                    ref={inputRef || innerInputRef}
                    label=""
                    id={id}
                    debounce={debounce}
                    onIonFocus={() =>
                    {
                        handleFocusIn();
                        if (onFocus) onFocus();
                    }
                    }
                    onIonBlur={(e) =>
                    {

                        handleFocusOut();
                        if (onBlur) onBlur({ value: InputValue.current!, focus: _hasFocus.current, event: e });
                    }}
                    required={required}
                    disabled={disabled}
                    value={value || 0}
                    type={inputType}
                    className={`${style.inputAlign} ${globalStyle[inputStyle]} ${style.noArrows}`}
                    onIonInput={({ detail }) =>
                    {
                        if (debounce) handleChange(detail);
                    }}
                    onIonChange={({ detail }) =>
                    {
                        if (!debounce) handleChange(detail);


                    }} />
            </div>
        </div>
    </IonItem>;
};

export default SettingsNumberInput;