import { useL10n } from "@ews/react-localization-context";
import { IonContent, IonInput, IonLabel, IonSegmentButton } from "@ionic/react";
import React, { useRef, useState } from "react";
import { SwiperSlide, useSwiper } from "swiper/react";
import Overflow from "../../../Overflow/Overflow";
import { useIsMobile } from "../../../Responsive";
import SettingsSwiper from "../../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import SettingsGrid from "../../../Settings/SettingsGrid/SettingsGrid";
import SettingsSegment from "../../../Settings/SettingsSegment/SettingsSegment";
import ActuatedFireProtectionSystems from "../ActuatedFireProtectionSystems/ActuatedFireProtectionSystems";
import AlarmTransmission from "../AlarmTransmission/AlarmTransmission";
import Dates from "../Dates/Dates";
import DegreeProtection from "../DegreeProtection/DegreeProtection";
import DelayTime from "../DelayTime/DelayTime";
import ElectricalInstallerCompany from "../ElectricalInstallerCompany/ElectricalInstallerCompany";
import MonitoredObject from "../MonitoredObject/MonitoredObject";
import Remarks from "../Remarks/Remarks";
import System from "../System/System";
import SystemDocumentation from "../SystemDocumentation/SystemDocumentation";
import SystemInstallerCompany from "../SystemInstallerCompany/SystemInstallerCompany";
import TransmissionZones from "../TransmissionZones/TransmissionZones";
import UserSystem from "../UserSystem/UserSystem";
import { DisplayTabProps, DocumentationsSettingsOverlayProps } from "../types";
import style from './DocumentationsSettingsOverlay.module.scss';


const DisplayTab: React.FC<DisplayTabProps> = ({ name, display, children }) =>
{
    if (name === display) {
        return <>{children}</>;
    }
    return <></>;
};

const DocumentationsSettingsOverlay: React.FC<DocumentationsSettingsOverlayProps> = ({ system, onClose }) =>
{
    type Page = {
        value: string;
        title: string;
        child: React.ReactNode;
    };

    const pageList: Page[] = [
        {
            value: 'monitoredObject',
            title: "Monitored object",
            child: <MonitoredObject system={system} />
        },
        {
            value: 'userSystem',
            title: "User of system",
            child: <UserSystem system={system} />
        },
        {
            value: 'systemInstallerCompany',
            title: "System installer company",
            child: <SystemInstallerCompany system={system} />
        },
        {
            value: 'electricalInstallerCompany',
            title: "Electrical installer company",
            child: <ElectricalInstallerCompany system={system} />
        },
        {
            value: 'system',
            title: "System",
            child: <System system={system} />
        },
        {
            value: 'degreeOfProtection',
            title: "Degree of protection",
            child: <DegreeProtection system={system} />
        },
        {
            value: 'transmissionZones',
            title: "Transmission Zones",
            child: <TransmissionZones system={system} />
        },
        {
            value: 'alarmTransmission',
            title: "Alarm transmission",
            child: <AlarmTransmission system={system} />
        },
        {
            value: 'dates',
            title: "Dates",
            child: <Dates system={system} />
        },
        {
            value: 'systemdocumentation',
            title: "System documentation",
            child: <SystemDocumentation system={system} />
        },
        {
            value: 'delayTime',
            title: "Delay time",
            child: <DelayTime system={system} />
        },
        {
            value: 'actuatedFireProtectionSystems',
            title: "Actuated fire protection Systems",
            child: <ActuatedFireProtectionSystems system={system} />
        },
        {
            value: 'remarks',
            title: "Remarks",
            child: <Remarks system={system} />
        },

    ];
    const mobileView = useIsMobile();
    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;
    const [currentSegment, setCurrentSegment] = useState<string>(pageList[0].value);
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const { translate: t } = useL10n();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={() => swiper.slideTo(slideToRef.current)}
            hidden />;
    };

    return <>
        <IonContent id="documentationSettingsContent" className={style.overlaySettings} fullscreen>

            <SettingsSegment value={currentSegment} onSelect={(current: string) => setCurrentSegment(current)}>
                {pageList.map((segment, index) => (

                    <IonSegmentButton key={segment.value} value={segment.value} onClick={() =>
                    {
                        if (mobileView) {
                            slideToRef.current = index;
                            SwiperRef.current.click();
                        }
                    }}>
                        <IonLabel>{t(segment.title)}</IonLabel>
                    </IonSegmentButton>
                ))}
            </SettingsSegment>


            <SettingsSwiper onSlideChange={(index) =>
            {
                slideToRef.current = index;
                setCurrentSegment(pageList[index].value);

            }}>
                {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}

                {pageList.map((page, index) =>
                {
                    const { child, value } = page;
                    return <SliderComponent key={index}>
                        <DisplayTab name={value} display={currentSegment}>
                            <Overflow>
                                <SettingsGrid height={{ min: "80vh", max: "87vh" }}>
                                    {child}
                                </SettingsGrid>
                            </Overflow>
                        </DisplayTab>
                    </SliderComponent>;
                })}

            </SettingsSwiper>

        </IonContent>
    </>;

};

export default DocumentationsSettingsOverlay;