import { useL10n } from "@ews/react-localization-context";


import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import { SystemInstallerCompanyProps } from "./types";

const SystemInstallerCompany: React.FC<SystemInstallerCompanyProps> = ({ system }) =>
{
    const { translate: t } = useL10n();
    /* 
    const details = useSystemDetails(system.id!);

    const [systemState, setSystemState] = useState<System>({});
    const [systemDetailsState, setSystemDetailState] = useState<SystemDetails>({});

    const modifySystem = useModifySystem();
    const modifySystemDetails = useModifySystemDetails();


    function saveSystem(key: keyof System, value: any,)
    {
        //if (!system.id || !system[key]) return;

        system[key] = value;
        setSystemState(system);
        modifySystem(system.id!, { [key]: value });
    }
    function saveSystemDetails(key: keyof SystemDetails, value: any)
    {
        //if (!system.id || !(key in details)) return;
        details[key] = value;
        modifySystemDetails(system.id!, { [key]: value });//
    }
    useEffect(() =>
    {
        if (!systemState.id && system.id) {
            setSystemState(system);
        }
        if (!systemDetailsState.systemId && details.systemId) {
            setSystemDetailState(details);
        }
    }, [system, details]); */
    return <>
        <SettingsCol >

            <SettingsTextInput
                text={"Company"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"Address"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"Postalcode"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsTextInput
                text={"City"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
            <SettingsSelect
                text={"Country"}
                tooltip={<TooltipIcon text={t("der Country der Anlage")} />}
                value={"austria"}
                onBlur={({ value }) =>
                {

                    /* setSystemDetailState({ ...systemDetailsState, country: value });
                    saveSystemDetails("country", value); */
                }}>
                <SettingsSelectOption text={"Österreich"} value={"austria"} />
                <SettingsSelectOption text={"Deutschland"} value={"germany"} />
                <SettingsSelectOption text={"Schweiz"} value={"schweiz"} />
                <SettingsSelectOption text={"Kroatien"} value={"kroatien"} />
                <SettingsSelectOption text={"Ungarn"} value={"ungaria"} />
                <SettingsSelectOption text={"Bosnien"} value={"bosnia"} />
                <SettingsSelectOption text={"Frankreich"} value={"france"} />
                <SettingsSelectOption text={"Niederlande"} value={"nederlands"} />
            </SettingsSelect>

            <SettingsTextInput
                text={"Person in charge"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />

            <SettingsTextInput
                text={"Tel.no."}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"tel"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />


            <SettingsTextInput
                text={"E-mail"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"email"}
                value={''}
                onBlur={({ value }) =>
                {

                    /* setSystemState({ ...systemState, name: value });
                    saveSystem("name", value); */
                }
                } />
        </SettingsCol>
        <SettingsCol lg={'6'} xl={'8'}>

        </SettingsCol>
    </>;
};

export default SystemInstallerCompany;