import { RTMCategory } from '@ews/zlt-events';
import Events = RTMCategory;

export { Events };

// export enum Events
// {
//     None = 0,
//     FireAlarm = 1,
//     FireAlarmManual = 2,
//     FireAlarmAutomatic = 4,
//     FireAlert = 8,
//     PreAlarm = 16,
//     Test = 32,
//     TestCondition = 64,
//     TestActivation = 128,
//     TestAlarm = 256,
//     ActiveOutput = 512,
//     Fault = 1024,
//     FaultGroup = 2048,
//     FaultActivation = 4096,
//     FaultAlarmDevice = 8192,
//     FaultTransmissionDevice = 16384,
//     TechnicalMessage = 32768,
//     Disablement = 65536
// }

export type EventType = keyof typeof Events;

export const EventPriority: EventType[] = [
    "Alarm",
    "ActiveOutput",
    //"TechnicalMessage",
    "Disablement",
    //"TestCondition"
    "Fault",
];

export const RTMFieldOrder: EventType[] = [
    "Alarm", "TestCondition", "Fault", "TechnicalMessage", "Disablement", "ActiveOutput"
];

export type EventItemType = {
    group: string;
    title: string;
    time: string;
};

export type EventObject = {
    title: string;
    type: EventType;
    list: EventItemType[];
};