import { IonHeader, IonIcon, IonItem, IonTitle } from "@ionic/react";
import { close } from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import { useNamedRoutes } from "../NamedRoutes";
import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({ title, backbutton = false }) =>
{
    const history = useHistory();
    const { generate } = useNamedRoutes();
    const goBack = () => history.goBack();
    
    return <IonHeader>
        <IonItem color={'primary'}>
            <IonTitle>{title}</IonTitle>

            {backbutton ?
                <IonIcon
                    icon={close}
                    color="danger"
                    size="large"
                    onClick={goBack} />
                :
                <Link to={generate("systems")}><IonIcon color="danger" size="large" icon={close} /></Link>
            }
        </IonItem>
    </IonHeader>;
};

export default Header;