import { IonCol, IonItemOption, IonItemOptions, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { EventPriority, EventType, Events } from "../Event/types";
import FilterList from "../FilterList/FilterList";
import { EventCategoryIcon } from '../Icons';

import
{
    SystemStatusIcon,
    SystemTypeIcon
} from '../Icons';

import List from "../List/List";
import Sidebar from "../Navigation/Sidebar/System";
import { useIsMobile } from "../Responsive";

import { useL10n } from "@ews/react-localization-context";
import { System, SystemStatus, SystemType } from "@ews/websocket-service";
import { RTMCategory } from "@ews/zlt-events";
import Checkbox from "../Checkbox/Wrapper";
import { useMySystems, useTypeAheadSystemList } from "../ReactData/system";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { Filter } from "./Reports/types/reportTypes";
import systemStyle from './SystemList.module.scss';

const eventKindTypeList: number[] = [1, 2, 4, 16];
const sumEventKindType = [...eventKindTypeList].reduce((total, current) => total | current);

type ListFilter = {
    name?: string;
    systemType?: string;
    customerId?: string;
    systemNumber?: string;
    eventStatus?: number;
    status?: string;
};

const standardFilter: Filter<ListFilter> = {
    sortCriteria: "name",
    sortOrder: "ASC"
};

const SystemList: React.FC<{ customerId?: string; }> = ({ customerId }) =>
{
    const { translate: t } = useL10n();
    const filterDebounceTime = 500;

    const mobileView = useIsMobile();
    const hidefilterDetails = false;

    type SystemStatus_ = keyof typeof SystemStatus;
    type SystemType_ = keyof typeof SystemType;

    const systemStatusAvailable: number[] = Object.keys(SystemStatus).filter(status => isNaN(Number(status))) as unknown[] as number[];
    const systemTypeAvailable: number[] = Object.values(SystemType);

    const [selectedStatus, setSelectedStatus] = useState<SystemStatus_[]>(systemStatusAvailable);
    const [selectedType, setSelectedType] = useState<SystemType_[]>(systemTypeAvailable as SystemType_[]);
    const [currentState, setState] = useState<Filter<ListFilter>>(standardFilter);

    if (customerId) {
        currentState.customerId = customerId;
    }

    const result = useMySystems(currentState);

    const typeahead = useTypeAheadSystemList();

    const handleSelectEvent = (eventKindtype: number, checked: boolean) =>
    {
        const selectedEvent = currentState.eventStatus ? currentState.eventStatus : 0b00;
        const currentFilter = { ...currentState };

        eventKindtype = checked ? eventKindtype | selectedEvent : eventKindtype ^ selectedEvent;

        if (eventKindtype === 0) delete currentFilter!.eventStatus;
        else currentFilter.eventStatus = eventKindtype;

        setState({ ...currentFilter });
    };

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>


        <FilterList
            //isDetailsHidden={hidefilterDetails}
            //onDetailOpen={setHideFilterDetails}
            isDetailsHidden={hidefilterDetails}
            onReset={() => setState(standardFilter)
            }
            details={<>
                <IonCol>
                    <div>
                        <IonLabel>{t("Type")}</IonLabel>
                        <div
                            className={systemStyle.filterTypesContainer}
                        >
                            {systemTypeAvailable.map((currentType, index) =>
                            {
                                return <div key={currentType + index} className={systemStyle.iconContainer}>

                                    <Checkbox
                                        checked={selectedType.indexOf(currentType as any) !== -1 ? true : false}

                                        position={
                                            {
                                                top: -10,
                                                right: -10
                                            }

                                        }
                                        onClick={(checked) =>
                                        {
                                            let type = selectedType;

                                            const typeKey = currentType as SystemType_;

                                            if (checked && type.indexOf(typeKey) === -1) {
                                                type.push(typeKey);
                                            } else {
                                                type = type.filter(t => t !== typeKey);
                                            }

                                            setSelectedType(type);

                                            const currentFilter = { ...currentState };

                                            if (type.length) {
                                                currentFilter.systemType = type.join(",");
                                            } else {
                                                delete currentFilter.systemType;
                                            }

                                            setState(currentFilter);
                                        }}>

                                        <SystemTypeIcon type={currentType}></SystemTypeIcon>
                                        <IonLabel>{currentType}</IonLabel>
                                    </Checkbox>

                                </div>;
                            })}
                        </div>
                    </div>
                </IonCol>
                <IonCol>
                    <div>
                        <IonLabel>{t("Status")}</IonLabel>
                        <div
                            className={systemStyle.filterStatusContainer}
                        >
                            {systemStatusAvailable.map((currentStatus, index) =>
                            {
                                return <div key={currentStatus + index} className={systemStyle.iconContainer}>
                                    <Checkbox
                                        position={{
                                            top: -10,
                                            right: -10
                                        }}
                                        checked={selectedStatus.indexOf(currentStatus as SystemStatus_) !== -1 ? true : false}
                                        onClick={(checked) =>
                                        {
                                            let status = selectedStatus;

                                            const statusKey = currentStatus as SystemStatus_;

                                            if (checked && status.indexOf(statusKey) === -1) {
                                                status.push(statusKey);
                                            } else {
                                                status = status.filter(st => st !== statusKey);
                                            }

                                            setSelectedStatus(status);
                                            const currentFilter = { ...currentState };

                                            if (status.length) {
                                                currentFilter.status = status.join(",");
                                            } else {
                                                delete currentFilter.status;
                                            }

                                            setState(currentFilter);
                                        }}>
                                        <SystemStatusIcon className={systemStyle.iconSize} status={SystemStatus[currentStatus]} />
                                    </Checkbox>
                                </div>;
                            })}
                        </div>
                    </div>

                </IonCol>
                <IonCol>
                    <div>
                        <IonLabel>{t("Event")}</IonLabel>
                        <div
                            className={systemStyle.filterStatusContainer}
                        >
                            {eventKindTypeList.map((eventKindType, index) =>
                            {
                                let isChecked = false;
                                if (eventKindType !== sumEventKindType && currentState.eventStatus) {
                                    let byte = eventKindType & currentState.eventStatus;
                                    isChecked = Boolean(byte);
                                }
                                else {
                                    isChecked = currentState.eventStatus === sumEventKindType;
                                }

                                return <div key={eventKindType + index} className={systemStyle.iconContainer}>
                                    <Checkbox
                                        position={{
                                            top: -10,
                                            right: -10
                                        }}
                                        checked={isChecked}
                                        onClick={(checked) => handleSelectEvent(eventKindType, checked)}
                                    >
                                        <EventCategoryIcon className={systemStyle.iconSize} key={`event-${eventKindType}`} category={`${RTMCategory[eventKindType]}` as EventType} />
                                    </Checkbox>

                                </div>;
                            })}
                        </div>
                    </div>
                </IonCol>
            </>} >

            <IonCol>
                <SettingsTextInput
                    id="systemName"
                    text="Name"
                    debounce={filterDebounceTime}
                    value={currentState.name}

                    onChange={(data) =>
                    {
                        const currentFilter = { ...currentState };

                        if (data.length) currentFilter.name = data;
                        else delete currentFilter.name;

                        setState(currentFilter);
                    }}
                />
            </IonCol>
            <IonCol>
                <SettingsTextInput
                    debounce={filterDebounceTime}
                    text={"Customer"}
                    value={currentState.customerId}
                    onChange={(customerId) =>
                    {
                        const currentFilter = { ...currentState };

                        if (customerId.length) currentFilter.customerId = customerId;
                        else delete currentFilter.customerId;

                        setState(currentFilter);
                    }}
                />
            </IonCol>
            <IonCol>

                <SettingsTextInput
                    debounce={filterDebounceTime}
                    text={"Number"}
                    value={currentState.systemNumber}
                    onChange={(systemNumber) =>
                    {
                        const currentFilter = { ...currentState };

                        //if (currentFilter.page) delete currentFilter.page;
                        if (systemNumber.length) currentFilter.systemNumber = systemNumber;
                        else delete currentFilter.systemNumber;

                        setState(currentFilter);
                    }} />
            </IonCol><IonCol>
            </IonCol>
        </FilterList >

        < List
            data-testid="list"

            rows={result.results}
            sort={currentState.sortCriteria ? {
                key: currentState.sortCriteria,
                direction: currentState.sortOrder || "ASC"
            } : undefined}

            pageSize={result.pageSize}
            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
            onPageChange={(page) => setState({ ...currentState, page })}
            onPageSize={(pageSize) => setState({ ...currentState, pageSize })}
            onReload={() => setState({ ...currentState })}
            currentPage={result.currentPage}
            numberOfPages={result.totalCountOfPages}
            /* maxHeight={mobileView ? "86vh" : "45vh"} */
            link={{ url: "system:events", paramsKey: "id" }}
            onMenu={(id: string, onMenuToggle: () => void) => <Sidebar onMenuToggle={onMenuToggle} routeParams={{ id }} />}
            sliderChildren={
                mobileView ?
                    <IonItemOptions side="start">
                        <IonItemOption color="success">{t("aktivieren")}</IonItemOption>
                    </IonItemOptions>
                    :
                    null
            }
            headers={
                [
                    {
                        title: '[SID_MNU_TAB_INFO_SCR]',//'TD_ZENTRALENNAME',
                        key: 'name',
                        both: true,
                    },
                    // {
                    //     title: 'Alias',
                    //     key: 'alias',
                    //     breakpoint: 1600, up: true,
                    // },
                    {
                        title: 'Zones',
                        key: 'inputDevices',
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: 'Actuations',
                        key: 'outputDevices',
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: 'CustomerNumber',
                        key: 'customerNumber',
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: 'customerName',
                        key: 'customerName',
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: '[SID_SYSTEM_KEY]',
                        key: 'systemKey',
                        option: { key: 'systemType', chooiceList: [['BC08', <SystemTypeIcon type={SystemType.BC08} />], ['BC600', <SystemTypeIcon type={SystemType.BC600} />], ['BC216', <SystemTypeIcon type={SystemType.BC216} />]] },
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: 'Event',
                        key: 'event',
                        both: true,
                        child: (system: System) => <div className={!mobileView ? systemStyle.eventIcons : ""}>
                            <SystemStatusIcon status={system.status!} />
                            {EventPriority.map((e) =>
                            {
                                return (system.eventStatus! & Events[e]) ?
                                    <EventCategoryIcon key={`event-${e}`} category={e} />
                                    :
                                    <React.Fragment key={`event-fragment-${e}`} />;
                            })}
                        </div>,
                    },
                ]}
        />
    </div>;
};

export default SystemList;
