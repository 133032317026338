import { useL10n } from '@ews/react-localization-context';
import L from 'leaflet';

export default function Language() 
{

    const { translate: t } = useL10n();

    L.drawLocal.draw.handlers.polygon = {
        tooltip: {
            start: t(""),
            cont: t(""),
            end: t("")
        }
    };

    L.drawLocal.draw.toolbar = {
        actions: {
            title: t(''),
            text: t('abbrechen')
        },
        finish: {
            title: t(''),
            text: t('fertig')
        },
        undo: {
            title: t("letzten punkt löschen"),
            text: t("schritt zurück")
        },
        buttons:
        {
            polyline: t(""),
            polygon: t(""),
            rectangle: t(""),
            circle: t(""),
            marker: t(""),
            circlemarker: t(""),
        }
    };

    L.drawLocal.edit = {

        handlers: {
            edit: {
                tooltip: {
                    subtext: t(""),
                    text: t("")
                }
            },
            remove: {
                tooltip: {
                    text: t(""),
                }
            }
        },
        toolbar: {
            buttons: {
                edit: t(""),
                editDisabled: t(""),
                remove: t(""),
                removeDisabled: t("")
            },

            actions: {
                cancel: {
                    text: t("abbrechen"),
                    title: t("")
                },
                clearAll: {
                    text: t("löschen"),
                    title: t("")
                },
                save: {
                    text: t("speichern"),
                    title: t("")
                }
            },

        }
    };
    return null;
};
