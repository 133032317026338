import { menuController } from "@ionic/core/components";
import { IonContent, IonPopover, IonMenu, IonHeader, IonFooter } from "@ionic/react";
import
{
    PropsWithChildren,
    useRef,
    useEffect,
    FC
} from "react";

import { Responsive } from "../Responsive";
import { MenuToggleProps } from "./types";

export type NavigationProps = PropsWithChildren<{
    navbar: FC<MenuToggleProps>,
    sidebar: FC<MenuToggleProps>,
    statusbar?: FC;
    id: string;
}>;

const enableMenu = (element: HTMLIonMenuElement | null) =>
{
    return menuController.enable(true, element?.menuId);
};

const Wrapper: React.FC<NavigationProps> = ({ children, navbar: Navbar, sidebar: Sidebar, statusbar: Statusbar, id }) =>
{

    const menuRef = useRef<HTMLIonMenuElement>(null);
    const popoverRef = useRef<HTMLIonPopoverElement>(null);

    useEffect(() =>
    {
        function handleResize()
        {
            popoverRef.current?.dismiss();
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });


    return (
        <>
            <Responsive desktop>
                <Navbar
                    onMenuToggle={async (e) => await popoverRef.current?.present(e.nativeEvent)}
                />

                <IonPopover ref={popoverRef}>
                    <Sidebar
                        onMenuToggle={async () => await popoverRef.current?.dismiss()}
                    />
                </IonPopover>

            </Responsive>

            {Statusbar && (<Responsive mobile>
                <IonHeader>
                    <Statusbar />
                </IonHeader>
            </Responsive>)}

            <IonContent id={`${id}Content`} class="ionPadding">
                {children}
            </IonContent>

            {Statusbar && (<Responsive desktop>
                <IonFooter>
                    <Statusbar />
                </IonFooter>
            </Responsive>)}

            <Responsive mobile>
                <Navbar
                    onMenuToggle={async () =>
                    {
                        (await enableMenu(menuRef.current))?.open(true);
                    }}
                />

                <IonMenu
                    swipeGesture={false}
                    ref={menuRef}
                    side="end"
                    disabled={false}
                    contentId={`${id}Content`}
                    menuId={`${id}Menu`}
                >
                    <Sidebar
                        onMenuToggle={async () => await menuRef.current?.close()} />
                </IonMenu>
            </Responsive>
        </>
    );
};

export default Wrapper;