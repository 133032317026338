import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderItemProps, ListLink, ListProps, PositionSide, ResponsiveChooice, RowProps } from "./types";

import LongPress from "../LongPress";
import listStyle from './List.module.scss';

import { useL10n } from "@ews/react-localization-context";
import { Responsive, useIsMobile } from "../Responsive";

import { IonIcon, IonItem, IonModal, IonPopover } from "@ionic/react";

import { caretDown, caretUp, ellipsisVertical } from "ionicons/icons";
import { useNamedRoutes } from "../NamedRoutes";
import ItemContainerSlider from "../Settings/SettingsComponents/SettingsItemSlider/ItemContainerSlider/ItemContainerSlider";
import { ClickEvent, ReactClickEvent } from "../types";
import Pagination from "./Pagination/Pagination";


function maxScreenHeight(height: number, y: number, side: PositionSide, margin: number = 10): number
{
    return (side === "bottom" ? (height - y) : y) - margin;
}
function pxToPercent(childSize: number, parentSize: number): number
{
    return Math.round((childSize / parentSize) * 100);
}


const NoContent = () =>
{
    const { translate: t } = useL10n();

    return <IonItem data-testid="no-content" className={listStyle.noContent} lines={'full'}><div>{t('No Content')}</div></IonItem>;
};

const optionChooiceList = (chooiceList: any[][], data: string) =>
{

    if (!chooiceList.length) return new Error('the chooiceList is empty');

    const chooice = chooiceList.filter((chooiceItem) => chooiceItem[0] === data);

    return chooice.length ? chooice[0][1] : [];
};


const ResponsivContainer = (chooice: 'desktop' | 'mobile' | 'breakpoint' | 'both', breakpoint?: number | "xsmall" | "small" | "medium" | "large" | "xlarge") =>
{
    if (chooice === 'desktop') return ({ children }: { children: ReactNode; }) => <Responsive desktop>{children}</Responsive>;
    else if (chooice === 'mobile') return ({ children }: { children: ReactNode; }) => <Responsive mobile>{children}</Responsive>;
    else if (chooice === 'breakpoint') return ({ children }: { children: ReactNode; }) => <Responsive breakpoint={breakpoint || 0} up>{children}</Responsive>;
    else return ({ children }: { children: ReactNode; }) => <>{children}</>;

};


const Header: React.FC<HeaderItemProps> = ({ item, onClick, icon, className }) =>
{
    const { translate: t } = useL10n();

    let chooice: ResponsiveChooice = 'desktop';

    if (item.breakpoint) chooice = 'breakpoint';
    else if (item.desktop) chooice = 'desktop';
    else if (item.mobile) chooice = 'mobile';
    else chooice = 'both';

    const Container = ResponsivContainer(chooice, item.breakpoint || 0);
    return <Container>
        <div className={`${className} ${listStyle.col} ${!item?.option?.noSort ? listStyle.pointer : ''}`}
            onClick={() =>
            {
                if (onClick) onClick(item.key);
            }}
        >
            {t(item.title)}
            {icon ? <IonIcon icon={icon}></IonIcon> : null}
        </div>

    </Container>;

};

const Col: React.FC<RowProps> = ({ responsive, chooice, breakpoint, value, link, nextLine = false, className }) =>
{
    const { generate } = useNamedRoutes();

    const linkRef = useRef<HTMLAnchorElement>(null);

    const Container = ResponsivContainer(responsive, breakpoint);

    const route = link ? generate(link, { id: value }) : "";

    const Value: React.FC = () => <>
        {chooice}
        <span
            data-testid="list-colum"
            className={`${chooice ? listStyle.rowTextCenterWithMarginAround : listStyle.rowTextCenter}`}
        >
            {value}
        </span>
    </>;


    return <Container>


        <div className={` ${className} ${listStyle.col} ${nextLine ? listStyle.flexNextLine : ''}`}>
            {link ? <Link
                style={{ color: "blue" }}
                ref={linkRef}
                className={listStyle.link}
                to={route}
            >
                <Value />
            </Link> : <Value />}
        </div>

    </Container>;
};

const List: React.FC<ListProps> = ({
    headers,
    rows,
    sliderChildren,
    sort,
    link,
    maxHeight,
    style,
    pageSize,
    currentPage,
    numberOfPages,
    totalCountOfItems,
    onMenu,
    onPageChange,
    onPageSize,
    onReload,
    onSort,
    noLink = false
}) =>
{
    const mobileView = useIsMobile();

    if (!maxHeight) maxHeight = mobileView ? "86vh" : "47vh";

    const [currentRows, setCurrentRows] = useState<Array<any>>([]);
    const { generate } = useNamedRoutes();

    const linkRef = useRef<HTMLAnchorElement>(null);

    const ContextMenuElement = mobileView ? IonModal : IonPopover;

    const [ContextMenu, setContextMenu] = useState<{ contextMenu?: ReactNode, event?: ClickEvent; }>({});
    const contextDirection = useRef<PositionSide>("bottom");
    const contextMaxHeight = useRef<number | null>(1050);

    const presentOptions = (id: string, event?: ReactClickEvent) =>
    {
        if (!onMenu) return;
        const contextMenu = onMenu(id, dismissOptions);
        if (event?.cancelable) event?.preventDefault();
        setContextMenu({ contextMenu, event: event?.nativeEvent });

        const { clientY, view } = event!.nativeEvent as PointerEvent;
        const screenHeight = view?.innerHeight || 0;

        const heightPercent = pxToPercent(clientY, screenHeight);
        const side = heightPercent >= 70 ? "top" : "bottom";

        contextDirection.current = side as PositionSide;
        contextMaxHeight.current = maxScreenHeight(screenHeight, clientY, side as PositionSide);
    };
    const dismissOptions = () =>
    {
        setContextMenu({});
    };

    useEffect(() =>
    {
        if (rows === null) return setCurrentRows([]);
        setCurrentRows(rows);

    },
        [rows]
    );


    const ListLink: React.FC<ListLink> = ({ style, listref, className, to, children }) => !noLink ?
        <Link
            data-testid="list-link"
            style={style}
            ref={listref}
            className={className}
            to={to!}
        >{children}</Link>
        :
        <>{children}</>;

    return (
        <div style={style} className={`${listStyle.list}`}>

            <ContextMenuElement
                reference="event"
                side={contextDirection.current}
                alignment="end"
                event={ContextMenu.event}
                initialBreakpoint={mobileView ? .6 : undefined}
                className={mobileView ? listStyle.menu : ''}
                isOpen={Boolean(ContextMenu.contextMenu)}
                onWillPresent={(e) =>
                {
                    const { children } = e.target;
                    try {

                        if (children[0].children[1].nodeName.toLowerCase() === "ion-content") (children[0].children[1] as HTMLIonContentElement).style.height = `${contextMaxHeight.current! - 91}px`;
                    } catch (e) { }
                }}
                onDidDismiss={dismissOptions}
                keepContentsMounted={true}
                style={contextDirection.current === "top" ? { "--offset-y": `-${contextMaxHeight.current}px` } : {}}
            //style={{ alignItems: "start", "--max-height": `${contextMaxHeight.current}px`, }}
            >
                {ContextMenu.contextMenu}
            </ContextMenuElement>

            <Responsive desktop>
                <div className={`${listStyle.row} ${listStyle.header}`}>
                    {!mobileView && onMenu ? <div className={`${listStyle.grow} ${listStyle.col} ${listStyle.settingsIcon} ${listStyle.begin}`}></div> : null}
                    {headers.map((head, index) =>
                    {
                        return head.option && head.option?.noSort ?
                            <Header className={head.className} key={index} item={head}></Header>
                            :
                            <Header className={head.className} key={index} onClick={(key) =>
                            {
                                if (onSort) {
                                    const direction = sort?.key === head.key ? sort.direction === "ASC" ? "DESC" : "ASC" : "ASC";
                                    onSort(key, direction);
                                };
                            }
                            }
                                item={head}
                                icon={sort?.key === head.key && sort.direction ? sort.direction === "ASC" ? caretDown : caretUp : undefined}
                            ></Header>;
                    })}

                </div>
            </Responsive>
            <section className={listStyle.rowContainer} >
                {

                    !currentRows || !currentRows.length ? <NoContent /> :
                        currentRows.map((row: any, index: number) =>
                        {
                            if (!Object.keys(row).length) {
                                if (currentRows.length === index + 1) return <NoContent key={index} />;
                                return null;
                            };
                            const Container = sliderChildren ? IonItem : ({ children }: { children: ReactNode; }) => <React.Fragment>{children}</React.Fragment>;

                            const evenOdd = (index) % 2 ? 'even' : 'odd';
                            return (
                                <ItemContainerSlider key={`slider-${index}-${row.id}`} >

                                    {sliderChildren}

                                    <Container data-testid="list-row" lines="none" className={listStyle.container}>
                                        <section className={`${mobileView ? listStyle.mobile : ''} ${row.link ? listStyle.pointer : ''}`}
                                        >
                                            <ListLink
                                                listref={linkRef}
                                                className={listStyle.link}
                                                key={index}
                                                to={link ? generate(link.url, { [link.paramsKey!]: row[link.paramsKey!] }) : row.link || ''}
                                            >
                                                <div
                                                    className={`${listStyle.row} ${listStyle[evenOdd]} ${mobileView ? listStyle.pullUp : ''}`}

                                                    {...LongPress((e) => presentOptions(row.id, e))}
                                                    onContextMenu={(e) => presentOptions(row.id, e)}
                                                >
                                                    {!mobileView && onMenu ? <div
                                                        className={`${listStyle.col} ${listStyle.settingsIcon} ${listStyle.begin}`}
                                                        onClick={(e) => presentOptions(row.id, e)}
                                                    >
                                                        <IonIcon
                                                            icon={ellipsisVertical}
                                                        />
                                                    </div> : null}

                                                    {headers.map((head, index) =>
                                                    {
                                                        let value: any = row[head.key];

                                                        let responsive: ResponsiveChooice = 'desktop';

                                                        if (head.breakpoint) responsive = 'breakpoint';
                                                        else if (head.desktop) responsive = 'desktop';
                                                        else if (head.mobile) responsive = 'mobile';
                                                        else responsive = 'both';

                                                        let chooice: any = undefined;
                                                        if (head.option && head.option.chooiceList) {

                                                            const data = head.option.key ? row[head.option.key] : value;
                                                            const option = optionChooiceList(head.option.chooiceList, data);
                                                            if (!(option instanceof Error)) chooice = option;
                                                        };



                                                        if (head.child) {
                                                            return <Col
                                                                className={head.className || ""}
                                                                key={`item-${index}`}
                                                                value={head.child(row)}
                                                                link={head?.option?.link}
                                                                responsive={responsive}
                                                                chooice={chooice}
                                                                nextLine={head?.option?.nextLine}
                                                                breakpoint={head.breakpoint || 0}
                                                            />;

                                                        };

                                                        /* ------------------------------------------------
                                                                        Error handling 
                                                        */
                                                        if (!row[head.key]) {
                                                            value = '';
                                                        };
                                                        /* ------------------------------------------------*/
                                                        if (value.type && value.type === 'function') return value;

                                                        return <Col
                                                            key={`${value}-${index}`}
                                                            value={value}
                                                            link={head?.option?.link}
                                                            responsive={responsive}
                                                            chooice={chooice}
                                                            nextLine={head?.option?.nextLine}
                                                            breakpoint={head.breakpoint || 0}
                                                        />;
                                                    })}
                                                    {mobileView && onMenu ? <div
                                                        className={`${listStyle.col} ${listStyle.settingsIcon} ${listStyle.end}`}>
                                                        <IonIcon
                                                            icon={ellipsisVertical}
                                                            onClick={(e) => presentOptions(row.id, e)} />
                                                    </div> : null}
                                                </div>
                                            </ListLink>
                                        </section>
                                    </Container>
                                </ItemContainerSlider>
                            );
                        })
                }
            </section>
            <Responsive desktop>
                <Pagination
                    pageSize={pageSize}
                    currentPage={currentPage}
                    numberOfPages={numberOfPages}
                    totalCountOfItems={totalCountOfItems}
                    onPageChange={(value) => { if (onPageChange) onPageChange(value); }}
                    onPageSize={(value) =>
                    {
                        //console.log("List onPageSize", value);
                        if (onPageSize) onPageSize(value);
                    }}
                    onReload={() => { if (onReload) onReload(); }}
                />
            </Responsive>
        </div >
    );
};

export default List;
