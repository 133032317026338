import React, { FC } from "react";

import UserList from "../components/User/UserList";

import SettingsOverlay from '../components/User/UserSettings/SettingsOvererlay/SettingsOverlay';

import { useL10n } from "@ews/react-localization-context";
import { addCircleSharp } from "ionicons/icons";
import Layout from '../components/Layout/User';
import { useNamedRoutes } from "../components/NamedRoutes";
import NavbarMain from '../components/Navigation/Navbar/Main';
import SidebarMain from '../components/Navigation/Sidebar/Main';
import { MenuLink } from "../components/Navigation/Sidebar/Sidebar";
import { MenuToggleProps } from "../components/Navigation/types";
import { useUser } from "../components/ReactData/user";
import NewUserModal from "../components/User/NewUser/NewUserModal";
import { ReactClickEvent } from "../components/types";
import { useMyPermissions } from "../components/Login/LoginProvider";
import { UserPermissions, permissionAllowed } from "@ews/websocket-service";

type UserProps = {
  filterByCustomer?: boolean;
};

const UserSettings: React.FC = () =>
{

  const { params } = useNamedRoutes();

  const { id } = params();

  const user = useUser(id!);

  return <>

    <Layout header={true} backbutton={true} user={user} navbar={NavbarMain} sidebar={SidebarMain} id="userSettings">
      <SettingsOverlay user={user} />
    </Layout>
  </>;

};

const SidebarWrapper: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
  const { translate: t } = useL10n();
  const permissions: UserPermissions = useMyPermissions();


  return (<SidebarMain onMenuToggle={onMenuToggle}>
    {permissionAllowed(permissions.createUser!) ? <>
      <MenuLink
        title={t('Add user')}
        id="newUser"
        icon={addCircleSharp}
      />
      <NewUserModal onDismiss={(e) => onMenuToggle && onMenuToggle(e as unknown as ReactClickEvent)} trigger={"newUser"} />
    </> : <></>}
  </SidebarMain>);
};



const User: React.FC<UserProps> = ({ filterByCustomer }) =>
{

  const { params } = useNamedRoutes();
  const { id: customerId } = params();
  //const result = useMyUsers({});

  return (
    <>
      <Layout header={false} navbar={NavbarMain} sidebar={SidebarWrapper} id="users">
        <UserList customerId={filterByCustomer ? customerId : undefined} />
      </Layout>
    </>
  );
};
export
{
  User,
  UserSettings
};
