import Header from "../Header/Customer";
import Navigation from "../Navigation";
import { CustomerLayoutProps } from "./types";


const Layout: React.FC<CustomerLayoutProps> = ({ header = true, children, customer, sidebar, backbutton, navbar, id = '' }) =>
{
    return <>
        {header ? <Header backbutton={backbutton} title={customer?.name || ""} /> : false}
        <Navigation sidebar={sidebar} navbar={navbar} id={id}>
            {children}
        </Navigation>
    </>;
};

export default Layout;