import { SystemShareOperate } from '@ews/websocket-service';
import { useModifySystemShareOperate, useSystemPermissions, useSystemShareOperate } from '../../../../../../../ReactData/system';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import SettingsCheckbox from '../../../../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox';
import NumberRange from '../components/NumberRange';
import { Props } from './types';

const Operation: React.FC<Props> = ({ systemId, userId }) =>
{

    const operation = useSystemShareOperate(systemId || "", userId);
    const modify = useModifySystemShareOperate();
    const modifyData = async (data: SystemShareOperate) => await modify(systemId, userId, data);

    const permission = useSystemPermissions(systemId || ""); // oder ist die Attribute im useSystemShareOperate enthalten? 
    console.log("permission:", permission);

    return <SettingsCol
    //    size='12' xl='12' md='12' lg='12' sm='12'
    >
        <SettingsCheckbox
            text={"WLAN check active"}
            checked={permission.restrictOperationToWlanSSID || false}
            onChange={(checked: boolean) =>
            {

            }} />

        <SettingsCheckbox
            text={"GPS check active"}
            checked={permission.restrictOperationToGPSArea || false}
            onChange={(checked: boolean) =>
            {

            }} />

        <SettingsCheckbox
            text={"Only one user in authorization"}
            checked={permission.exclusiveAuthorization || false}
            onChange={(checked: boolean) =>
            {

            }} />

        <SettingsCheckbox
            text={"Operation only possible if FDCP is in authorization level 2/3"}
            checked={permission.restrictOperationToAuthorizedUsers || false}
            onChange={(checked: boolean) =>
            {

            }} />

        <SettingsCheckbox
            text={"If session is enabled on the FDCP"}
            checked={permission.restrictOperationToApprovedSession || false}
            onChange={(checked: boolean) =>
            {

            }} />

        <NumberRange
            label='Zone'
            from={operation.fromZone}
            to={operation.toZone}
            onChange={(fromZone, toZone) => modifyData({ fromZone, toZone })}
        />

        <NumberRange
            label='Action'
            from={operation.fromActuation}
            to={operation.toActuation}
            onChange={(fromActuation, toActuation) => modifyData({ fromActuation, toActuation })}
        />

        <NumberRange
            label='AlarmingDevice'
            from={operation.fromAlarmingDevice}
            to={operation.toAlarmingDevice}
            onChange={(fromAlarmingDevice, toAlarmingDevice) => modifyData({ fromAlarmingDevice, toAlarmingDevice })}
        />
        <NumberRange
            label='TransmissionDevice'
            from={operation.fromTransmissionDevice}
            to={operation.toTransmissionDevice}
            onChange={(fromTransmissionDevice, toTransmissionDevice) => modifyData({ fromTransmissionDevice, toTransmissionDevice })}
        />

    </SettingsCol>;
};
export default Operation;
