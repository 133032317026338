import { PaginationRequest } from "@ews/websocket-service";
import { IonCol } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import FilterList from "../../../FilterList/FilterList";
import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { Filter } from "../types/reportTypes";
import { EventFilterKeys, PaginatedResult, TypeDefinitionSubmissionDocumentsProps } from "./types";

const TypeDefinitionSubmissionDocuments: React.FC<TypeDefinitionSubmissionDocumentsProps> = ({ system }) =>
{

    //const filterData = useFetchSystemEvents();

    const [systemEvents, setSystemEvents] = useState<PaginatedResult["results"] | []>([]);
    const [clearFilterInputs] = useState(false);
    const page = useRef<number | undefined>();
    const numberOfPages = useRef<number | undefined>();
    const numberOfItemsPerPage = useRef(pageRange[1]);


    const standardFilter: Filter<EventFilterKeys> = {
        sortCriteria: "runningNumber",
        sortOrder: "ASC"
    };

    const [filterEvents, setFilterEvents] = useState<Filter<EventFilterKeys>>(standardFilter);

    async function getFilteredData(id: string, filter: PaginationRequest)
    {
        const response = {
            results: [],

            currentPage: 1,
            pageSize: 10,
            totalCountOfEntries: 0,
            totalCountOfPages: 0
        };//await filterData(id, filter);
        if (!response) return;

        const { results, currentPage, pageSize, totalCountOfPages } = response as PaginatedResult;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setSystemEvents(results);
    }

    useEffect(() =>
    {
        if (system.id) getFilteredData(system.id, filterEvents);
    }, [filterEvents, system]);


    return <>
        <FilterList
            isDetailsHidden={false}
            onReset={() => setFilterEvents({})}
            //onDetailOpen={(state) => setHideDetailsFilter(state)}
            details={<>
                <IonCol>
                    <TypeaheadString
                        id="group"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            //setState({ ...currentState, systemId: (a: string, b: string): boolean => stringFilter(a, value) });
                            //const list = await getList(data, 'systemId');
                            return [];
                        }}

                        text={"Group"}
                    />
                    <TypeaheadString
                        id="element"
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            //setState({ ...currentState, systemId: (a: string, b: string): boolean => stringFilter(a, value) });
                            //const list = await getList(data, 'systemId');
                            return [];
                        }}

                        text={"Element"}
                    />
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>} >
        </FilterList >


        < List
            rows={systemEvents}
            sort={filterEvents.sortCriteria ? {
                key: filterEvents.sortCriteria,
                direction: filterEvents.sortOrder || "ASC"
            } : undefined}

            onSort={(sortCriteria, sortOrder) => setFilterEvents({ ...filterEvents, sortCriteria, sortOrder })}
            onPageChange={(page) => setFilterEvents({ ...filterEvents, page })}

            pageSize={filterEvents.pageSize}
            onPageSize={(pageSize) => setFilterEvents({ ...filterEvents, pageSize })}

            onReload={() =>
            {
                if (system.id) getFilteredData(system.id, filterEvents);
            }}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}
            noLink
            //link={{ url: "system:events", paramsKey: "id" }}
            headers={[
                { title: 'Group.', key: 'group', both: true },
                { title: 'Element', key: 'element', breakpoint: 1200, up: true },
                { title: 'Text1', key: 'text1', breakpoint: 1200, up: true },
                { title: 'Text2', key: 'text2', breakpoint: 1200, up: true },
                { title: 'Parent Group', key: 'parentGroup', breakpoint: 1200, up: true },
                { title: 'Type', key: 'type', breakpoint: 1200, up: true },
            ]}
        />
    </>;
};

export default TypeDefinitionSubmissionDocuments;