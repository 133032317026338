import { useL10n } from "@ews/react-localization-context";
import { IonSelectOption } from "@ionic/react";
import { SettingsSelectOptionProps } from "./types";

const SettingsSelectOption: React.FC<SettingsSelectOptionProps> = ({ className, value, text, chlidren }) =>
{
    const { translate: t } = useL10n();
    return <IonSelectOption className={className} value={value}>{t(text)} {chlidren}</IonSelectOption>;
};

export default SettingsSelectOption;