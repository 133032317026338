import Default from './Component/Default/Default';
import Spinner1 from './Component/Spinner1/Spinner1';
import screenStyle from './LoadingScreen.module.scss';

const LoadingScreen = () =>
{

    return (
        <div className={screenStyle["loading-screen"]}>
            {/*   <Spinner1 /> */}
            <Default />
        </div>
    );
};

export default LoadingScreen;