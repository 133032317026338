
import { useL10n } from "@ews/react-localization-context";
import { IonCheckbox, IonItem, IonLabel, IonToggle } from "@ionic/react";
import React, { ReactNode, useRef } from "react";
import { useIsMobile } from "../../../Responsive";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsCeckbox.module.scss';
import { SettingsCheckboxProps } from "./types";

const SettingsCheckbox: React.FC<SettingsCheckboxProps> = ({
    children,
    onChange,
    hidden = false,
    text = '',
    className,
    checked,
    labelPosition = 'stacked',
    disabled,
    tooltip,
    lines = 'full',
    item = true,
    container = true,
    label = true,
}) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();

    const Checkbox = mobileView ? IonToggle : IonCheckbox;
    const Item = item ? IonItem : ({ children }: { children: React.ReactNode; }) => <>{children}</>;

    const labelRef = useRef(null);


    //useEffect(() => { setShowChildren(checked); }, [checked]);

    const Div =
        ({ children, className }: { children: ReactNode, className: string; }) => container ?
            <div
                /* style={{ marginTop: '0', padding: '8px 0' }} */
                className={className}
            >
                {children}
            </div>
            :
            <>{children}</>;

    return <>
        <Item
            hidden={hidden}
            lines={children ? (!checked ? lines : 'none') : lines}
            className={`${style.overflow} ${globalStyle.setting} ${style.container} ${className}`}
        >
            {label ? <span className={style.flex}>
                <IonLabel
                    ref={labelRef}
                    position={labelPosition}
                    style={{ whiteSpace: "break-spaces" }}
                    className={`${style.label} `}
                /* position={labelPosition} */
                >
                    {t(text)} {mobileView ? <></> : tooltip}
                </IonLabel>
            </span> : null}
            <Div className={`${className} ${style.checkboxContainer}`}>
                <Checkbox
                    slot={item ? "end" : ""}
                    aria-label=""
                    disabled={disabled}
                    checked={checked}
                    className={`${style.checkboxPosition} ${style.checkbox}`}
                    onIonChange={(e) =>
                    {
                        const checked = e.detail.checked;
                        onChange(checked);
                    }} />
            </Div>
            {children && !checked ? (
                <div className={style.more}></div>
            ) : <></>}
        </Item>

        {children ? (
            Array.isArray(children) ? children.map((child, index) => <IonItem
                lines="none"
                key={index}
                className={`${style.children} ${style.overflow}`}
                style={{ minWidth: '100%' }}
                hidden={!checked}>
                {child}
            </IonItem>) :
                <IonItem
                    lines={lines}
                    className={`${style.children} ${style.overflow}`}
                    style={{ minWidth: '100%' }}
                    hidden={!checked}>
                    {children}
                </IonItem>
        ) : null}
    </>;
};

export default SettingsCheckbox;