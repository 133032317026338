import { ReactNode } from "react";
import { BreakpointProvider, useCurrentWidth } from "react-socks";
import { Capacitor } from "@capacitor/core";

type Never<Type> = {
    [Property in keyof Type]?: never;
};

enum Breakpoints
{
    xsmall = 0,
    small = 576,
    medium = 768,
    large = 992,
    xlarge = 1200
};

const mobileBreakpoint = 'large';

type ChildrenProps = {
    children: ReactNode;
};

export type MobileProp = {
    mobile: true;
};

export type DesktopProp = {
    desktop: true;
};

type UpProps = {
    up: true;
};

export type BreakpointProp = (UpProps) & {
    breakpoint: keyof (typeof Breakpoints) | number;
};

export type MobileProps = MobileProp & Never<DesktopProp> & Never<BreakpointProp>;
export type DesktopProps = DesktopProp & Never<MobileProp> & Never<BreakpointProp>;
export type BreakpointProps = BreakpointProp & Never<DesktopProp> & Never<MobileProp>;

type ResponsiveProps = ChildrenProps & (MobileProps | DesktopProps | BreakpointProps);

export function useIsMobile()
{
    const currentWidth = useCurrentWidth();
    return currentWidth < Breakpoints[mobileBreakpoint];
}

export function useIsNative()
{
    const platform = Capacitor.getPlatform();
    const native = Capacitor.isNativePlatform();
    return (platform === "android" || platform === "ios") && native;
}

export const Responsive: React.FC<ResponsiveProps> = ({ children, mobile, desktop, breakpoint, up }) => 
{
    const currentWidth = useCurrentWidth();
    const mobileView = currentWidth <= Breakpoints[mobileBreakpoint];

    if (mobile || desktop) {

        const show = (mobile && mobileView) || (desktop && !mobileView);
        return show ? <>{children}</> : <></>;

    } else {

        const breakAt = typeof breakpoint === 'number' ? breakpoint : Breakpoints[breakpoint];
        const below = (currentWidth <= breakAt);
        const show = (below && !up) || (!below && up);

        return show ? <>{children}</> : <></>;
    }
};

export const ResponsiveProvider: React.FC<ChildrenProps> = ({ children }) =>
{
    return <BreakpointProvider>{children}</BreakpointProvider>;
};


