import { IonLoading } from "@ionic/react";
import
{
    ReactNode,
    createContext,
    useContext,
    useState
} from "react";
import LoadingScreen from "./LoadingScreen/LoadingScreen";

export type LoadingContextType = (loadingStatus: boolean) => void;

const LoadingContext = createContext<LoadingContextType>(() => { });

export const useLoadingIndicator = () =>
{
    return useContext(LoadingContext);
};

export const LoadingIndicator: React.FC<{ children: ReactNode; }> = ({ children }) =>
{

    const [isLoading, setIsLoading] = useState<boolean>(false);

    // wenn die eingabe wärend des ladens geblockt werden soll
    //const listener = useRef((e: KeyboardEvent, isLoading: boolean) => { if (isLoading) e.preventDefault(); });
    //useEffect(() =>
    //{
    // window.onkeydown = (e) => listener.current(e, isLoading);
    //}, [isLoading]);
    //console.log(isLoading);

    return (
        <LoadingContext.Provider value={setIsLoading}>
            {/* <IonLoading isOpen={isLoading}></IonLoading> */}
            {isLoading ? <LoadingScreen /> : null}
            {children}
        </LoadingContext.Provider >
    );
};